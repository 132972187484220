<template>
  <div class="pc-home">
    <div class="flex wrap flex_center page-item item-2">
      <!--:style="subProjectsStyle[index]"-->
      <div v-for="(item,ind) in subProjects" :key="ind">
        <img :src="item.src" />
      </div>
    </div>
    <div class="gray-line"></div>
    <div class="flex_column flex_center page-item item-7">
      <div class="title">为您的业务量身定制开发方案</div>
      <div class="script">选择最适合您需求的产品模式</div>
      <div class="flex wrap flex_center page_attr">
        <div class="card" v-for="(item,k) in solveWay" :key="k + 'attr'">
          <div class="flex flex_center img">
            <img class="fade-in" v-lazy="item.back" />
          </div>
          <lazy-component class="fade-in">
            <div class="card-title">{{item.title}}</div>
            <div class="card-script">{{item.script}}</div>
          </lazy-component>
        </div>
      </div>
      <!-- <div class="click-button">
        <div class="button" @click="toserve">详细了解</div>
      </div>-->
    </div>
    <!-- <div class="gray-line"></div> -->
    <div class="flex_column flex_center page-item item-3">
      <div class="title">服务案例</div>
      <div class="script">所有案例均是我们从0开始开发与维护，最长已有五年之久。</div>
      <div
        class="flex wrap project-card"
        v-for="(item,index) in mainProjects"
        :key="index + 'projectCard'"
      >
        <div
          :style="item.back"
          class="flex flex_center left-bigimg click-img"
          @click="projectContent(item.projectIndex)"
        >
          <lazy-component class="fade-in">
            <img :src="item.bigImg" />
          </lazy-component>
        </div>
        <div class="flex flex_center right">
          <lazy-component class="fade-in">
            <div class="flex flex_row right-firstline">
              <img class="right-img" :src="item.logo" />
              <div class="right-title">{{item.title}}</div>
            </div>
            <div class="right-script">{{item.script}}</div>
            <div class="right-subtitle">我们负责：</div>
            <div class="flex_row wrap content-wrap">
              <div
                v-for="(divil,index1) in item.content"
                :key="`inner${index1}`"
                class="content-card"
              >{{divil}}</div>
            </div>
            <div class="click-button">
              <button class="button" @click="projectContent(item.projectIndex)">案例详情查看</button>
            </div>
          </lazy-component>
        </div>
      </div>
      <!-- mobile project-card -->
      <div
        :style="item.back+';background-size: 100% 100%;'"
        class="mobile-project-card"
        v-for="(item,indexs) in mobile_mainProjects"
        :key="indexs + 'project'"
      >
        <div class="down">
          <div class="flex_row down-firstline">
            <img class="down-img" :src="item.logo" />
            <div class="down-title">{{item.title}}</div>
          </div>
          <div class="down-script">{{item.script}}</div>
          <div class="down-subtitle">我们负责：</div>
          <div class="flex_row wrap content-wrap">
            <div
              v-for="(divil,index11) in item.content"
              :key="`inner${index11}`"
              class="content-card"
            >{{divil}}</div>
          </div>
          <div class="button" @click="projectContent(item.projectIndex)" v-fb>案例详情查看</div>
        </div>
      </div>
    </div>
    <div class="flex_column flex_center page-item item-4-wrap">
      <div class="flex wrap flex_center page-item item-4">
        <div v-for="(item,i) in caseProject" :key="i + 'anli'" class="flex_row card">
          <lazy-component class="fade-in" style="width: 100%;">
            <div
              class="small_title flex items-center"
              :style="i % 2 !== 0 ? 'margin-left: 20px;' : ''"
            >
              <img
                style="width: 20px;height: 20px;"
                src="http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png"
              />
              {{ item.title }}
            </div>
            <div class="click-img small_image" :style="i % 2 !== 0 ? 'margin-left: 20px;' : ''">
              <img :src="item.back" @click="projectContent(item.projectIndex)" />
              <!-- <div class="small-card" :style="item.back" @click="projectContent(item.projectIndex)"></div> -->
            </div>
            <div class="card-script">{{item.script}}</div>
          </lazy-component>
        </div>
        <div v-for="(item,key) in caseProject" :key="key + 'anli2'" class="mobile_card">
          <lazy-component class="fade-in">
            <div class="small_title flex items-center">
              <img
                style="width: 20px;height: 20px;"
                src="http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png"
              />
              {{ item.title }}
            </div>
            <div class="click-img small_image flex flex_column flex_center">
              <img :src="item.back" @click="projectContent(item.projectIndex)" v-fb />
              <!-- <div class="small-card" :style="item.back" @click="projectContent(key+3)" v-fb></div> -->
              <div class="card-script">{{item.script}}</div>
            </div>
          </lazy-component>
        </div>
      </div>
      <div class="click-button">
        <button class="item-4-button" @click="toProject">查看更多案例</button>
      </div>
    </div>
    <div class="gray-line"></div>
    <div class="flex_column item-new5_wrap align-center page-item">
      <div class="big_title text-center">设想一下，你将拥有一个这样的团队</div>
      <div class="flex flex-row justify-center rewrap page-item item-new5">
        <div class="flex_column justify-space-between left_content">
          <div class="flex flex-row wrap" v-for="(item, i) in hopeTeam" :key="i + 'team'">
            <div class="flex flex_center circle">{{i+1}}</div>
            <div class="flex_column text">
              <div class="title" style="font-weight: bold">{{item.title}}</div>
              <div class="script">{{item.text}}</div>
            </div>
          </div>
        </div>
        <div class="right_content">
          <lazy-component class="fade-in" style="width: 100%;height: 100%">
            <img
              src="http://moke-store.oss-cn-beijing.aliyuncs.com/2fc8aca1-bbff-45b7-ac88-121a4a4665df.jpeg?x-oss-process=image/crop,w_599,h_408"
            />
          </lazy-component>
        </div>
      </div>
      <div class="click-button">
        <button class="button" @click="toserve">详细了解</button>
      </div>
    </div>
    <!-- <div class="gray-line"></div> -->
    <!-- <div class="flex_column flex_center page-item item-8">
      <div class="title">现在立刻来做项目评估</div>
      <div class="script">告诉我们您的需求，我们来给您策划和估价</div>
      <div class="flex wrap flex_center maincards">
        <div class="three-cards" v-for="(item,i1) in steps" :key="i1">
          <div class="serial">{{i1+1}}</div>
          <div class="steps-title">{{item.title}}</div>
          <div class="steps-script">{{item.script}}</div>
        </div>
      </div>
      <div class="click-button">
        <div class="button" @click="showPreviewf">
          免费评估您的项目
          <img :src="iconImg.right" />
        </div>
      </div>
      <div class="subscript" @click="toserve">还没准备好估算？了解我们的更多的服务介绍</div>
    </div>-->
    <div class="gray-line"></div>
    <div class="flex wrap flex_center page-item my_serve">
      <div class="half-img">
        <img class="fade-in half-img" v-lazy="provideHalfimg" />
      </div>
      <div class="right-half">
        <div class="small-title">我们的服务</div>
        <div class="small-redline"></div>
        <div class="main-content">
          我们将您的创意变成用户会喜欢的
          <br />成熟产品
        </div>
        <div class="flex_row content" v-for="(item,index) in fourOfferItems" :key="index + 'serve'">
          <div class="small-icon">
            <img :src="item.icon" />
          </div>
          <div class="content">
            <div class="title">{{item.title}}</div>
            <div class="script">{{item.script}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex_column flex_center page-item job_type">
      <div class="small-title">我们的工作方式</div>
      <div class="small-redline"></div>
      <div class="main-content">
        通过透明的流程来确保按您的愿景
        <br />交付预期应用
      </div>
      <div class="pc">
        <div class="flex_row four-icon">
          <div v-for="(item,index1) in fourRoutesIcon" :key="index1+'card'" class="card">
            <img :src="item" />
          </div>
        </div>
        <div class="flex redline">
          <div class="flex_row" v-for="(item,index2) in 4" :key="index2 + 'index2'">
            <div class="small-point"></div>
            <div class="thin-redline" v-if="index2!==3"></div>
          </div>
        </div>
        <div class="flex_row four-cards">
          <div
            v-for="(item,index3) in fourRoutes"
            :key="index3"
            class="flex_column flex_center cards"
          >
            <div class="small-icon">
              <img class="small-icon" :src="item.icon" />
            </div>
            <div class="title">{{item.title}}</div>
            <div class="script">{{item.script}}</div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div
          v-for="(item,index) in mobilefourRoutes"
          :key="index + 'cards'"
          class="flex_column flex_center cards"
        >
          <div class="small-icon">
            <img class="small-icon" :src="item.icon" />
          </div>
          <div class="title">{{item.title}}</div>
          <div class="script">{{item.script}}</div>
        </div>
      </div>
    </div>
    <estimate></estimate>
    <estimate-alert></estimate-alert>
  </div>
</template>
<script>
import qa from '../assets/js/qa'
import estimate from './estimate'
import EstimateAlert from './EstimateAlert'
export default {
  name: 'pcHome',
  components: { EstimateAlert, estimate },
  data () {
    return {
      hopeTeam: [{ title: '永远以技术为先', text: '我们始终尝试将最新的技术应用到您的项目' }, { title: '始终保持活力', text: '软件只有长期更新才能保持活力' }, { title: '解决一切和技术有关的问题', text: '免费服务器，应用商店上架，软著申请' }],
      disCards: [{ icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e9f9d520-54e4-4e54-9308-cbf41bdb1fc8.png', title: '永远以技术为先', script: '领先业内平均水平3-5年' },
      { icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ff97975b-509b-4440-a820-364c8f0883ee.png', title: '长期技术支持', script: '让您的软件始终保持活力' },
      { icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/51dfc3b9-9936-490b-851e-d3574516361e.png', title: '完善的后期服务', script: '免费服务器，APP上架，软著申请' }],
      subProjects: [{ src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b2983cec-9d2e-45fb-bb1f-8762fce64ff0.png?x-oss-process=image/resize,w_206,h_41' },
      { src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2d141a4b-8d0e-44e1-9845-f0589479408c.png?x-oss-process=image/resize,w_206,h_41' },
      { src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8b484e44-ece9-4584-9c5e-ee1e7fd6cc53.png?x-oss-process=image/resize,w_206,h_41' },
      { src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1bb108c9-c7d1-4e7f-bb44-513078901783.png?x-oss-process=image/resize,w_206,h_41' },
      { src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1608bd1-9f14-4b8d-9aae-8e9b6ff1d915.png?x-oss-process=image/resize,w_206,h_41' }],
      mainProjects: [
        // {
        //   title: 'HIRO',
        //   script: '利用游戏化的模式，进行下单和接单。根据定位 查看周边HIRO的位置。',
        //   bigImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6d71f66a-bfa0-434a-88bb-b849927ffaf2.png?x-oss-process=image/resize,w_500',
        //   back: 'background:url(http://moke-store.oss-cn-beijing.aliyuncs.com/181aed03-e8ca-43dc-9a59-3038dfea8b5b.png?x-oss-process=image/crop,w_600) center left no-repeat',
        //   logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e4ff704b-ef43-4c01-9fff-7af5b8cfb8dc.png',
        //   content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
        //   projectIndex: 0
        // },

        {
          title: '车辆管理系统',
          script: '利用智能技术手段，实现对人、车的全方面、智能化监管，提高工作效率。',
          bigImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6507f6cb-5d41-4631-bd62-1b9598867495.png?x-oss-process=image/resize,w_500',
          back: 'background:url(http://moke-store.oss-cn-beijing.aliyuncs.com/4c70cc9b-923d-4109-b7ab-56456d24bd7e.png?x-oss-process=image/crop,w_600) center left no-repeat',
          logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ae3f4e0d-56c4-4292-a2a0-8f942ba50576.png',
          content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
          projectIndex: 1
        },
        {
          title: '古力古利',
          script: '软硬件结合，手机端远程操作智能伴宠机器人，视频语音沟通。',
          bigImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/09a50f87-bb55-4c7a-ba4b-be55b14526cf.png?x-oss-process=image/resize,w_500',
          back: 'background:url(http://moke-store.oss-cn-beijing.aliyuncs.com/34068e90-0724-44fb-87aa-f50a860453e4.png?x-oss-process=image/crop,w_600) center left no-repeat',
          logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/49b48818-fda2-4247-8bce-7cbcc008cb49.png',
          content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发', '硬件设备操纵'],
          projectIndex: 2
        }
        // {
        //   title: '小萬生活',
        //   script: '本地化服务商，您可以在上面体验所在地的权益和服务',
        //   bigImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4de79b2e-3592-4dee-8b12-6d05a37d2f69.png?x-oss-process=image/resize,w_500',
        //   back: 'background:url(http://moke-store.oss-cn-beijing.aliyuncs.com/d483dfdd-b767-4a8f-9d43-26ec001fc2fc.jpeg?x-oss-process=image/crop,w_600) center center no-repeat',
        //   logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d762e23-5db9-4a57-8888-88696f1dcd55.png',
        //   content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发']
        //   // }, {
        //   //         title: '智赛桥牌',
        //   //         script: '电脑端桥牌游戏，隶属于石家庄桥牌协会，有超越普通在线桥牌游戏的设计',
        //   //         bigImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c8154efb-9517-486c-bd57-917df1acb6e6.png?x-oss-process=image/resize,w_500',
        //   //         back: 'background:url(http://moke-store.oss-cn-beijing.aliyuncs.com/bd3baba8-d433-4cea-8c50-28ea98b33a67.jpeg?x-oss-process=image/crop,w_600) center center no-repeat',
        //   //         logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3d4c5a4e-bd5b-4c44-aed5-5eaa28c628e0.png',
        //   //         content: ['产品设计', '端游交互设计', 'Win应用程序', '后端开发']
        // },
        // {
        //   title: '沃特德饮水机',
        //   script: '软硬件结合，手机端直观操作智能水机系统',
        //   bigImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/59f4703b-762f-416d-bfed-7001a38be238.png?x-oss-process=image/resize,w_500',
        //   back: 'background:url(http://moke-store.oss-cn-beijing.aliyuncs.com/43003031-8ec6-4819-9223-b128daa8d7f3.jpeg?x-oss-process=image/crop,w_600) center center no-repeat',
        //   logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/600938e9-e675-47ad-b435-37ca2e57a8c7.png',
        //   content: ['产品设计', 'APP交互设计', '小程序开发', '后端开发']
        // }
      ],
      mobile_mainProjects: [
        // {
        //   title: '小萬生活',
        //   script: '本地化服务商，您可以在上面体验所在地的权益和服务',
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/1218ead9-1ad1-48aa-b8d4-19afe4cb00ad.jpeg) no-repeat;',
        //   logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d762e23-5db9-4a57-8888-88696f1dcd55.png',
        //   content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发']
        // },
        // {
        // 	title: '智赛桥牌',
        // 	script: '电脑端桥牌游戏，隶属于石家庄桥牌协会，有超越普通在线桥牌游戏的设计',
        // 	back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/f4254284-4407-4bff-b0a5-5d79657ac0a4.jpeg) no-repeat',
        // 	logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3d4c5a4e-bd5b-4c44-aed5-5eaa28c628e0.png',
        // 	content: ['产品设计', '端游交互设计', 'Win应用程序', '后端开发']
        // },
        // {
        //   title: '沃特德饮水机',
        //   script: '软硬件结合，手机端直观操作智能水机系统',
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/f385271e-e6a1-4550-a0c8-28385b3431d5.jpeg) no-repeat',
        //   logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/600938e9-e675-47ad-b435-37ca2e57a8c7.png',
        //   content: ['产品设计', 'APP交互设计', '小程序开发', '后端开发']
        // }
        // {
        //   title: 'HIRO',
        //   script: '利用游戏化的模式，进行下单和接单。根据定位 查看周边HIRO的位置。',
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/3b559e28-c359-4edd-998d-1eaa9833b06c.png) no-repeat;',
        //   logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e4ff704b-ef43-4c01-9fff-7af5b8cfb8dc.png',
        //   content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
        //   projectIndex: 0
        // },
        {
          title: '车辆管理系统',
          script: '利用智能技术手段，实现对人、车的全方面、智能化监管，提高工作效率。',
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/b41ca3f7-f714-437c-b4e6-559d4753e8c1.png) no-repeat;',
          logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ae3f4e0d-56c4-4292-a2a0-8f942ba50576.png',
          content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
          projectIndex: 1
        },
        {
          title: '古力古利',
          script: '软硬件结合，手机端远程操作智能伴宠机器人，视频语音沟通。',
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/5d9a7989-52b3-4402-9705-fe1fecb65456.png) no-repeat;',
          logo: 'http://moke-store.oss-cn-beijing.aliyuncs.com/49b48818-fda2-4247-8bce-7cbcc008cb49.png',
          content: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发', '硬件设备操纵'],
          projectIndex: 2
        }
      ],
      qa: qa,
      name: '',
      title: '',
      phone: '',
      body: '',
      preview: [],
      readMore: false,
      fourPromiseItems: [{
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8a53d53b-b6d9-4584-bb79-7136cbe192a8.jpeg',
        title: '100％基于办公室',
        script: '享受只有办公室团队才能提供的效率和透明度'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8c1a240f-df79-4101-a3ca-5f265ad77c7d.jpeg',
        title: '专业团队沟通',
        script: '为您的项目创建专属的开发沟通团队'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9ebc4ff2-7823-4572-a04f-de2e66771781.jpeg',
        title: '合理、规范的预估报价',
        script: '我们专业的估算团队为您提供合理规范的报价。'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c6fc264b-8ed1-495d-8f34-f4be7c95de87.jpeg',
        title: '按期交付无崩溃应用',
        script: '我们承诺按合同交付无崩溃的应用。'
      }],

      fourOfferItems: [{
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/43d5b03e-3254-414c-9975-a6da3a2bc931.jpeg',
        title: '漂亮的UI设计',
        script: '我们将完美无瑕的设计组合在一起，打造直观易用的UX / UI'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6c69b92d-3bdf-4261-a85e-081698422f29.jpeg',
        title: '规范的开发流程',
        script: '通过规范的开发流程，您将获得一个无崩溃的应用程序'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e9630bac-1100-4764-be6b-5931fefadf4b.jpeg',
        title: '免费的服务器',
        script: '我们提供2年的免费的后端服务器'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/490dba1e-f614-43fe-a293-c0540365e66f.jpeg',
        title: '帮您节约时间成本',
        script: '不要花时间组建技术团队，立刻就能开发您的产品'
      }],
      fourRoutesIcon: ['http://moke-store.oss-cn-beijing.aliyuncs.com/e9f9d520-54e4-4e54-9308-cbf41bdb1fc8.png',
        'http://moke-store.oss-cn-beijing.aliyuncs.com/ff97975b-509b-4440-a820-364c8f0883ee.png',
        'http://moke-store.oss-cn-beijing.aliyuncs.com/7391d4b4-11c0-4c44-81ba-ad6671263d3e.png',
        'http://moke-store.oss-cn-beijing.aliyuncs.com/51dfc3b9-9936-490b-851e-d3574516361e.png'],
      fourRoutes: [{ title: '准时启动项目', script: '我们会在签订合同时准时启动项目开发' },
      { title: '完全透明开发', script: '通过直接与专门团队联系可以随时了解项目状态' },
      { title: '知识产权转让', script: '您一直是所在项目知识产权的所有者' },
      { title: '高效后期服务', script: '我们在项目交付后会一直为您的保障高效运行' }],
      item2cards: ['background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")',
        'background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")',
        'background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")',
        'background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")'],
      mobilefourRoutes: [
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e9f9d520-54e4-4e54-9308-cbf41bdb1fc8.png',
          title: '准时启动项目',
          script: '我们会在签订合同时准时启动项目开发'
        },
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ff97975b-509b-4440-a820-364c8f0883ee.png',
          title: '完全透明开发',
          script: '通过直接与专门团队联系可以随时了解项目状态'
        },
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7391d4b4-11c0-4c44-81ba-ad6671263d3e.png',
          title: '知识产权转让',
          script: '您一直是所在项目知识产权的所有者'
        },
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/51dfc3b9-9936-490b-851e-d3574516361e.png',
          title: '高效后期服务',
          script: '我们在项目交付后会一直为您的保障高效运行'
        }],
      promiseHalfimg: "http://moke-store.oss-cn-beijing.aliyuncs.com/41795c22-059e-49fc-81f5-432844229414.jpeg",
      provideHalfimg: "http://moke-store.oss-cn-beijing.aliyuncs.com/da15e162-1e13-416f-986b-520547293cfc.jpeg?x-oss-process=image/resize,w_578"
    }
  },
  computed: {
    day () {
      let d = new Date()
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
    }
  },
  methods: {
    toserve: function () {
      this.$router.push('/serve/')
    },
    toAbout: function () {
      this.$router.push('/about/')
    },
    toProject: function () {
      this.$router.push('/project/')
    },
    projectContent: function (index) {
      // 0是小万生活
      if (index === 8) index = 0
      this.$router.push({
        path: '/oneProject/',
        query: {
          index: index
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.click-button {
  margin-top: 16px;
  .button {
    cursor: pointer;
    margin: 24px auto 80px;
    text-align: center;
    line-height: 50px;
    width: auto;
    padding: 0 23px;
    height: 50px;
    border: 1.5px solid rgba(124, 124, 153, 1);
    border-radius: 25px;
    font-size: 16px;
    color: rgba(124, 124, 153, 1);
  }
}
.itme-body {
  width: 1160px;
}
.item-title {
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: bold;
  color: rgba(45, 45, 73, 1);
}
.item-text {
  font-size: 20px;
  color: rgba(110, 110, 136, 1);
  line-height: 1.5;
  width: 800px;
}
.project-slide {
  box-sizing: border-box;
  padding: 50px;
  width: 700px;
  height: 440px;
  background-color: white;
  border-radius: 2px;
  margin: 20px 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  &:first-child {
    margin-left: 30px;
  }

  &:last-child {
    margin-right: 30px;
  }
}
.preview-slide {
  height: 100vh;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 60px;
  }

  &:last-child {
    margin-right: 60px;
  }

  img {
    max-height: 60%;
    width: auto;
  }
}
.preview-close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
  width: 40px;
  height: 40px;
  cursor: pointer;
  path {
    fill: white;
  }
}
.title-br {
  width: 40px;
  height: 4px;
  background-color: rgba(244, 74, 79, 1);
  margin: 30px 0;
}
.item-2 {
  height: auto;
  div {
    text-align: center;
    width: 240px;
    height: 136px;
    padding-top: 42px;
    img {
      width: 100%;
      height: auto;
    }
  }
  div:nth-child(1) {
    img {
      width: 86%;
      height: auto;
    }
  }
  div:nth-child(2) {
    img {
      width: 86%;
      height: auto;
    }
  }
  @media screen and (max-width: 1220px) {
    div {
      width: 18vw;
      height: 120px;
    }
  }
}
.item-3 {
  margin-top: 27px;
  padding: 32px;
  .title {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 2rem;
    color: rgba(45, 45, 73, 1);
    margin-bottom: 1rem;
  }
  .script {
    margin-top: 1rem;
    font-size: 1.5rem;
    line-height: 1;
    color: rgba(124, 124, 153, 1);
    margin-bottom: 56px;
  }
  .project-card {
    margin-top: 4rem;
    max-width: 1160px;
    width: 100%;
    height: auto;
    background-color: rgba(250, 250, 250, 1);
    border-radius: 0.6rem;
  }
  .mobile-project-card {
    display: none;
  }
  .left-bigimg {
    cursor: pointer;
    height: 680px;
    max-width: 600px;
    width: 100%;
    @media screen and (min-width: 808px) and (max-width: 1240px) {
      width: 40vw;
      img {
        width: 100%;
      }
    }
  }
  .right {
    max-width: 560px;
    width: 100%;
    height: 100%;
    padding: 16px;
    margin-top: 79px;
    @media screen and (min-width: 808px) and (max-width: 1240px) {
      max-width: 50vw;
    }
    .right-img {
      width: 68px;
      height: 68px;
    }
    .right-title {
      margin-left: 24px;
      font-size: 1.6rem;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
    }
    .right-firstline {
      line-height: 68px;
      height: 68px;
    }
    .right-script {
      width: 342px;
      margin-top: 21px;
      line-height: 30px;
      font-size: 1.2rem;
      color: rgba(124, 124, 153, 1);
    }
    .right-subtitle {
      margin-top: 29px;
      margin-bottom: 10px;
      line-height: 20px;
      font-size: 20px;
      color: rgba(45, 45, 73, 1);
    }
    .content-wrap {
      width: 342px;
    }
    .content-card {
      margin: 10px 20px 10px 0px;
      padding-right: 22px;
      padding-left: 22px;
      font-size: 16px;
      color: rgba(45, 45, 73, 1);
      line-height: 46px;
      height: 46px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 8px;
    }
  }
}
.item-4 {
  height: auto;
  width: 100%;
  max-width: 1180px;
  margin-bottom: 100px;
  text-align: center;
  .mobile_card {
    display: none;
  }
  .card {
    width: calc(50% - 8px);
    .small-card {
      cursor: pointer;
      width: 100%;
      margin-left: 15px;
      height: 192px;
      border-radius: 10px;
    }
    .small_image {
      width: calc(100% - 20px);
      img {
        width: 100%;
      }
    }
    .small_title {
      padding-left: 20px;
      margin-top: 54px;
      text-align: left;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2d2d49;
      margin-bottom: 30px;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
    .card-script {
      line-height: 18px;
      font-size: 18px;
      color: rgba(45, 45, 73, 1);
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
  .item-4-wrap {
    padding: 0 20px;
    height: 731px;
  }
}
.item-4-button {
  cursor: pointer;
  text-align: center;
  margin-bottom: 80px;
  vertical-align: auto;
  line-height: 50px;
  width: 160px;
  height: 50px;
  border: 1.5px solid rgba(124, 124, 153, 1);
  border-radius: 25px;
  font-size: 16px;
  color: rgba(124, 124, 153, 1);
}
.item-new5_wrap {
  .big_title {
    margin: 99px auto 64px;
    font-weight: 700;
    font-size: 32px;
    color: rgba(45, 45, 73, 1);
  }
  .item-new5 {
    max-width: 1160px;
    height: auto;
    .left_content {
      max-width: 574px;
      width: 48%;
      min-width: 414px;
      height: 408px;
      background-color: rgba(250, 250, 250, 1);
      font-size: 24px;
      line-height: 1;
      padding: 50px;
      .circle {
        width: 40px;
        height: 40px;
        background-color: rgba(244, 74, 79, 1);
        border-radius: 50%;
        color: rgba(255, 255, 255, 1);
        margin-right: 19px;
      }
      .text {
        .title {
          color: rgba(45, 45, 73, 1);
          margin-bottom: 17px;
        }
        .script {
          font-size: 18px;
          color: rgba(124, 124, 153, 1);
        }
      }
      @media screen and (max-width: 1020px) {
        width: 100%;
      }
    }
    .right_content {
      max-width: 599px;
      width: 52%;
      min-width: 469px;
      height: 408px;
      img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 1020px) {
        width: 100%;
      }
    }
  }
  .click-button {
    .button {
      margin: 52px auto 100px;
    }
  }
}

.item-7 {
  margin: 0 auto;
  max-width: 1160px;
  width: 100%;
  .title {
    margin-top: 90px;
    line-height: 32px;
    font-size: 2rem;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
  }
  .script {
    margin: 20px auto 62px;
    line-height: 1.2rem;
    font-size: 1.2rem;
    color: rgba(124, 124, 153, 1);
  }
  .card {
    margin: 5px 10px;
    width: 266px;
    height: 441px;
    background-color: rgba(250, 250, 250, 1);
    border-radius: 10px;
    .img {
      margin-top: 19px;
      width: 100%;
      height: 201px;
      background-color: rgba(250, 250, 250, 1);
    }
    .card-title {
      line-height: 20px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
      margin: 42px 0 0 30px;
    }
    .card-script {
      margin: 31px 25px 0 30px;
      line-height: 30px;
      font-size: 18px;
      color: rgba(124, 124, 153, 1);
    }
  }
}
.item-8 {
  .title {
    font-weight: bold;
    margin: 5rem auto 1.2rem;
    line-height: 32px;
    font-size: 2rem;
    color: rgba(45, 45, 73, 1);
  }
  .script {
    line-height: 1;
    font-size: 1.3rem;
    color: rgba(110, 110, 136, 1);
  }
  .three-cards {
    margin: 73px 69px 33px;
    width: 320px;
    height: 167px;
    @media screen and (max-width: 1374px) and (min-width: 1028px) {
      width: 20vw;
    }
    @media screen and (max-width: 980px) {
      width: 280px;
    }
    .serial {
      box-shadow: -4px -4px 4px rgba(244, 74, 79, 0.4);
      text-align: center;
      font-size: 20px;
      color: rgba(255, 255, 255, 1);
      line-height: 36px;
      width: 36px;
      height: 36px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 50%;
      margin-bottom: 20px;
    }
    .steps-title {
      line-height: 1.2;
      font-size: 20px;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
      margin-bottom: 14px;
    }
    .steps-script {
      line-height: 30px;
      font-size: 18px;
      color: rgba(124, 124, 153, 1);
    }
  }
  .button {
    cursor: pointer;
    margin-bottom: 30px;
    text-align: center;
    line-height: 56px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    width: 270px;
    height: 56px;
    border: 1.5px solid #ffffff;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 28px;
  }
  .subscript {
    cursor: pointer;
    margin-bottom: 79px;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 400;
    color: rgba(124, 124, 153, 1);
  }
}
.my_serve {
  margin-top: 48px;
  max-width: 1360px;
  padding: 32px;
  .title {
    font-size: 32px;
    line-height: 32px;
    color: rgba(45, 45, 73, 1);
    margin-bottom: 21px;
  }
  .script {
    font-size: 20px;
    line-height: 20px;
    color: rgba(124, 124, 153, 1);
    margin-bottom: 56px;
  }
  .project-card {
    margin-top: 30px;
    width: 1160px;
    height: 680px;
    background-color: rgba(250, 250, 250, 1);
    border-radius: 10px;
  }
  .half-img {
    margin-left: -30px;
    margin-top: 49px;
    min-width: 578px;
    width: 50%;
    height: auto;
  }
  .right-half {
    margin-top: 23px;
    width: 50%;
    min-width: 460px;
    .title {
      margin: 0 0 10px 15px;
      font-weight: bold;
      line-height: 20px;
      font-size: 20px;
      color: rgba(45, 45, 73, 1);
    }

    .script {
      margin: 0 0 38px 15px;
      line-height: 20px;
      font-size: 20px;
      color: rgba(124, 124, 153, 1);
    }

    .small-icon {
      width: 29px;
      height: 29px;
    }
  }

  .small-title {
    line-height: 24px;
    font-size: 24px;
    color: rgba(124, 124, 153, 1);
    margin-bottom: 30px;
  }

  .small-redline {
    width: 60px;
    height: 4px;
    background-color: rgba(244, 74, 79, 1);
    margin-bottom: 21px;
  }

  .main-content {
    line-height: 42px;
    font-weight: bold;
    font-size: 28px;
    color: rgba(45, 45, 73, 1);
    margin-bottom: 55px;
  }
}
.job_type {
  height: 598px;
  background: rgba(250, 250, 250, 1)
    url('http://moke-store.oss-cn-beijing.aliyuncs.com/388bb923-3874-4a55-8c7a-d4d3144ebb70.png')
    100% 25%;
  text-align: center;
  width: 100%;
  padding-bottom: 55px;
  .four-icon {
    margin-top: 66px;
    .card {
      margin: auto 120px;
    }
  }
  .small-title {
    margin: 81px auto 29px;
    line-height: 24px;
    font-size: 24px;
    color: rgba(124, 124, 153, 1);
  }

  .small-redline {
    width: 60px;
    height: 4px;
    background-color: rgba(244, 74, 79, 1);
  }

  .main-content {
    margin-top: 21px;
    line-height: 42px;
    font-weight: bold;
    font-size: 28px;
    color: rgba(45, 45, 73, 1);
  }

  .redline {
    padding-left: 140px;
    margin: 30px 0 29px 4px;
    .thin-redline {
      margin-top: 8px;
      width: 287px;
      height: 2px;
      background-color: rgba(251, 183, 185, 1);
    }

    .small-point {
      width: 18px;
      height: 18px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 50%;
    }
  }

  .four-cards {
    margin-left: 4px;
    .cards {
      width: 176px;
      margin-right: 130px;
      transform: translate(65px, 0);
    }

    .title {
      line-height: 20px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
    }

    .script {
      width: 176px;
      margin-top: 21px;
      line-height: 23px;
      font-size: 16px;
      color: rgba(124, 124, 153, 1);
    }
  }
}
@media screen and (max-width: 600px) {
  .click-button {
    margin-left: 7px;
    .button {
      transform: translate(-6px, 0);
      text-align: center;
      margin: 15px auto 56px;
      width: auto;
      height: auto;
      border: 1.5px solid rgba(124, 124, 153, 1);
      border-radius: 15px;
      line-height: 28px;
      font-size: 12px;
      color: rgba(124, 124, 153, 1);
    }
  }
  .item-2 {
    margin: 16px auto;
    div {
      margin: 10px;
      width: 100px;
      height: 30px;
      padding: 5px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .item-3 {
    padding: 0px;
    .script {
      font-size: 12px;
      margin-bottom: 27px;
    }
    .project-card {
      display: none;
    }
    .mobile-project-card {
      background-size: 100% 100%;
      display: inline;
      margin-bottom: 20px;
      min-width: 345px;
      width: 90%;
      height: 493px;
      background-color: rgba(250, 250, 250, 1);
      border-radius: 5px;
    }
    .down {
      padding-left: 16px;
      .down-firstline {
        margin-top: 236px;
        .down-img {
          width: 34px;
          height: 34px;
        }
        .down-title {
          margin-left: 12px;
          line-height: 34px;
          font-size: 16px;
          font-weight: bold;
          color: rgba(45, 45, 73, 1);
        }
      }
      .down-script {
        margin: 10px 103px 20px 0;
        font-size: 12px;
        color: rgba(124, 124, 153, 1);
      }
      .down-subtitle {
        font-size: 14px;
        color: rgba(45, 45, 73, 1);
      }
      .content-card {
        padding: 0 10px;
        margin: 15px auto auto 15px;
        height: 25px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 4px;
        line-height: 25px;
        font-size: 12px;
        color: rgba(45, 45, 73, 1);
      }
      .button {
        transform: translate(-6px, 0);
        margin: 15px auto 0;
        text-align: center;
        width: 120px;
        height: 30px;
        border: 1.5px solid rgba(124, 124, 153, 1);
        border-radius: 15px;
        line-height: 28px;
        font-size: 12px;
        color: rgba(124, 124, 153, 1);
      }
    }
  }
  .item-4 {
    margin-bottom: 30px;
    .card {
      display: none;
      width: 160px;
    }
    .mobile_card {
      display: inline;
      .small_image {
        width: 90%;
        min-width: 345px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .small_title {
        padding-left: 20px;
        margin-top: 54px;
        text-align: left;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2d2d49;
        margin-bottom: 30px;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }
    .small-card {
      width: 160px;
      height: 82px;
      background-color: rgba(250, 250, 250, 1);
      border-radius: 5px;
    }
    .card-script {
      line-height: 11px;
      font-size: 11px;
      color: rgba(45, 45, 73, 1);
      margin-top: 10px;
    }
  }
  .item-4-button {
    display: none;
  }
  .item-new5_wrap {
    .big_title {
      margin: 41px auto 30px;
      width: 231px;
      height: auto;
      font-size: 20px;
    }
    .item-new5 {
      .left_content {
        width: 100%;
        height: auto;
        font-size: 24px;
        min-width: 0;
        padding: 0 15px 45px;
        font-size: 10px;
        .circle {
          margin: 27px auto 13px;
          width: 18px;
          height: 18px;
        }
        .text {
          width: 100%;
          height: auto;
          text-align: center;
          .title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 15px;
          }
          .script {
            font-size: 12px;
          }
        }
      }
      .right_content {
        width: 100%;
        padding: auto 15px;
        min-width: 0;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
    }
    .click-button {
      .button {
        margin: 10px auto 35px;
      }
    }
  }
  .item-7 {
    margin-bottom: 40px;
    .title {
      padding: 0 50px;
      text-align: center;
      margin-top: 40px;
      line-height: 30px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
    }
    .script {
      margin: 9px auto 20px;
      line-height: 12px;
      font-size: 12px;
      color: rgba(124, 124, 153, 1);
    }
    .card {
      padding: 12px;
      width: 160px;
      height: 224px;
      background-color: rgba(250, 250, 250, 1);
      border-radius: 5px;
      .img {
        margin-top: 0px;
        height: auto;
      }
      img {
        margin-top: 0px;
        width: auto;
        height: 90px;
      }
      .card-title {
        margin: 0;
        padding-top: 10px;
        font-weight: bold;
        line-height: 1.5;
        font-size: 15px;
        color: rgba(45, 45, 73, 1);
      }
      .card-script {
        font-size: 12px;
        padding-top: 10px;
        margin: 0;
        text-align: justify;
        text-justify: distribute-all-lines;
        line-height: 1.5;
        color: rgba(124, 124, 153, 1);
      }
    }
    .button {
      margin: 20px 0 10px;
      border: 1.5px solid #ffffff;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 20px;
      text-align: center;
      width: 200px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      color: white;
    }
  }
  .item-8 {
    .title {
    }
    .script {
      font-size: 12px;
    }
    .three-cards {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      height: 106px;
      .serial {
        font-size: 16px;
        line-height: 19px;
        width: 18px;
        height: 18px;
        margin: 24px auto 10px;
      }
      .steps-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .steps-script {
        line-height: 1;
        font-size: 12px;
      }
    }
    .button {
      text-align: center;
      width: 200px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      border-radius: 20px;
      color: white;
      border: 1.5px solid #ffffff;
      margin-bottom: 20px;
    }
    .subscript {
      font-size: 11px;
      margin-bottom: 35px;
    }
  }
  .job_type {
    width: 100%;
    height: auto;
    padding: 30px 0;
    .small-title {
      margin-top: 0px;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .small-redline {
      width: 30px;
      height: 2px;
      margin: 0px auto;
    }
    .main-content {
      text-align: center;
      line-height: 21px;
      font-weight: bold;
      font-size: 14px;
      color: rgba(45, 45, 73, 1);
      margin-bottom: 17px;
    }
    .cards {
      .small-icon {
        width: 29px;
        height: 29px;
      }
      .title {
        margin: 14px auto 10px;
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        color: rgba(45, 45, 73, 1);
      }
      .script {
        font-size: 12px;
        line-height: 12px;
        color: rgba(124, 124, 153, 1);
        margin-bottom: 21px;
      }
    }
  }
  .my_serve {
    padding: 0 0 20px;
    .half-img {
      width: 100%;
      max-width: 430px;
      height: auto;
      margin: 0;
      min-width: 0px;
    }
    .right-half {
      text-align: center;
      .small-title {
        font-size: 12px;
        margin-bottom: 12px;
      }
      .small-redline {
        width: 30px;
        height: 2px;
        margin: 0 auto 20px;
      }
      .main-content {
        font-size: 14px;
        line-height: 1.5;
      }
      .content {
        text-align: center;
        width: 100%;
        flex-direction: column;
        .small-icon {
          margin: auto;
        }
        .title {
          margin: 14px 0 0 0;
          font-size: 12px;
        }
        .script {
          width: 100%;
          height: auto;
          text-align: center;
          line-height: 1;
          font-size: 12px;
          margin-bottom: 24px;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
