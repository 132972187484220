import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/index/index'
import About from './views/index/about'
import Project from './views/index/project'
import Download from './views/index/download'
import Minipro from './views/index/Minipro'
import serve from './views/index/serve'
import oneProject from './views/index/oneProject'
import supplierAllBack from './components/supplierAllBack'

Vue.use(Router)

export default new Router({
  mode: 'history',
  // mode: 'hash', //打包模式
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/download',
      name: 'download',
      component: Download
    },
    {
      path: '/project',
      name: 'project',
      component: Project
    },
    {
      path: '/minipro',
      name: 'minipro',
      component: Minipro
    }
    ,
    {
      path: '/serve',
      name: 'serve',
      component: serve
    },
    {
      path: '/oneProject',
      name: 'oneProject',
      component: oneProject
    },
    {
      path: '/supplierAllBack',
      name: 'supplierAllBack',
      component: supplierAllBack
    }
  ]
})
