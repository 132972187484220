export default {
  data () {
    return {
      threeWorkPic: ["http://moke-store.oss-cn-beijing.aliyuncs.com/df705f53-9842-4544-9f98-c47e4b87a537.jpeg",
        "http://moke-store.oss-cn-beijing.aliyuncs.com/10d1797a-3e1f-4177-bc67-0a2e2862ea8c.jpeg",
        "http://moke-store.oss-cn-beijing.aliyuncs.com/20fe15ed-49bd-4c4d-add3-1196b6415389.jpeg"],
      solveWay: [{
        title: 'APP开发',
        script: '先进稳定的跨平台解决方案，安卓，苹果一次解决',
        back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5c80d71b-81ea-44ef-ada3-7f61ec7d028a.png'
      },
      {
        title: '微信开发',
        script: '小程序/公众号，各类行业解决方案，一步到位',
        back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/da52942f-e628-400a-905b-9ca23ee658d2.png?x-oss-process=image/resize,w_200'
      },
      {
        title: '智能设备开发',
        script: '针对各种智能场景量身定制，满足用户需求',
        back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6749e607-ce6e-4061-a6a6-9c585dcf800c.jpeg?x-oss-process=image/resize,w_200'
      },
      {
        title: '网站开发',
        script: '真正的响应式网站开发，手机，电脑网站一次搞定',
        back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/caabc9e4-0cce-4198-b30b-7135d5d81d5a.png?x-oss-process=image/resize,w_200'
      }],
      otherProject: [
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png',
          title: '应用商城',
          children: [
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/e5501a5e-5f6e-4af9-ad6e-262190d27db1.png) no-repeat',
              script: '商城定制开发案例',
              projectIndex: 4
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/b9e7d621-8132-4ae5-a790-20c45efa9c05.png) no-repeat',
              script: '本地化权益服务案例',
              projectIndex: 3
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/ef8e1551-1d50-4544-a5b9-5ca95b2d3909.png) no-repeat',
              script: '多伦比亚房产销售应用案例',
              projectIndex: 10
            },
          ]
        },
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png',
          title: '教培生服',
          children: [
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/fa543186-4cdf-4377-bfa6-c6049e1647ef.png) no-repeat',
              script: '教育管理系统案例',
              projectIndex: 5
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/73300151-16fa-4ef9-a4c4-70731bb9fc98.png) no-repeat',
              script: '在线考试管理系统案例',
              projectIndex: 9
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/04386aea-5e13-403b-8795-4016d8530b97.png) no-repeat',
              script: '教学在线朗读应用案例',
              projectIndex: 11
            },
          ]
        },
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png',
          title: '政府项目',
          children: [
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/5ef4c6ff-1c8d-4888-a6fa-e9d75a8c9da0.png) no-repeat',
              script: '政府合作交易平台案例',
              projectIndex: 14
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/63808be9-cbd3-45bc-b876-ec8a578b1255.png) no-repeat',
              script: '政府合作公园路线导览案例',
              projectIndex: 15
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/96298596-6373-4954-9526-9826bb3529ed.png) no-repeat',
              script: '政府合作监督平台案例',
              projectIndex: 13
            },
          ],
        },
        {
          icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png',
          title: '其他类型',
          children: [
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/57d83a9d-e554-46db-9b0a-6941c03b6671.png) no-repeat',
              script: '硬件设备控制案例',
              projectIndex: 6
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/83e9c392-40d9-429b-8bbd-ed346188491e.png) no-repeat',
              script: '国外纹身应用案例',
              projectIndex: 7
            },
            {
              back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/0334cc82-26e2-4b59-910a-05ef1a32c3ea.png) no-repeat',
              script: '大型储存运输管理应用案例',
              projectIndex: 8
            },
          ]
        },
      ],
      caseProject: [
        {
          title: '政府项目',
          back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/efd7d6e8-302f-418f-97a6-0f0219e9d231.png?x-oss-process=image/resize,w_560,h_300',
          script: '政府合作监督平台案例',
          projectIndex: 13
        },
        {
          title: '应用商城',
          back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0dc27996-376a-4112-9bf7-873a217abce9.png?x-oss-process=image/resize,w_560,h_300',
          script: '商城定制开发案例',
          projectIndex: 4
        },
        {
          title: '教培生服',
          back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8f43490e-32b2-456f-bb47-cf77f4ca3f57.png?x-oss-process=image/resize,w_560,h_300',
          script: '教育管理系统案例',
          projectIndex: 5
        },
        {
          title: '其他类型',
          back: 'http://moke-store.oss-cn-beijing.aliyuncs.com/86c9b519-3a73-4417-af8c-2dc678271014.png?x-oss-process=image/resize,w_560,h_300',
          script: '智能饮水机软件应用',
          projectIndex: 12
        },
      ],
      smallProjects: [
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/57d83a9d-e554-46db-9b0a-6941c03b6671.png) no-repeat',
          script: '硬件设备控制案例',
          projectIndex: 6
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/83e9c392-40d9-429b-8bbd-ed346188491e.png) no-repeat',
          script: '国外纹身应用案例',
          projectIndex: 7
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/0334cc82-26e2-4b59-910a-05ef1a32c3ea.png) no-repeat',
          script: '大型储存运输管理应用案例',
          projectIndex: 8
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/fa543186-4cdf-4377-bfa6-c6049e1647ef.png) no-repeat',
          script: '教育管理系统案例',
          projectIndex: 5
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/73300151-16fa-4ef9-a4c4-70731bb9fc98.png) no-repeat',
          script: '在线考试管理系统案例',
          projectIndex: 9
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/04386aea-5e13-403b-8795-4016d8530b97.png) no-repeat',
          script: '教学在线朗读应用案例',
          projectIndex: 11
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/e5501a5e-5f6e-4af9-ad6e-262190d27db1.png) no-repeat',
          script: '商城定制开发案例',
          projectIndex: 4
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/b9e7d621-8132-4ae5-a790-20c45efa9c05.png) no-repeat',
          script: '本地化权益服务案例',
          projectIndex: 3
        },
        {
          back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/ef8e1551-1d50-4544-a5b9-5ca95b2d3909.png) no-repeat',
          script: '多伦比亚房产销售应用案例',
          projectIndex: 10
        },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/4fbeb854-5dd0-45cd-bd82-5d8169adf8b6.png) no-repeat',
        //   script: '用于预约驾校练车',
        //   projectIndex: 3
        // },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/04386aea-5e13-403b-8795-4016d8530b97.png) no-repeat',
        //   script: '用于教学的在线朗读应用',
        //   projectIndex: 4
        // },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/ef8e1551-1d50-4544-a5b9-5ca95b2d3909.png) no-repeat',
        //   script: '多伦比亚房产销售应用',
        //   projectIndex: 5
        // },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/0334cc82-26e2-4b59-910a-05ef1a32c3ea.png) no-repeat',
        //   script: '大型储存运输管理应用',
        //   projectIndex: 6
        // },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/73300151-16fa-4ef9-a4c4-70731bb9fc98.png) no-repeat',
        //   script: '在线考试管理系统',
        //   projectIndex: 7
        // },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/b9e7d621-8132-4ae5-a790-20c45efa9c05.png) no-repeat',
        //   script: '本地化权益服务',
        //   projectIndex: 0
        // },
        // {
        //   back: 'background:center/contain url(http://moke-store.oss-cn-beijing.aliyuncs.com/c63c8875-c40b-4e22-81dc-258d6a580994.png) no-repeat',
        //   script: '智能水机系统',
        //   projectIndex: 2
        // }
      ],
      steps: [
        { title: '告诉我们您的需求', script: '简单的文字或专业的文档我们都能处理。' },
        { title: '量身定制开发方案', script: '将您的需求整理为专业的开发计划并给出我们的建议。' },
        { title: '确定开发计划', script: '从UI设计到产品上线，一切尽在掌握。' }
      ],
      talkGirlimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8cb4047a-6695-4bd6-956e-c159f6a2ea6a.png?x-oss-process=image/resize,w_98,h_98',
      iconImg: {
        right: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d295f543-b158-48a4-aa59-b6acd800de5b.png?x-oss-process=image/resize,w_32',
        under: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8482daef-7008-4f6d-a59e-f88a43caa337.png?x-oss-process=image/resize,w_32',
        position: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8656e35e-fd43-40c6-88bb-a4ab9a4afccc.png?x-oss-process=image/resize,w_32',
        enterprise: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1d8821d2-cb84-4a1a-b0d6-243423bf08c7.png?x-oss-process=image/resize,w_32',
        lead: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a13b3dfa-a86f-4cec-9635-7263ace4fb48.png?x-oss-process=image/resize,w_32',
        Office: 'http://moke-store.oss-cn-beijing.aliyuncs.com/129d8f40-e9e7-4475-8d18-43524e6d065d.png?x-oss-process=image/resize,w_32',
        Focus: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1b322e42-a456-4990-88ac-2881c56b524e.png?x-oss-process=image/resize,w_32',
        open: 'http://moke-store.oss-cn-beijing.aliyuncs.com/afcbf58d-56d3-48a3-9ed4-59b6627e1808.png?x-oss-process=image/resize,w_32',
        Code: 'http://moke-store.oss-cn-beijing.aliyuncs.com/81a005ca-28e7-435c-a0a1-d300d931195e.png?x-oss-process=image/resize,w_32',
        alternate: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b932c742-f5b2-43a3-b5a5-70507fcfa350.png?x-oss-process=image/resize,w_32',
        project: 'http://moke-store.oss-cn-beijing.aliyuncs.com/601467f6-9632-4440-a063-63e2912011bf.png?x-oss-process=image/resize,w_32',
        en: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6e7fcacd-7dd4-4be8-b20e-b22ae6d7ed3b.png?x-oss-process=image/resize,w_32',
        Talent: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5480b22c-ea48-4f03-979e-dc75507d40ca.png?x-oss-process=image/resize,w_32',
        Sales: 'http://moke-store.oss-cn-beijing.aliyuncs.com/60a6f55d-19eb-4c00-bf99-f40744486c0f.png?x-oss-process=image/resize,w_32',
        windows: 'http://moke-store.oss-cn-beijing.aliyuncs.com/209b9772-2043-4900-8660-79af30f2ec4d.png?x-oss-process=image/resize,w_32',
        ipad: 'http://moke-store.oss-cn-beijing.aliyuncs.com/896ed81c-12ee-4072-b039-ed24b2ed26eb.png?x-oss-process=image/resize,w_32',
        devise: 'http://moke-store.oss-cn-beijing.aliyuncs.com/02b2cd2c-e760-492f-8f5f-46e3dd0dac51.png?x-oss-process=image/resize,w_32',
        game: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d7576edd-4367-4383-9366-33eafcd3d6f5.png?x-oss-process=image/resize,w_32',
        ios: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e3c66105-4e26-4baa-ae30-9d30ad88d3d0.png?x-oss-process=image/resize,w_32',
        idea: 'http://moke-store.oss-cn-beijing.aliyuncs.com/20c446fd-f3fa-4289-92a5-83d04ed08db5.png?x-oss-process=image/resize,w_32',
        WeChat: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fe00cc92-d17e-4d3f-beab-dc06e58b3b3e.png?x-oss-process=image/resize,w_32'
      },
    }
  },
  computed: {
    onMobile: function () {
      return this.$store.state.onMobile
    },
    day () {
      let d = new Date()
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
    },
    showPreview: {
      get: function () {
        return this.$store.state.showPreview
      },
      set: function () {
        this.$store.commit('setIsshow', false)
      }
    }
  },
  methods: {
    showPreviewf () {
      this.$store.commit('setIsshow', true)
    },
    closePreview () {
      this.$store.commit('setIsshow', false)
    },
    scrollBanner: function () {
      let moveY = 800
      let getExplorer = (function () {
        let explorer = window.navigator.userAgent,
          compare = function (s) {
            return (explorer.indexOf(s) >= 0)
          },
          ie11 = (function () {
            return ("ActiveXObject" in window)
          })()
        if (compare("MSIE") || ie11) {
          return 'ie'
        } else if (compare("Firefox") && !ie11) {
          return 'Firefox'
        } else if (compare("Chrome") && !ie11) {
          if (explorer.indexOf("Edge") > -1) {
            return 'Edge'
          } else {
            return 'Chrome'
          }
        } else if (compare("Opera") && !ie11) {
          return 'Opera'
        } else if (compare("Safari") && !ie11) {
          return 'Safari'
        }
      })()
      if (this.onMobile) {
        moveY = 600
      }
      if (getExplorer === 'ie' || getExplorer === 'Edge' || getExplorer === 'Safari') {
        window.scrollTo(0, moveY)
      } else {
        window.scrollTo({
          top: moveY,
          behavior: "smooth"
        })
      }
    },
    scrollBannerMobile: function () {
      if (navigator.userAgent.match(/Trident\/7\./)) {
        window.scrollTo(0, 400)
      } else {
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        })
      }
    },

  }
}
