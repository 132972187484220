<template>
	<div>
		<Pc :key="key"></Pc>
	</div>
</template>
<script>
    import Pc from '../../components/pcOneproject'
    export default {
        name: 'OneProject',
        components: {
            Pc,
        },
		computed: {
			key () {
				return this.$route.path + Math.random()
			}
		}
    }
</script>
