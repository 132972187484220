<template>
	<div class="pc-about">
		<div class="flex_column align-center banner">
			<lazy-component class="fade-in">
				<div class="title-2">嘿，我们是默客</div>
			</lazy-component>
			<lazy-component class="fade-in">
				<div class="title-3">我们相信，通过共同努力，我们可以使<br/>所有企业减轻<span style="color:#F4365A">开发负担</span></div>
			</lazy-component>
			<lazy-component class="fade-in">
				<div class="click-button">
					<div class="button" @click="scrollBanner">
						<img :src="iconImg.under" class="imgicon-18">
					</div>
				</div>
			</lazy-component>
		</div>

		<div class="flex_column  align-center item-1">
			<div class="title">我们的团队</div>
			<div class="script">默客隶属于石家庄谨思科技有限公司、成立于2014年，是一家致力于为IT创业公司提供高质量技术支撑服务的公司，<br>创立至今为多家企业提供了专业的互联网解决方案。</div>
			<div class="flex_row  wrap flex_center page_attr">
					<div class="flex_column card" v-for="(item,index) in teamItems" :key="index">
						<div class="left-num">{{'0'+(index+1)}}</div>
						<div class="flex_column justify-center align-center inner-part">
							<div style="height: 40px"><img :src="iconImg[item.icon]" class="imgicon-45"></div>
							<div class="center-num">{{item.num}}</div>
							<div class="script">{{item.script}}</div>
						</div>
					</div>
			</div>
		</div>

		<div class="gray-line"></div>

		<div class="flex_column flex_center item-2">
			<div class="title">为什么选择我们？</div>
			<div class="script">我们专注于您和您的产品</div>
			<div class="flex wrap justify-center more_cards">
				<div class="flex_column cards" v-for="(item,index) in nineItems1" :key="index">
					<div class="flex flex_center item-2-icon">
						<img :src="iconImg[item.icon]" class="imgicon-20">
						<div class="cards-title">{{item.title}}</div>
					</div>
					<div class="cards-script">{{item.script}}</div>
				</div>
			</div>
		</div>

		<div class="gray-line"></div>

		<div class="flex_column flex_center page-item item-3">
			<div class="title">感受我们企业精神</div>
			<div class="script">默客隶属于石家庄谨思科技有限公司、成立于2014年，是一家致力于为IT创业公司提供高质量技术支撑服务的公司，</div>
			<div class="script">创立至今为多家企业提供了专业的互联网解决方案。</div>
			<div class="flex wrap flex_center three-img page_attr">
				<div class="large">
					<img class="fade-in" v-lazy=threeWorkPic[0]>
				</div>
				<div class="flex_column smalls">
					<div class="small"><img class="fade-in" v-lazy=threeWorkPic[1]></div>
					<div class="small"><img class="fade-in" v-lazy=threeWorkPic[2]></div>
				</div>
			</div>
		</div>

		<div class="gray-line"></div>

        <estimate></estimate>
		<estimate-alert></estimate-alert>
	</div>
</template>
<script>
    import estimate from './estimate'
	import EstimateAlert from './EstimateAlert'
    export default {
        components: {estimate, EstimateAlert},
        name: 'pcAbout',
        data() {
            return {
                teamItems: [{icon: 'position', num: '32', script: '位于石家庄默客的技术人才'}, {
                    icon: 'enterprise',
                    num: '21',
                    script: '家集团企业技术合作'
                }, {icon: 'lead', num: '领先7', script: '年国内技术团队'}],
                nineItems1: [{icon: 'Office', title: '100%办公室团队', script: '我们是100%办公团队，具有透明便捷沟通等分散不具备的优势'},
                    {icon: 'Focus', title: '100％专注于您', script: '开发团队完全致力于您的项目，因此他们可以完全专注于交付您的需求。'},
                    {icon: 'open', title: '100%透明', script: '您可以随时随地访问项目的进度。邀请您使用我们的工具，查看状态。'}, {icon: 'Code', title: '高质量代码', script: '我们的代码是在考虑最佳实践的前提下编写的，并且由于外部代码的审查和持续集成，它始终清晰且一致。'},
                    {icon: 'alternate', title: '无缝沟通', script: '每天您都可以通过 后台项目管理，项目经理等与我们联系，因此您可以确切了解工作的进展情况。'},
                    {icon: 'project', title: '项目经验丰富', script: '我们有大大小小软硬件合作项目，积累了丰富的项目经验。'}, {icon: 'en', title: '英文流利', script: '我们公司有流利的英语对接，因此理解您的目标是什么以及我们为实现该目标正在做的事情不会有问题。'},
                    {icon: 'Talent', title: '高技术人才', script: '我们只雇用最优秀的人才，在我们公司里技术经验优势'},
                    {icon: 'Sales', title: '没有销售团队', script: '我们只专注与技术开发，公司不具有销售团队推销业务，保证您的资金只用于研发'}],
                threeWorkPic: ["http://moke-store.oss-cn-beijing.aliyuncs.com/df705f53-9842-4544-9f98-c47e4b87a537.jpeg?x-oss-process=image/resize,w_770,h_538",
                    "http://moke-store.oss-cn-beijing.aliyuncs.com/10d1797a-3e1f-4177-bc67-0a2e2862ea8c.jpeg?x-oss-process=image/resize,w_360,h_255",
                    "http://moke-store.oss-cn-beijing.aliyuncs.com/20fe15ed-49bd-4c4d-add3-1196b6415389.jpeg?x-oss-process=image/resize,w_360,h_255"],
            }
        }
    }
</script>
<style scoped lang="scss">
	.banner {
		margin-top: 70px;
		width: 100%;
		height: 860px;
		background: url("http://moke-store.oss-cn-beijing.aliyuncs.com/4b96d486-bac5-44d8-9c1a-50acbe79dfba.jpeg") center center no-repeat;
		background-size: cover;
		.title-2 {
			margin-top: 253px;
			line-height: 40px;
			font-size: 40px;
			color: rgba(255, 255, 255, 1);
		}

		.title-3 {
			margin-top: 30px;
			text-align: center;
			line-height: 75px;
			font-weight: bold;
			font-size: 50px;
			color: rgba(255, 255, 255, 1);
		}

		.button {
			cursor: pointer;
			text-align: center;
			margin-top: 64px;
			width: 46px;
			height: 46px;
			line-height: 46px;
			background-color: rgba(244, 74, 79, 1);
			border-radius: 50%;
			font-size: 24px;
			color: white;
		}
	}

	.item-1 {
		margin-bottom: 79px;

		.title {
			margin: 79px auto 12px;
			line-height: 1;
			font-size: 32px;
			font-weight: bold;
			color: rgba(45, 45, 73, 1);
		}

		.script {
			margin-bottom: 54px;
			text-align: center;
			line-height: 35px;
			font-size: 20px;
			color: rgba(110, 110, 136, 1);
		}

		.card {
			margin: 15px 15px;
			width: 366px;
			height: 300px;
			background: rgba(250, 250, 250, 1) url('http://moke-store.oss-cn-beijing.aliyuncs.com/aacf5a5f-c114-49f4-ab0c-979c579c2875.jpeg') center center no-repeat;
			border-radius: 4px;

			.left-num {
				margin: 20px;
				line-height: 1;
				font-size: 20px;
				color: rgba(124, 124, 153, 1);
			}

			.inner-part {
				width: 366px;
			}

			.center-num {
				margin: 49px auto 20px;
				line-height: 1;
				font-size: 60px;
				color: rgba(45, 45, 73, 1);
			}

			.script {
				line-height: 1;
				font-size: 20px;
				color: rgba(124, 124, 153, 1);
			}

		}
	}

	.item-2 {
		height: auto;
		margin: auto;
		max-width: 1380px;
		.title {
			margin-top: 79px;
			line-height: 32px;
			font-weight: bold;
			font-size: 32px;
			color: rgba(45, 45, 73, 1);
		}
		.script {
			margin: 19px auto 65px;
			line-height: 20px;
			font-size: 20px;
			color: rgba(110, 110, 136, 1);
		}
		.more_cards { width: 100% }
		.cards {
			margin: 45px 40px;
			width: 330px;
			.cards-title {
				margin-left: 11px;
				font-size: 18px;
				font-weight: bold;
				color: rgba(45, 45, 73, 1);
			}
			.cards-script {
				width: 330px;
				margin-top: 13px;
				line-height: 23px;
				font-size: 16px;
				color: rgba(110, 110, 136, 1);
			}
		}
	}

	.item-3 {
		margin-bottom: 80px;
		.title {
			font-weight: bold;
			margin: 81px auto 22px;
			line-height: 1;
			font-size: 32px;
			color: rgba(45, 45, 73, 1);
		}

		.script {
			padding: 0 20px;
			line-height: 1.8;
			font-size: 20px;
			color: rgba(110, 110, 136, 1);
		}

		.three-img {
			margin-top: 62px;
			.large {
				margin-right: 30px;
				width: 770px;
				height: 538px;
				@media screen and (max-width: 1178px){
					margin-right: 0;
				}
				border-radius: 10px;
			}
			.smalls {
				@media screen and (max-width: 1178px){
					margin-top: 20px;
					flex-direction: row;
				}
				width: auto;
				min-width: 360px;
			}
			.small {
				margin: 15px 0;
				height: 254px;
				border-radius: 10px;
			}
		}
		.button {
			cursor: pointer;
			margin: 40px auto 78px;
			line-height: 50px;
			text-align: center;
			width: 160px;
			height: 50px;
			border: 1.5px solid rgba(124, 124, 153, 1);
			border-radius: 25px;
			font-size: 16px;
			color: rgba(124, 124, 153, 1);
		}
	}
	@media screen and (max-width: 600px) {
		.banner {
			width: 100%;
			height: 600px;
			margin: auto;
			.title-2 {
				margin-top: 180px;
				font-size: 18px;
				line-height: 1;
			}
			.title-3 {
				font-size: 25px;
				width: 274px;
				line-height: 1.5;
			}
			.button {
				&:hover {
					opacity: 1;
				}
				img {
					opacity: 1;
				}
				&:active {
					opacity: 0.5;
				}
			}
		}
		.item-1 {
			margin-bottom: 30px;
			.title {
				margin-top: 40px;
				font-size: 20px;
			}
			.script {
				padding: 0 12px;
				font-size: 12px;
				line-height: 2;
			}
			.card {
				width: 315px;
				height: 315px;
				.inner-part {
					width: 100%;
					margin-top: 36px;
				}
				.center-num {
					font-size: 25px;
				}
				.script {
					font-size: 12px;
					margin-bottom: 70px;
				}
			}
		}
		.item-2 {
			padding-bottom: 20px;
			.title {
				margin-top: 30px;
				text-align: center;
				font-size: 20px;
			}
			.item-2-icon {
				width: 100%;
				flex-direction: column;
				img {
					margin-bottom: 12px;
				}
			}
			.script {
				margin-top: 14px;
				font-size: 10px;
				margin-bottom: 0px;
			}
			.cards {
				text-align: center;
				margin: 25px 20px;
				.cards-title {
					font-size: 14px;
					width: 100%;
				}
				.cards-script{
					font-size: 12px;
					line-height: 1.5;
				}
			}
		}
		.item-3 {
			margin-bottom: 20px;
			.title {
				margin-top: 40px;
				font-size: 20px;
			}
			.script {
				text-align: center;
				padding: 0 12px;
				margin-top: 2px;
				line-height: 2;
				font-size: 12px;
			}

			.three-img {
				margin-top: 16px;
				.large {
					margin: 0 auto;
					width: 335px;
					height: 210px;
					img {width: 100%; height: 100% }
				}
				.smalls {
					justify-content: center;
					.small {
						width: 160px;
						height: 105px;
						img { width: 100%; height: 100%}
					}
				}
			}
		}
	}

</style>
