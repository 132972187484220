import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        showPreview:false,
        preventScroll:false,
        onMobile:false,
    },
    getters: {
    },
    mutations: {
        setIsshow (state, showPreview) {
            state.showPreview = showPreview
        },
        setpreventScroll (state, preventScroll) {
            state.preventScroll = preventScroll
        },
        setonMobile (state, onMobile) {
            state.onMobile = onMobile
        },
    },
    actions: {}
})
export default store
