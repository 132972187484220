<template>
  <div id="app">
    <component :is="topMenu"></component>
    <router-view/>
    <site-footer></site-footer>
    <!--<tk-global :components="components" ></tk-global>-->
  </div>
</template>
<script>
  import TopMenumobile from './components/topMenumobile'
  import TopMenu from './components/topMenu'
  import SiteFooter from './components/footer'
  import Alert from './components/alert'
  import Waiting from './components/waiting'
  export default {
    name: 'app',
    data () {
      return {
        ready: false,
        components: [{
          name: 'alert',
          component: Alert
        }, {
          name: 'waiting',
          component: Waiting,
          props: {
            show: false
          }
        }]
      }
    },
    computed:{
      topMenu () {
        return 'TopMenu'
      }
    },
    mounted () {
      this.ready = true
      setInterval(() => {
        if (window.gtag) {
          window.gtag('event', 'pageView')
        }
        if (window._hmt) {
          window._hmt.push(['_trackEvent', 'page', 'view'])
        }
      }, 3000)
      let onMobile = window.innerWidth < 600
      this.$store.commit('setonMobile', onMobile)
      let that = this
      window.onresize = () => {
        let onMobile = window.innerWidth < 600
        that.$store.commit('setonMobile', onMobile)
      }
    },
    components: {
      TopMenu,
      SiteFooter,
      TopMenumobile
    }
  }
</script>
