import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import VueTk from '@moke/vue-tk'
// import '@moke/vue-tk/dist/vue-tk.css'
import './assets/scss/_reset.scss'
import './assets/scss/main.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import store from '../src/store.js'
import mixin from './assets/js/mixin'
import vueFeedback from 'vue-feedback'
import VueLazyload from 'vue-lazyload'
// import tkImage from './components/image' // 找不到此模块 疑问？？
const lazyloadOptions = {
  preLoad: 1.3,
  // error: require('./assets/images/error.png'),
  // loading: require('./assets/images/loading.gif'),
  lazyComponent: true,
  attempt: 1
}
// Vue.use(tkImage)
Vue.use(VueLazyload, lazyloadOptions)
// Vue.component("tkImages", tkImages);
Vue.use(VueAwesomeSwiper)
Vue.use(vueFeedback)
Vue.config.productionTip = false
Vue.mixin(mixin)
const options={
  icon: { // 图标配置
    custom: {
      default: {
        code: 1482278,
        data: require('./assets/json/icon'),
        type: 'private',
        useClassAsName: true
      }
    }
  }
}
// VueTk.init(options)
router.afterEach((to, from, next) => {
  window.scrollTo(0,0)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
