<template>
  <div>
    <div class="flex justify-center top-menu">
      <div class="flex align-center justify-space-between top-menu-body">
        <router-link to="/">
          <div class="click-img p-t-2">
            <img :src="logoSrc" class />
          </div>
        </router-link>
        <div class="flex align-center justify-space-between menu-contain">
          <div class="flex justify-space-between menu">
            <router-link
              v-for="(item, key) in routeTo"
              :to="item.to"
              :key="key"
              class="menu-item"
              :class="{
                active:
                  nowRoute.path === item.to || nowRoute.path === item.to + '/',
              }"
            >{{ item.title }}</router-link>
          </div>
          <!--<div v-if="false">
                    <img class="language-icon"
                             src="http://moke-store.oss-cn-beijing.aliyuncs.com/5679eeaa-81cf-48f8-a160-45d01bc4d239.jpeg">
                    <span class="language">中文</span>
                    <img class="language-icon"
                             src="http://moke-store.oss-cn-beijing.aliyuncs.com/fc991054-a834-43eb-aecb-4ffcabd1ec00.jpeg">
                    <span class="language">EN</span>
          </div>-->
          <div class="click-button">
            <div class="button" @click="showPreviewf">
              免费评估项目
              <img :src="iconImg.right" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex_column mobile-menu-fix">
      <div class="flex align-center justify-space-between mobile-menu">
        <img
          class="logo"
          src="http://moke-store.oss-cn-beijing.aliyuncs.com/6cb2e743-0d1a-4097-b62f-023d9bf60cf2.png"
        />
        <div middle right class="flex align-center justify-end right-menu">
          <div v-if="false">
            <img
              class="language-icon"
              src="http://moke-store.oss-cn-beijing.aliyuncs.com/5679eeaa-81cf-48f8-a160-45d01bc4d239.jpeg"
            />
            <span class="language">中文</span>
            <img
              class="language-icon"
              src="http://moke-store.oss-cn-beijing.aliyuncs.com/fc991054-a834-43eb-aecb-4ffcabd1ec00.jpeg"
            />
            <span class="language">EN</span>
          </div>
          <div class="flex align-center justify-center icon-wrap" @click="showMenu">
            <!--<tk-icon size="15" color="white" >navigation</tk-icon>-->
            <!---->
            <svg
              t="1575354182231"
              v-if="!isShowmenu"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5278"
              width="128"
              height="128"
            >
              <path
                d="M256 734.101333v64H170.666667v-64h85.333333z m597.333333 0v64H298.666667v-64h554.666666zM256 493.184v64H170.666667v-64h85.333333z m597.333333 0v64H298.666667v-64h554.666666zM256 252.245333v64H170.666667v-64h85.333333z m597.333333 0v64H298.666667v-64h554.666666z"
                p-id="5279"
                fill="#ffffff"
              />
            </svg>
            <svg
              t="1575354294109"
              v-else
              viewBox="0 0 1045 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5547"
              width="128"
              height="128"
            >
              <path
                d="M282.517333 213.376l-45.354666 45.162667L489.472 512 237.162667 765.461333l45.354666 45.162667L534.613333 557.354667l252.096 253.269333 45.354667-45.162667-252.288-253.44 252.288-253.482666-45.354667-45.162667L534.613333 466.624l-252.096-253.226667z"
                p-id="5548"
                fill="#ffffff"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        class="flex_column align-center justify-space-between"
        :class="[isShowmenu ? 'route_menu' : 'inside_out']"
      >
        <div
          v-for="(item, key) in routeTo"
          :key="key"
          :class="[isShowmenu ? 'menu-item' : 'menu-item-none']"
        >
          <router-link
            :to="item.to"
            class="menu-item"
            :class="{
              active:
                nowRoute.path === item.to || nowRoute.path === item.to + '/',
            }"
            @click.native="closeMenu"
          >{{ item.title }}</router-link>
        </div>
        <div :class="[isShowmenu ? 'button' : 'button-none']">
          <div v-fb @click="handleEvents">
            免费评估项目
            <img :src="iconImg.right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowmenu: false,
      nowRoute: this.$router.currentRoute,
      routeTo: [
        { to: "/", title: "首页" },
        { to: "/serve/", title: "服务" },
        { to: "/project/", title: "开发案例" },
        { to: "/about/", title: "关于我们" },
      ],
    }
  },
  computed: {
    logoSrc: function () {
      return "http://moke-store.oss-cn-beijing.aliyuncs.com/6cb2e743-0d1a-4097-b62f-023d9bf60cf2.png"
    },
  },
  methods: {
    handleEvents () {
      this.showPreviewf()
      this.closeMenu()
    },
    showMenu: function () {
      this.isShowmenu = !this.isShowmenu
    },
    closeMenu: function () {
      this.isShowmenu = false
    },
  },
  watch: {
    $route (to) {
      if (this.nowRoute) {
        this.nowRoute = to
      }
    },
  },
};
</script>
<style scoped lang="scss">
.p-t-2 {
  padding-top: 2px;
}
a:hover {
  color: #f44a4f !important;
}
.icon {
  color: white;
}
.menu-contain {
  min-width: 500px;
  width: 60%;
}
.menu {
  font-weight: bold;
  margin-left: 0%;
  margin-right: 5%;
  width: 450px;
}
.minipro {
  color: black !important;
}
.top-menu {
  @media screen and (max-width: 806px) {
    display: none;
  }
  background-color: white;
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.minipro-menu {
  background-color: white;
  height: 170px;
}

.top-menu-body {
  padding-left: 35px;
  padding-right: 35px;
  max-width: 1280px;
  min-width: 600px;
  width: 100%;
  height: 70px;
}
.logo {
  padding-top: 2px;
  margin-right: 133px;
  height: 47px;
  width: 186px;
}
.button {
  margin-left: 20px;
  line-height: 40px;
  text-align: center;
  width: 160px;
  height: 40px;
  background-color: rgba(244, 74, 79, 1);
  border-radius: 20px;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.language {
  white-space: nowrap;
  margin-left: 14px;
  margin-right: 31px;
  line-height: 70px;
}
.language-icon {
  width: 28px;
  height: 28px;
}
.menu-item {
  width: auto;
  white-space: normal;
  margin-right: 10px;
  line-height: 16px;
  font-size: 16px;
  color: rgba(45, 45, 73, 1);
}
.active {
  color: #f44a4f !important;
}
.mobile-menu-fix {
  display: none;
  width: 100%;
  position: fixed;
  z-index: 100;
  @media screen and (max-width: 806px) {
    display: inline;
  }
  .mobile-menu {
    width: 100%;
    padding-left: 20px;
    height: 44px;
    background-color: rgba(255, 255, 255, 1);
  }

  .route_menu {
    transition: height 0.3s ease-out;
    padding-top: 1vh;
    position: relative;
    z-index: 99;
    width: 100%;
    height: 91vh;
    background-color: #20071b;
    .active {
      text-decoration: line-through;
    }
  }

  .inside_out {
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: 806px) {
  .language {
    margin-right: 10px;
    font-size: 12px;
    color: rgba(244, 74, 79, 1);
  }
  .language-icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  .logo {
    width: 93px;
    height: 23px;
  }
  .menu {
    width: 34px;
    height: 34px;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 5px;
  }
  .icon-wrap {
    margin-left: 15px;
    width: 34px;
    height: 34px;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 5px;
  }
  .right-menu {
    margin-right: 15px;
    width: 180px;
  }
  .menu-item {
    transition: opacity 0.5s linear;
    margin: 31px 0;
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
  }

  .menu-item-none,
  .button-none {
    opacity: 0;
  }
  .button {
    transition: opacity 0.5s linear;
    margin: 0 auto 50px;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
    width: 180px;
    height: 40px;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 20px;
  }
}
</style>
