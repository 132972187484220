export default {
    0: {
        title: 'iOS APP',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/245f5b57-42d8-4e36-8ce2-3d176d10ba01.jpeg',
        description: '我们负责ios端的开发已完成并且上线，可在苹果商场搜索下载使用'
    },
    1: {
       title: '安卓APP',
       img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/dc684188-7cf8-4be5-bc1a-44cdac931f59.jpeg',
       description: '适用于所有安卓系统手机的应用，在华为应用商城，小米应用商城等均可下载'
    },
    2: {
        title: 'iOS iPad',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/245f5b57-42d8-4e36-8ce2-3d176d10ba01.jpeg',
        description: '我们负责ios端ipad的开发和交互体验设计'
    },
    3: {
        title: '安卓平板',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/dc684188-7cf8-4be5-bc1a-44cdac931f59.jpeg',
        description: '运行于所有安卓系统平板的应用，在华为应用商城，小米应用商城等均可下载'
    },
    4: {
        title: '桥牌桌面应用',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2863bb1a-b5a4-48a9-98fe-a9b958a192f7.jpeg',
        description: '基于win系统开发的即时视频游戏应用，并配备了娱乐模式的比赛模式'
    },
    5: {
        title: '管理后台',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ab725c90-748b-450a-a64b-f4c1f90081f9.jpeg',
        description: '配合前端APP使用的软件管理后台也投入使用，查看软件数据，管理用户数据'
    },
    6: {
        title: '智能设备开发',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/96848c53-16a5-4ae6-8fd5-2df9f99b59c6.jpeg',
        description: '我们与沃特德合作开发智能饮水机软件，实现软件管理硬件的智能操纵'
    },
    7: {
        title: '智能设备开发',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/723cb77d-a05e-4abf-9d9f-557a76b984df.jpeg',
        description: '我们与荣事达合作开发智能汽车自动洗车机，实现软件管理硬件的智能操纵'
    },
    8: {
        title: '微信公众号',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/aa31c1b4-04fb-4964-bd21-1cf7a2d86e68.jpeg',
        description: '帮助客户在所拥有的公众号搭建一套软件运行程序'
    },
    9: {
        title: '微信分享活动',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/aa31c1b4-04fb-4964-bd21-1cf7a2d86e68.jpeg',
        description: '基于APP在微信上实现用户注册，分享砍价活动参与等一系列活动'
    },
    10: {
        title: '小程序开发',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c4792af1-d96f-4ec8-8b48-e0ff6d771232.jpeg',
        description: '在微信小程序实现了部分app功能，使用户更便利的使用软件'
    },
    11: {
        title: '产品原型',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/902d7d24-6a90-4993-94c4-c92ec24003e0.jpeg',
        description: '前期投入大量精力策划和探讨数据模型和产品原型，并且使项目达到预期'
    },
    12: {
        title: 'UI设计稿',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d2aec199-f8c4-4aa7-82f1-61b3f3913854.jpeg',
        description: '我们制作了漂亮的UI设计稿和无障碍交互，并且开发到达了100%还原'
    },
    13: {
        title: '服务器运维',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/10bf6725-9236-4f3b-94ee-ac3693a232f1.jpeg',
        description: '服务器运维和软件维护服务，是确保软件安全稳定的正常运行保障'
    },
    14: {
        title: '应用',
        img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/dc1ee4e4-2b3b-43d2-ac12-176e97df1387.jpeg',
        description: '我们提供了项目开发上线所需要的应用图标设计'
    }
}
