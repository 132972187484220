<template>
  <tk-cover center middle :show="show" >
    <tk-spinner color="white" type="oval"></tk-spinner>
  </tk-cover>
</template>
<script>
  export default {
    name: 'waiting',
    props: {
      show: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped lang="scss">

</style>
