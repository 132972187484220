<template>
  <tk-flex center middle column class="download">
    <div class="logo">
      <img :src="project.logo" v-if="project" />
    </div>
    <div class="name">
      &nbsp;{{project && project.name}}&nbsp;
    </div>
    {{message}}
    <tk-cover right top :show.sync="wechatTip" :hideOnClick="false">
      <img class="wechat-tip" src="http://moke-store.oss-cn-beijing.aliyuncs.com/f42b7931-a5d5-4c00-91ba-c87bb5a63d56.png" />
    </tk-cover>
  </tk-flex>
</template>
<script>
  import projects from '../../assets/js/project'
  export default {
    name: 'download',
    data () {
      return {
        message: '准备下载..',
        wechatTip: false
      }
    },
    computed: {
      id () {
        return this.$route.query.id
      },
      project () {
        return this.id ? projects[parseInt(this.id)] : null
      },
      url () {
        if (!this.project) return null
        return this.project.android || this.project.pc
      }
    },
    mounted () {
      if (this.$tkUa.isWechat) {
        this.wechatTip = true
        this.message = '请在浏览器中下载'
      } else if (this.url){
        this.message = '已经开始下载'
        window.location.href = this.url
      }
    }
  }
</script>
<style scoped lang="scss">
.download {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  color: rgba(110, 110, 136, 1);
  font-size: 16px;
  font-weight: bold;
  background-image: linear-gradient(to right top, #9db1f9, #b7b7f9, #ccbef9, #dec5f8, #edcef8);
  padding-bottom: 120px;
}
.logo {
  background-color: white;
  width: 72px;
  height: 72px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  border-radius: 16px;
  overflow: hidden;
  img {
    width: 72px;
    height: 72px;
  }
}
.name {
  text-align: center;
  margin: 10px 0 80px;
  opacity: 0.8;
  font-weight: normal;
}
.wechat-tip {
  width: 240px;
}
</style>
