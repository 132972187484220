export default [
  {
    q: '开发软件需要提供哪些信息？',
    a: '软件的功能概要即可，我们会将其转化为开发计划书，如果有设计好的产品原型或设计稿更佳。'
  },
  {
    q: '什么样的软件都能开发吗？',
    a: '通常的手机与PC软件都可以开发，3d游戏类软件不适用该技术。'
  },
  {
    q: '开发的付款方式是？',
    a: '对于小型项目（开发周期2月及以下）,在合同签订时支付50%，项目交付时支付30%，验收通过后支付20%，对于中大型项目按照开发总时长平均分期支付。'
  },
  {
    q: '开发软件需要多长时间，多少钱？',
    a: '需要根据具体的项目需求判断，请在上方提交您的项目信息，我们的项目经理会进行整理和报价。'
  },
  {
    q: '项目的质保期有多长时间，质保哪些部分？',
    a: '质保期与项目的开发时间相等，最短3个月，最长1年，质保范围为需求定义内的bug或其他恶性问题，超出质保时间后，我们仍提供功能修复服务，但会有一定费用。'
  },
  {
    q: '项目延期怎么办？',
    a: '因我司原因造成的项目延期会根据延期时间在开发时间中的比例减免开发费用，最高为30%。'
  },
  {
    q: '我已经有自己的网站/App了，可以进行改动或升级吗？',
    a: '不可以，因为技术架构与规范完全不同，但可以使用旧的数据库数据重新开发。'
  },
  {
    q: '项目开发好后是否交付源码？',
    a: '交付源码，每个项目版本都可以在我们提供管理后台上下载相应的源码。如有二次开发需要，也可以提供开发文档和设计原稿等。'
  }
]
