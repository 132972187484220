<template>
  <div>
    <topBannerminipro class="top-banner"></topBannerminipro>
    <Pc></Pc>
  </div>
</template>
<script>
import Pc from "../../components/pcMiniprogram";
import topBannerminipro from "../../components/topBannerMinipro";
export default {
  name: "index",
  components: {
    Pc,
    topBannerminipro,
  },
};
</script>
