<template>
<div></div>
</template>
<script>
    export default {
        name: "supplierAllBack",
        data() {
            this.$router.replace({
                path: '/oneProject',
                query: {
                    index: this.$route.query.index
                }
            })
            return {}
        }
    }
</script>

<style scoped>

</style>
