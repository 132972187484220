<template>
  <div class="pc-home">
    <div class="flex flex_center page-item item-2">
      <img v-lazy="item2Img" class="item-2-img fade-in" />
      <div class="item-2-content">
        <lazy-component class="fade-in">
          <div class="item-2-content-first">
            从2017年小程序上线时开始，
            <br />我们便在做小程序了
            <br />没有模板，
            <span v-if="isMobile">
              <br />
            </span>每个项目都是精心的定制设计
          </div>
          <div class="click-button">
            <div class="button" @click="showPreviewf">
              免费评估您的项目
              <img :src="iconImg.right" />
            </div>
          </div>
        </lazy-component>
      </div>
    </div>
    <!-- <div class="flex_column flex_center page-item item-3">
			<div class="item-3-head">目前市面大多小程序都基于模板开发</div>
			<div class="flex flex_center item-3-midhead">
				<div>租用小程序模板</div>
				<div class="item-3-midhead-center">对比</div>
				<div>默客定制开发</div>
			</div>
			<lazy-component class="fade-in">
				<div class="flex flex_center item-3-sheet" v-for="(i, index) in sheetArray" :key="index">
					<div class="item-3-sheet-left">{{i.head}}</div>
					<div class="flex_column flex_center item-3-sheet-center">
						<img :src="i.img" />
						<div class="text-center red-smallfont">{{i.imgScript}}</div>
					</div>
					<div class="item-3-sheet-right">{{i.tail}}</div>
				</div>
			</lazy-component>
    </div>-->

    <!-- <div class="flex wrap flex_center page-item item-4">
			<div class="inline-block item-4-left">
				<lazy-component class="fade-in">
					<div class="item-4-title">请勿租用小程序</div>
					<div class="item-4-script">
						<div class="item-4-script-firstline">租用小程序意味着将你的用户和业务完成绑在</div>
						<div class="item-4-script-firstline">服务商身上生意越好，便越难离开</div>
					</div>
					<div class="click-button">
						<button class="button" @click="showPreviewf">免费评估您的项目
							<img :src="iconImg.right">
						</button>
					</div>
				</lazy-component>
			</div>
			<img class="item-4-img inline-block fade-in" v-lazy="item4Img">
    </div>-->

    <div class="flex_column flex_center page-item item-5">
      <div class="item-5-title text-center">近期案例</div>
      <div class="item-2-content-second text-center">微信扫一扫查看小程序案例</div>
      <div class="flex wrap flex_center item-5-wrap page_attr">
        <div
          v-for="(i,index2) in showProjects1"
          :key="index2"
          class="flex flex_center item-5-onecard"
        >
          <div class="item-5-card">
            <div class="flex_column flex_center item-5-img">
              <img v-lazy="i.src" class="item-5-phone fade-in" />
            </div>
            <div class="item-5-scripttitle">
              <lazy-component class="fade-in">{{i.title}}</lazy-component>
            </div>
            <!-- <div class="item-5-script">
              <lazy-component class="fade-in">{{i.script1}}</lazy-component>
            </div>-->
          </div>
        </div>
      </div>
      <div class="flex wrap flex_center item-5-wrap page_attr">
        <div
          v-for="(i,index2) in showProjects2"
          :key="index2"
          class="flex flex_center item-5-onecard"
        >
          <div class="item-5-card">
            <div class="flex_column flex_center item-5-img">
              <img v-lazy="i.src" class="item-5-phone fade-in" />
            </div>
            <div class="item-5-scripttitle">
              <lazy-component class="fade-in">{{i.title}}</lazy-component>
            </div>
            <!-- <div class="item-5-script">
              <lazy-component class="fade-in">{{i.script1}}</lazy-component>
            </div>-->
          </div>
        </div>
      </div>
      <div class="flex_column flex_center page-item item-8">
        <div class="title">现在立刻来做项目评估</div>
        <div class="script">告诉我们您的需求，我们来给您策划和估价</div>
        <div class="flex wrap flex_center maincards">
          <div class="three-cards" v-for="(item,i1) in steps" :key="i1">
            <div class="serial">{{i1+1}}</div>
            <div class="steps-title">{{item.title}}</div>
            <div class="steps-script">{{item.script}}</div>
          </div>
        </div>
        <div class="click-button">
          <div class="button" @click="showPreviewf">
            免费估算
            <img :src="iconImg.right" />
          </div>
        </div>
      </div>
    </div>
    <estimate-alert></estimate-alert>
  </div>
</template>
<script>
import qa from '../assets/js/qa'
import estimate from './estimate'
import EstimateAlert from './EstimateAlert'
export default {
  name: 'pcMiniprogram',
  components: { EstimateAlert, estimate },
  data () {
    return {
      item2Img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f782ffc9-911c-4530-884f-633d9064cf04.png',
      item4Img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/bbd70613-3fe7-4f5d-b4e2-8849c20a3a85.jpeg',
      // sheetArray: [{
      //     head: '按年付费',
      //     img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/02ee6552-a1dd-4e9a-9f17-29eb43f7aba0.png?x-oss-process=image/resize,w_58,h_58',
      //     imgScript: '费用',
      //     tail: '一次开发没有后续费用'
      // },
      //     {
      //         head: '套用模板',
      //         img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1acb955f-93a7-4eb7-a930-6d919c7f61d3.png?x-oss-process=image/resize,w_58,h_58',
      //         imgScript: '功能',
      //         tail: '按需开发'
      //     },
      //     {
      //         head: '几分钟',
      //         img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/98b0fdc7-c04c-4d3a-b526-4aa742c275da.png?x-oss-process=image/resize,w_58,h_58',
      //         imgScript: '时间',
      //         tail: '通常1-2个月'
      //     },
      //     {
      //         head: '多数无',
      //         img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/99dfe756-8ea1-4f59-999b-4bb7335aaa3b.png?x-oss-process=image/resize,w_58,h_58',
      //         imgScript: '升级',
      //         tail: '按需定制，满足企业发展要求'
      //     },
      //     {
      //         head: '多数无法导出',
      //         img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/97cb7cc1-7184-456c-9537-073799048e25.png?x-oss-process=image/resize,w_58,h_58',
      //         imgScript: '数据',
      //         tail: '完全拥有'
      //     },
      //     {
      //         head: '无',
      //         img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/81fcea8a-7da7-4254-a15e-03882ff837d7.png?x-oss-process=image/resize,w_58,h_58',
      //         imgScript: '源码',
      //         tail: '完整程序源码'
      //     }],
      qa: qa,
      preview: [],
      showProjects1: [{
        src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/daddd0ea-a49a-4342-a3c2-bbc06429419d.png',
        title: '超威云商城',
        // script1: '上线1个月'
      },
      {
        src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/674eef15-afa4-4764-827b-fe1e11f3a8ba.png',
        title: '杜桥派出所',
        // script1: '上线2个月'
      },
      {
        src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d7ef89bb-e167-4109-ba2f-4a412a964cb2.png',
        title: '邢台乡镇纪委一键通',
        // script1: '上线2个月'
      },
      {
        src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b3660369-d271-44c1-931d-738d7464af36.png',
        title: '农业嘉年华智慧旅游',
        // script1: '上线2个月',
      }],
      showProjects2:
        [{
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c357cecf-ded0-4ef0-8acc-80fe24bd72eb.png',
          title: '有光万物',
          // script1: '上线1个月'
        },
        {
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e2f863fa-89c8-4edb-8cb6-2710b9e634a0.png',
          title: '村务清',
          // script1: '上线1个月',
        },
        {
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a68a09f0-5552-4e05-b277-326c9fe5cde3.png',
          title: '沃特德水机',
          // script1: '上线2个月'
        },
        {
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ae5dd909-1583-487d-8857-a6cb99abab0f.png',
          title: '观本淘装修',
          // script1: '上线2个月'
        }]
    }
  },
  computed: {
    day () {
      let d = new Date()
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
    },
    isMobile: function () {
      return window.innerWidth < 601
    },
  },
}
</script>
<style scoped lang="scss">
.itme-body {
  width: 1160px;
}

.item-title {
  font-size: 40px;
  font-weight: bold;
  color: rgba(45, 45, 73, 1);
}

.item-text {
  font-size: 20px;
  color: rgba(110, 110, 136, 1);
  line-height: 1.5;
  width: 800px;
}

.button {
  cursor: pointer;
  width: 270px;
  height: 56px;
  background-color: rgba(244, 74, 79, 1);
  border-radius: 28px;
  text-align: center;
  line-height: 56px;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
}

.item-2 {
  height: auto;
  padding: 50px 0;
  .item-2-img {
    max-width: 623px;
    width: 52%;
    height: auto;
    margin-right: 58px;
  }
  .item-2-content {
    width: 48%;
    max-width: 596px;
    .item-2-content-first {
      font-weight: bold;
      line-height: 1;
      font-size: 32px;
      line-height: 50px;
      color: rgba(45, 45, 73, 1);
      margin-bottom: 50px;
    }
    .item-2-content-second {
      margin: 30px auto 55px;
      line-height: 1;
      font-size: 20px;
      color: rgba(110, 110, 136, 1);
    }
    .item-2-content-button {
      margin-top: 74px;
      width: 270px;
      height: 50px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 4px;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
    }
  }
}
.item-3 {
  padding: 1px;
  background: rgba(255, 255, 255, 1)
    url('http://moke-store.oss-cn-beijing.aliyuncs.com/2185e61f-ef5f-4a6a-a1e4-698209dc7909.jpeg')
    center center no-repeat;
  background-size: cover;
  height: 1406px;
  .item-3-head {
    text-align: center;
    line-height: 1;
    margin-top: 0;
    font-weight: bold;
    font-size: 32px;
    color: rgba(244, 74, 79, 1);
  }

  .item-3-midhead {
    margin: 80px auto 33px;
    line-height: 1;
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
    .item-3-midhead-center {
      margin: 0 180px;
      color: rgba(244, 74, 79, 1);
    }
  }
  .item-3-sheet {
    width: 1260px;
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
    height: 130px;
    background-color: rgba(30, 36, 72, 0.8);
    border-radius: 10px;
    margin-bottom: 19px;
  }

  .item-3-sheet-center {
    margin: auto 184px;
    display: inline-block;
    width: 58px;
    img {
      width: 58px;
      height: 58px;
    }
    .red-smallfont {
      margin-top: 10px;
      font-size: 20px;
      color: rgba(244, 74, 79, 1);
    }
  }

  .item-3-sheet-left {
    font-size: 20px;
    margin-left: 25px;
    text-align: right;
    display: inline-block;
    width: 417px;
  }

  .item-3-sheet-right {
    font-size: 20px;
    display: inline-block;
    width: 417px;
  }
}

.item-4 {
  margin: 143px auto 104px;
  height: auto;
  .item-4-left {
    max-width: 480px;
    width: 45%;
    height: 499px;
  }
  .item-4-img {
    margin-left: 72px;
    max-width: 708px;
    min-width: 600px;
    width: 55%;
    height: 520px;
  }

  .item-4-title {
    margin-top: 111px;
    font-size: 32px;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
    margin-bottom: 34px;
  }

  .item-4-script {
    font-size: 20px;
    color: rgba(110, 110, 136, 1);
    margin-bottom: 40px;
    .item-4-script-firstline {
      white-space: nowrap;
    }
  }
  .item-4-button {
    width: 270px;
    height: 50px;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 4px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
  }
}

.item-5 {
  width: 100%;
  height: auto;
  padding: 22px 0 100px;
  background-color: #fafafa;

  .item-5-title {
    padding: 104px 104px 12px 104px;
    font-weight: bold;
    font-size: 32px;
    color: rgba(45, 45, 73, 1);
  }

  .item-5-card {
    margin: 50px 10px 0;
    background-color: white;
    height: 256px;
    width: 276px;
    .item-5-script {
      margin: 10px auto 0px;
      margin-left: 10px;
      font-size: 14px;
      color: rgba(110, 110, 136, 1);
    }
    .item-5-scripttitle {
      margin-top: 20px;
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #2d2d49;
    }
  }
  .item-5-img {
    width: 100%;
    height: 196px;
    background-color: #f5f5f5;
  }
  .item-5-phone {
    width: 156px;
    height: 156px;
  }
}

.item-5-wrap {
  margin-top: 51px;
  max-width: 1280px;
}

.item-8 {
  background-color: #fff;
  margin-top: 50px;
  .title {
    font-weight: bold;
    margin: 5rem auto 1.2rem;
    line-height: 32px;
    font-size: 2rem;
    color: rgba(45, 45, 73, 1);
  }
  .script {
    line-height: 1;
    font-size: 1.3rem;
    color: rgba(110, 110, 136, 1);
  }
  .three-cards {
    margin: 73px 69px 33px;
    width: 320px;
    height: 167px;
    @media screen and (max-width: 1374px) and (min-width: 1028px) {
      width: 20vw;
    }
    @media screen and (max-width: 980px) {
      width: 280px;
    }
    .serial {
      box-shadow: -4px -4px 4px rgba(244, 74, 79, 0.4);
      text-align: center;
      font-size: 20px;
      color: rgba(255, 255, 255, 1);
      line-height: 36px;
      width: 36px;
      height: 36px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 50%;
      margin-bottom: 20px;
    }
    .steps-title {
      line-height: 1.2;
      font-size: 20px;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
      margin-bottom: 14px;
    }
    .steps-script {
      line-height: 30px;
      font-size: 18px;
      color: rgba(124, 124, 153, 1);
    }
  }
  .button {
    cursor: pointer;
    margin-bottom: 30px;
    text-align: center;
    line-height: 56px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    width: 270px;
    height: 56px;
    border: 1.5px solid #ffffff;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 28px;
  }
  .subscript {
    cursor: pointer;
    margin-bottom: 79px;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 400;
    color: rgba(124, 124, 153, 1);
  }
}
@media screen and (max-width: 600px) {
  .item-2 {
    flex-wrap: wrap-reverse;
    padding: 30px 0;
    .item-2-img {
      width: 245px;
      height: 242px;
      margin: 10px 0 10px 0;
    }
    .item-2-content {
      width: auto;
      text-align: center;
      .item-2-content-first {
        margin-top: 0px;
        font-size: 20px;
        line-height: 35px;
        margin-bottom: 25px;
      }
      .item-2-content-second {
        font-size: 12px;
        margin: 14px auto 12px;
      }
    }
    .button {
      display: none;
    }
  }
  .item-3 {
    height: 680px;
    padding-bottom: 30px;
    .item-3-head {
      margin-top: 40px;
      margin-bottom: 10px;
      font-size: 22px;
    }
    .item-3-midhead {
      margin-top: 0px;
      font-size: 16px;
      width: auto;
      min-width: 0px;
      .item-3-midhead-center {
        margin: 0 25px;
        height: 32px;
        font-size: 16px;
        line-height: 2;
      }
    }
    .item-3-sheet {
      width: 345px;
      height: 65px;
    }
    .item-3-sheet-left {
      font-size: 12px;
      margin-left: 0;
      width: 120px;
    }
    .item-3-sheet-center {
      margin: auto 20px;
      width: 27px;
      img {
        width: 27px;
        height: 27px;
      }
      .red-smallfont {
        margin-top: 0px;
        font-size: 10px;
      }
    }
    .item-3-sheet-right {
      font-size: 12px;
      width: 100px;
    }
  }
  .item-4 {
    margin: 0px auto 40px;
    .item-4-left {
      width: 100%;
      margin-top: 22px;
      text-align: center;
      height: auto;
    }
    .item-4-title {
      font-size: 22px;
      line-height: 1;
      margin: 15px auto 15px;
    }
    .item-4-img {
      width: 332px;
      height: 234px;
      min-width: 0px;
      margin: 0;
    }
    .item-4-script-firstline {
      font-size: 12px;
    }
    .button {
      display: none;
    }
  }
  .item-5-wrap {
    margin-top: 30px;
  }
  .item-5 {
    padding: 35px 0 35px;
    .item-5-title {
      padding: 0px 0px;
      font-size: 22px;
      margin-bottom: 5px;
    }
    .item-5-card {
      margin-top: 25px;
      width: 160px;
      height: 167px;
      margin-right: 5px;
      .item-5-img {
        width: 100%;
        height: 113px;
        background-color: #f5f5f5;
      }
      img {
        margin-top: 0px;
        width: 78px;
        height: 78px;
      }
      .item-5-scripttitle {
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
      }
      .item-5-script {
        font-size: 10px;
        margin-top: 5px;
      }
    }
  }
  .item-8 {
    .title {
    }
    .script {
      font-size: 12px;
    }
    .three-cards {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      height: 106px;
      .serial {
        font-size: 16px;
        line-height: 19px;
        width: 18px;
        height: 18px;
        margin: 24px auto 10px;
      }
      .steps-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .steps-script {
        line-height: 1;
        font-size: 12px;
      }
    }
    .button {
      text-align: center;
      width: 200px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      border-radius: 20px;
      color: white;
      border: 1.5px solid #ffffff;
      margin-bottom: 20px;
    }
    .subscript {
      font-size: 11px;
      margin-bottom: 35px;
    }
  }
}
</style>
