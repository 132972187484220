<template>
  <div class="flex_column flex_center page-item item-estimate">
    <div class="item-title text-center">让我们谈谈您的应用</div>
    <div class="flex flex_center wrap">
      <div>
        <img class="img" :src="talkGirlimg" />
      </div>
      <div class="triangle"></div>
      <div class="answer">Hi 您好，您有任何问题，欢迎随时与我们联系</div>
    </div>
    <div class="click-button">
      <div class="item-button text-center" @click="showPreviewf">
        免费估算
        <!-- 免费评估您的项目 -->
        <img :src="iconImg.right" />
      </div>
    </div>
    <div class="flex" style="width: 100%">
      <a v-if="nowRoute.path !== '/about/' && nowRoute.path !== '/serve/'">
        <router-link to="/about/">查看我们公司的介绍</router-link>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "estimate",
  data () {
    return {
      nowRoute: this.$router.currentRoute,
      downloadUrl: ''
    }
  },
}
</script>

<style scoped lang="scss">
    .page-item {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        margin: 0 auto;
    }
    .item-estimate {
        width: 100%;
        height: 537px;
        .item-title {
            /*margin-top: 0;*/
            margin-bottom: 65px;
            line-height: 28px;
            font-size: 28px;
            font-weight: normal;
        }

        .item-button {
            cursor: pointer;
            text-align: center;
            margin-top: 71px;
            width: 180px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(244, 74, 79, 1);
            border-radius: 25px;
            font-size: 18px;
            color: rgba(255, 255, 255, 1);
        }

        .answer {
            padding: 34px 24px 34px 34px;
            width: 321px;
            height: 108px;
            background-color: rgba(250, 250, 250, 1);
            border-radius: 10px;
            line-height: 23px;
            font-size: 16px;
            color: rgba(136, 136, 136, 1);
        }

        .img {
            width: 98px;
            height: 98px;
            border-radius: 50%;
        }

        .triangle {
            width: 0;
            height: 0;
            border: 17px solid transparent;
            border-right: 19px solid rgba(250, 250, 250, 1);
        }
        a { display: none}
    }
    @media screen and (max-width: 600px) {
        .item-estimate {
            height: auto;
            .triangle {
                display: none;
            }
            .img {
                width: 52px;
                height: 52px;
            }
            .item-title {
                margin: 40px 0;
                font-size: 16px; font-weight: bold;
                line-height: 1;
            }
            .answer {
                margin-top: 10px;
                padding: 20px 24px 20px 34px;height: 60px;
                width: 340px;
                text-align: center;
                font-size: 12px;
            }
            .item-button {
                width: 180px;
                height: 40px;
                font-size: 16px;
                line-height: 2.6;
                margin-top: 40px;
                margin-bottom: 30px;
            }
            a {
                text-align: center;
                display: inline; font-size: 11px;
                width: 100%;
                height: auto;
                line-height: 1.5;
                color: #c3c3c3;
                text-decoration: underline;
                margin-bottom: 35px;
            }
        }

    }
</style>
