<template>
	<div class="flex_column flex_center justify-start top-page" v-if="isMobile">
		<div class="title-2">在找小程序开发公司？</div>
		<div class="title-3">不如找一个<span style="color:#F4365A ">长期合作伙伴<br/></span>来承担您遇到所有的技术问题</div>
		<div style="width: 100%" class="click-button"><div class="button" @click="showPreviewf" v-fb>估算项目<img :src="iconImg.right">
		</div></div>
	</div>
	<div class="flex_column flex_center top-page" v-else>
		<div class="title-2">
			<lazy-component class="fade-in">在找小程序开发公司？</lazy-component>
		</div>
		<div class="title-3">
		<lazy-component class="fade-in">
			<div>不如找一个<span style="color:#F4365A ">长期合作伙伴</span><br/>来承担您遇到所有的技术问题
			</div>
		</lazy-component>
		</div>
		<div style="width: 100%" class="click-button">
			<lazy-component class="fade-in">
				<div class="button" @click="showPreviewf" v-fb>估算项目
					<img :src="iconImg.right">
				</div>
			</lazy-component>
		</div>
	</div>
</template>
<script>
    export default {
        name: 'topBannerMinipro',
        computed: {
            isMobile: function () {
                return window.innerWidth < 601;
            },
        }
		}
</script>
<style scoped lang="scss">
	.top-page {
		@media screen and (max-width: 600px) {
			width: 100%;
			margin-top: 44px;
			height: 445px;
		}
		@media (min-width: 601px) {
			width: 100%;
			margin-top: 70px;
			height: 614px;
		}
		background: rgba(255, 255, 255, 1) url('http://moke-store.oss-cn-beijing.aliyuncs.com/5a076853-e028-4204-a7f8-897a1a3f4cba.png') center center no-repeat;
		background-size: cover;
		letter-spacing: 5px;
		.title-1{
			font-size: 20px;
			color: #ffffff;
		}
		.title-2 {
			margin-top: 322px;
			margin-bottom: 30px;
			line-height: 40px;
			color: white;
			font-size: 40px;
			color: rgba(255, 255, 255, 1);
		}
		.title-3 {
			line-height: 75px;
			text-align: center;
			font-weight: bold;
			font-size: 50px;
			color: rgba(255, 255, 255, 1);
			margin-left: 12px;
		}
		.button {
			margin: 60px auto 320px;
			text-align: center;
			letter-spacing: 0;
			line-height: 56px;
			width: 270px;
			height: 56px;
			background-color: rgba(244, 74, 79, 1);
			border-radius: 28px;
			font-size: 20px;
			color: rgba(255, 255, 255, 1);
			cursor: pointer;
		}
		@media screen and (max-width: 600px) {
			// transform: translate(0, -15px);
			letter-spacing: 0;
			background-image: url('http://moke-store.oss-cn-beijing.aliyuncs.com/1ad2db44-ac90-4e4e-98e8-90f969efa48a.png');
			// background-size: cover;
			height: 445px;
			// align-items: start;
			.title-2 {
				margin-top: 10px;
				margin-bottom: 21px;
				text-align: center;
				line-height: 16px;
				font-size: 16px;
				color: rgba(255, 255, 255, 1);
			}
			.title-3 {
				margin: 0;
				padding: 0 30px;
				line-height: 38px;
				font-size: 25px;
				color: rgba(255, 255, 255, 1);
			}
			.button {
				margin: auto;
				margin-top: 27px;
				line-height: 40px;
				font-size: 16px;
				color: rgba(255, 255, 255, 1);
				width: 200px;
				height: 40px;
				background-color: rgba(244, 74, 79, 1);
				border-radius: 20px;
			}
		}
	}
</style>
