<template>
  <PcAbout ></PcAbout>
</template>
<script>
  import PcAbout from '../../components/pcAbout'
  export default {
    name: 'about',
    computed: {
    },
    components: {
      PcAbout
    }
  }
</script>
