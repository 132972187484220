<template>
  <div>
    <top-banner></top-banner>
    <Pc></Pc>
  </div>
</template>
<script>
  import Pc from '../../components/pcHome'
  import TopBanner from '../../components/topBanner'
  export default {
    name: 'index',
    components: {
      Pc,
      TopBanner
    }
  }
</script>
