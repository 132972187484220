<template>
  <div>
    <div class="flex_column flex_center item-1">
      <lazy-component class="fade-in">
        <div class="title-2">您是否正在寻找</div>
      </lazy-component>
      <lazy-component class="fade-in">
        <div class="title-3">
          可以设计、开发和维护您的移动或网络
          <br />应用程序的
          <span style="color:#F4365A ">长期合作伙伴</span>
        </div>
      </lazy-component>
      <lazy-component class="fade-in">
        <div class="click-button">
          <div class="button" @click="scrollBanner">
            <img :src="iconImg.under" class="imgicon-18" />
          </div>
        </div>
      </lazy-component>
    </div>
    <div class="flex_column flex_center page-item item-2">
      <div class="title">为您的业务量身定制开发方案</div>
      <div class="script">选择最适合您需求的产品模式</div>
      <div class="flex flex_center wrap page_attr">
        <div class="card" v-for="(item,index) in solveWay" :key="index">
          <div class="flex flex_center img">
            <img class="fade-in" v-lazy="item.back" />
          </div>
          <lazy-component class="fade-in">
            <div class="card-title">{{item.title}}</div>
            <div class="card-script">{{item.script}}</div>
          </lazy-component>
        </div>
      </div>
      <div class="click-button">
        <div class="button" @click="showPreviewf">
          免费评估您的项目
          <img :src="iconImg.right" />
        </div>
      </div>
    </div>

    <div class="gray-line"></div>
    <div class="flex wrap flex_center page-item item-5">
      <div class="half-img">
        <img class="fade-in half-img" v-lazy="provideHalfimg" />
      </div>
      <div class="right-half">
        <div class="small-title">我们的服务</div>
        <div class="small-redline"></div>
        <div class="main-content">
          我们将您的创意变成用户会喜欢的
          <br />成熟产品
        </div>
        <div class="flex_row content" v-for="(item,index) in fourOfferItems" :key="index">
          <div class="small-icon">
            <img :src="item.icon" />
          </div>
          <div class="content">
            <div class="title">{{item.title}}</div>
            <div class="script">{{item.script}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="gray-line"></div>
    <!--<div class="flex flex_center wrap page-item item-3">-->
    <!--<div class="half-img">-->
    <!--<img class="fade-in" v-lazy=promiseHalfimg>-->
    <!--</div>-->
    <!--<div class="right-half">-->
    <!--<div class="small-title">我们的承诺</div>-->
    <!--<div class="small-redline"></div>-->
    <!--<div class="main-content">100％基于办公室的公司，拥有专门的项目<br/>团队-承诺按期交付无崩溃应用</div>-->
    <!--<div class="flex content" v-for="(item,index) in fourPromiseItems" :key="index">-->
    <!--<div class="small-icon"><img :src="item.icon"></div>-->
    <!--<div class="content">-->
    <!--<div class="title">{{item.title}}</div>-->
    <!--<div class="script">{{item.script}}</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="flex_column flex_center page-item item-4">
      <div class="small-title">我们的工作方式</div>
      <div class="small-redline"></div>
      <div class="main-content">
        通过透明的流程来确保按您的愿景
        <br />交付预期应用
      </div>
      <div class="pc">
        <div class="flex_row four-icon">
          <div v-for="(item,index1) in fourRoutesIcon" :key="index1" class="card">
            <img :src="item" />
          </div>
        </div>
        <div class="flex redline">
          <div class="flex_row" v-for="(item,index2) in 4" :key="index2">
            <div class="small-point"></div>
            <div class="thin-redline" v-if="index2!==3"></div>
          </div>
        </div>
        <div class="flex_row four-cards">
          <div
            v-for="(item,index3) in fourRoutes"
            :key="index3"
            class="flex_column flex_center cards"
          >
            <div class="small-icon">
              <img class="small-icon" :src="item.icon" />
            </div>
            <div class="title">{{item.title}}</div>
            <div class="script">{{item.script}}</div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div
          v-for="(item,index) in mobilefourRoutes"
          :key="index"
          class="flex_column flex_center cards"
        >
          <div class="small-icon">
            <img class="small-icon" :src="item.icon" />
          </div>
          <div class="title">{{item.title}}</div>
          <div class="script">{{item.script}}</div>
        </div>
      </div>
    </div>

    <div class="gray-line"></div>

    <estimate></estimate>

    <estimate-alert></estimate-alert>
  </div>
</template>

<script>
import estimate from './estimate'
import EstimateAlert from './EstimateAlert'
export default {
  components: {
    EstimateAlert,
    estimate
    // TkFlex
  },
  name: "pcServe",
  data () {
    return {
      fourPromiseItems: [{
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8a53d53b-b6d9-4584-bb79-7136cbe192a8.jpeg',
        title: '100％基于办公室',
        script: '享受只有办公室团队才能提供的效率和透明度'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8c1a240f-df79-4101-a3ca-5f265ad77c7d.jpeg',
        title: '专业团队沟通',
        script: '为您的项目创建专属的开发沟通团队'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9ebc4ff2-7823-4572-a04f-de2e66771781.jpeg',
        title: '合理、规范的预估报价',
        script: '我们专业的估算团队为您提供合理规范的报价。'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c6fc264b-8ed1-495d-8f34-f4be7c95de87.jpeg',
        title: '按期交付无崩溃应用',
        script: '我们承诺按合同交付无崩溃的应用。'
      }],

      fourOfferItems: [{
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/43d5b03e-3254-414c-9975-a6da3a2bc931.jpeg',
        title: '漂亮的UI设计',
        script: '我们将完美无瑕的设计组合在一起，打造直观易用的UX / UI'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6c69b92d-3bdf-4261-a85e-081698422f29.jpeg',
        title: '规范的开发流程',
        script: '通过规范的开发流程，您将获得一个无崩溃的应用程序'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e9630bac-1100-4764-be6b-5931fefadf4b.jpeg',
        title: '免费的服务器',
        script: '我们提供2年的免费的后端服务器'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/490dba1e-f614-43fe-a293-c0540365e66f.jpeg',
        title: '帮您节约时间成本',
        script: '不要花时间组建技术团队，立刻就能开发您的产品'
      }],
      fourRoutesIcon: ['http://moke-store.oss-cn-beijing.aliyuncs.com/e9f9d520-54e4-4e54-9308-cbf41bdb1fc8.png',
        'http://moke-store.oss-cn-beijing.aliyuncs.com/ff97975b-509b-4440-a820-364c8f0883ee.png',
        'http://moke-store.oss-cn-beijing.aliyuncs.com/7391d4b4-11c0-4c44-81ba-ad6671263d3e.png',
        'http://moke-store.oss-cn-beijing.aliyuncs.com/51dfc3b9-9936-490b-851e-d3574516361e.png'],
      fourRoutes: [{ title: '准时启动项目', script: '我们会在签订合同时准时启动项目开发' },
      { title: '完全透明开发', script: '通过直接与专门团队联系可以随时了解项目状态' },
      { title: '知识产权转让', script: '您一直是所在项目知识产权的所有者' },
      { title: '高效后期服务', script: '我们在项目交付后会一直为您的保障高效运行' }],
      item2cards: ['background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")',
        'background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")',
        'background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")',
        'background:url("http://moke-store.oss-cn-beijing.aliyuncs.com/35825c3d-07ed-4186-9341-f4cf48e90347.png")'],
      mobilefourRoutes: [{
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e9f9d520-54e4-4e54-9308-cbf41bdb1fc8.png',
        title: '准时启动项目',
        script: '我们会在签订合同时准时启动项目开发'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ff97975b-509b-4440-a820-364c8f0883ee.png',
        title: '完全透明开发',
        script: '通过直接与专门团队联系可以随时了解项目状态'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7391d4b4-11c0-4c44-81ba-ad6671263d3e.png',
        title: '知识产权转让',
        script: '您一直是所在项目知识产权的所有者'
      },
      {
        icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/51dfc3b9-9936-490b-851e-d3574516361e.png',
        title: '高效后期服务',
        script: '我们在项目交付后会一直为您的保障高效运行'
      }],
      promiseHalfimg: "http://moke-store.oss-cn-beijing.aliyuncs.com/41795c22-059e-49fc-81f5-432844229414.jpeg",
      provideHalfimg: "http://moke-store.oss-cn-beijing.aliyuncs.com/da15e162-1e13-416f-986b-520547293cfc.jpeg?x-oss-process=image/resize,w_578"
    }
  },
}
</script>

<style scoped lang="scss">
	.mobile { margin-top: 0px; }
	.item-1 {
		background: #130E2D url('http://moke-store.oss-cn-beijing.aliyuncs.com/12bb2fd2-346b-4faa-ad76-da91fe2913ab.jpeg') center center no-repeat;
		background-size: cover;
		margin-top: 46px;
		width: 100%;
		height: 860px;
		color: rgba(255, 255, 255, 1);
		.title-2 {
			margin: 253px auto 42px;
			line-height: 1;
			font-size: 40px;
		}
		.title-3 {
			line-height: 75px;
			text-align: center;
			font-weight: bold;
			font-size: 50px;
			margin-left: 12px;
		}
		.button {
			cursor: pointer;
			text-align: center;
			color: white;
			margin: 76px auto 278px;
			line-height: 46px;
			font-size: 23px;
			width: 46px;
			height: 46px !important;
			background-color: rgba(244, 74, 79, 1);
			border-radius: 50%;
			.active {
				background-color: rgba(244, 74, 79, 1);
			}
			:focus {
				background-color: rgba(244, 74, 79, 1);
			}
		}
	}
	.item-2 {
		.title {
			margin-top: 90px;
			line-height: 1;
			font-size: 2rem;
			font-weight: bold;
			color: rgba(45, 45, 73, 1);
		}
		.script {
			margin: 1.2rem 0 62px;
			line-height: 1;
			font-size: 1.3rem;
			color: rgba(124, 124, 153, 1);
		}
		.card {
			margin: 5px 16px;
			width: 256px;
			height: 441px;
			background-color: rgba(250, 250, 250, 1);
			border-radius: 10px;

			.img {
				margin-top: 19px;
				width: 100%;
				height: 201px;
				background-color: rgba(250, 250, 250, 1);
			}

			.card-title {
				line-height: 20px;
				font-size: 20px;
				font-weight: bold;
				color: rgba(45, 45, 73, 1);
				margin: 42px 0 0 30px;
			}

			.card-script {
				text-align: justify;
				text-justify: distribute-all-lines;
				margin: 31px 25px 0 30px;
				line-height: 30px;
				font-size: 18px;
				color: rgba(124, 124, 153, 1);
			}
		}

		.button {
			cursor: pointer;
			margin: 80px auto;
			text-align: center;
			line-height: 56px;
			font-size: 20px;
			color: rgba(255, 255, 255, 1);
			width: 270px;
			height: 56px;
			background-color: rgba(244, 74, 79, 1);
			border-radius: 28px;
		}
	}
	.item-4 {
		height: 598px;
		background: rgba(250, 250, 250, 1) url('http://moke-store.oss-cn-beijing.aliyuncs.com/388bb923-3874-4a55-8c7a-d4d3144ebb70.png') 100% 25%;
		text-align: center;
		width: 100%;
		padding-bottom: 55px;
		.four-icon {
			margin-top: 66px;
			.card {
				margin: auto 120px;
			}
		}
		.small-title {
			margin: 81px auto 29px;
			line-height: 24px;
			font-size: 24px;
			color: rgba(124, 124, 153, 1);
		}

		.small-redline {
			width: 60px;
			height: 4px;
			background-color: rgba(244, 74, 79, 1);
		}

		.main-content {
			margin-top: 21px;
			line-height: 42px;
			font-weight: bold;
			font-size: 28px;
			color: rgba(45, 45, 73, 1);
		}

		.redline {
			padding-left: 140px;
			margin: 30px 0 29px 4px;
			.thin-redline {
				margin-top: 8px;
				width: 287px;
				height: 2px;
				background-color: rgba(251, 183, 185, 1);
			}

			.small-point {
				width: 18px;
				height: 18px;
				background-color: rgba(244, 74, 79, 1);
				border-radius: 50%;
			}
		}

		.four-cards {
			margin-left: 4px;
			.cards {
				width: 176px;
				margin-right: 130px;
				transform: translate(65px,0);
			}

			.title {
				line-height: 20px;
				font-size: 20px;
				font-weight: bold;
				color: rgba(45, 45, 73, 1);
			}

			.script {
				width: 176px;
				margin-top: 21px;
				line-height: 23px;
				font-size: 16px;
				color: rgba(124, 124, 153, 1);
			}
		}

	}
	.item-5 {
		margin-top: 48px;
		max-width: 1360px;
		padding: 32px;
		.title {
			font-size: 32px;
			line-height: 32px;
			color: rgba(45, 45, 73, 1);
			margin-bottom: 21px;
		}
		.script {
			font-size: 20px;
			line-height: 20px;
			color: rgba(124, 124, 153, 1);
			margin-bottom: 56px;
		}
		.project-card {
			margin-top: 30px;
			width: 1160px;
			height: 680px;
			background-color: rgba(250, 250, 250, 1);
			border-radius: 10px;
		}
		.half-img {
			margin-left: -30px;
			margin-top: 49px;
			min-width: 578px;
			width: 50%;
			height: auto;
		}
		.right-half {
			margin-top: 23px;
			width: 50%;
			min-width: 460px;
			.title {
				margin: 0 0 10px 15px;
				font-weight: bold;
				line-height: 20px;
				font-size: 20px;
				color: rgba(45, 45, 73, 1);
			}

			.script {
				margin: 0 0 38px 15px;
				line-height: 20px;
				font-size: 20px;
				color: rgba(124, 124, 153, 1);
			}

			.small-icon {
				width: 29px;
				height: 29px;
			}
		}

		.small-title {
			line-height: 24px;
			font-size: 24px;
			color: rgba(124, 124, 153, 1);
			margin-bottom: 30px;
		}

		.small-redline {
			width: 60px;
			height: 4px;
			background-color: rgba(244, 74, 79, 1);
			margin-bottom: 21px;
		}

		.main-content {
			line-height: 42px;
			font-weight: bold;
			font-size: 28px;
			color: rgba(45, 45, 73, 1);
			margin-bottom: 55px;
		}
	}
	@media screen and (max-width: 600px){
		.item-1 {
			background: #130E2D url(http://moke-store.oss-cn-beijing.aliyuncs.com/8c9276b1-2666-4d4d-b545-e87c4224f16f.jpeg) center center no-repeat;
			width: 100%;
			color: white;
			height: 600px;
			.button {
				background-color: #f44a4f;
				&:hover {
					opacity: 1;
				}
				img {
					opacity: 1;
				}
				&:active {
					opacity: 0.5;
				}
			}

			.title-2 {
				margin: 183px auto 22px;
				font-size: 16px;
			}
			.title-3 {
				padding: 0 40px;
				line-height: 38px;
				font-size: 25px;
			}
		}
		.item-2 {
			.title {
				margin-top: 35px;
			}
			.card {
				width: 160px;
				margin: 5px 6px;
				height: 190px;
				border-radius: 5px;
				img {
					width: auto;
					height: 94px;
				}
				.img {
					margin-top: 0px;
					width: auto;
					height: 94px;
				}
				.card-title {
					line-height: 2;
					font-size: 12px;
					font-weight: bold;
					color: rgba(45, 45, 73, 1);
					margin: 8px 0 0 15px;
				}
				.card-script {
					margin: 5px 15px 15px;
					line-height: 1.2;
					font-size: 10px;
				}
			}
			.button {
				width: 180px;
				height: 40px;
				font-size: 16px;
				line-height: 2.5;
				margin: 30px auto;
			}
		}
		.item-4 {
			width: 100%;
			height: auto;
			padding: 30px 0;
			.small-title {
				margin-top: 0px;
				font-size: 12px;
				margin-bottom: 10px;
			}
			.small-redline{
				width: 30px;
				height: 2px;
				margin: 0px auto;
			}
			.main-content {
				text-align: center;
				line-height: 21px;
				font-weight: bold;
				font-size: 14px;
				color: rgba(45, 45, 73, 1);
				margin-bottom: 17px;
			}
			.cards {
				.small-icon {
					width: 29px;
					height: 29px;
				}
				.title {
					margin: 14px auto 10px;
					font-size: 12px;
					font-weight: bold;
					line-height: 12px;
					color: rgba(45, 45, 73, 1);
				}
				.script {
					font-size: 12px;
					line-height: 12px;
					color: rgba(124, 124, 153, 1);
					margin-bottom: 21px;
				}
			}
		}
		.item-5 {
			padding: 0 0 20px;
			.half-img{
				width: 100%;
				max-width: 430px;
				height: auto;
				margin: 0;
				min-width: 0px;
			}
			.right-half {
				text-align: center;
				.small-title {
					font-size: 12px;
					margin-bottom: 12px;
				}
				.small-redline{
					width: 30px;
					height: 2px;
					margin: 0 auto 20px;
				}
				.main-content {
					font-size: 14px;
					line-height: 1.5;
				}
				.content {
					text-align: center;
					width: 100%;
					flex-direction: column;
					.small-icon{
						margin: auto;
					}
					.title {
						margin: 14px 0 0 0;
						font-size: 12px;
					}
					.script {
						width: 100%;
						height: auto;
						text-align: center;
						line-height: 1;
						font-size: 12px;
						margin-bottom: 24px;
						margin-left: 0;

					}
				}
			}
		}
	}
</style>
