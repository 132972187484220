 export default [{
  "name": "简果",
  "des": "用于水果的的采购，仓储，销售管理开发的App，包含仓储管理，物料管理，记账，工人考勤，利润统计，市场销售等功能模块，含近500个页面，近200个数据表。",
  "expense": "30万元",
  "cycle": "5个月",
  "logo": "http://moke-store.oss-cn-beijing.aliyuncs.com/f2f77559-ad9d-4bb3-bd72-fa1ab8e313c1.png",
  "android": "https://project-apk.oss-cn-beijing.aliyuncs.com/%E7%AE%80%E6%9E%9C.apk",
  "ios": "",
  "pc": "",
   "preview":[
     'http://moke-store.oss-cn-beijing.aliyuncs.com/a558c3d5-cd0e-47cc-92bd-d259a3e01566.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/2cc420ce-fe43-406f-8ed2-e8de9ec48c6e.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/98ef8c9a-bf01-4554-9c03-c92b7fd6f8fa.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/45f36fe9-97db-49d4-8b10-533776f8317d.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/f1702a44-3ada-4bf5-b279-f8cd067f0986.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/a5f783e6-6073-47f3-b6b2-47bfe8a44184.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/fbbfbd90-daad-4e12-82b5-8e2450d55096.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/5c09ce14-2c5d-4b53-aa00-7461e1bccca1.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/d87f23de-7d4d-448e-be83-a9eb99b3d007.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/58d69b0d-a3a6-4ffa-b1f3-ecaac9086abb.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/2aedbeca-f6f9-432a-9f1e-03d8061cea81.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/dfcbd3f3-2cef-4134-9c35-3c7fb7fd70f3.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/7d1313fa-584c-4d9a-8c45-51f0d8d3c1aa.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/aa51adbc-1b93-4f13-b94d-43077f1db836.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/db1d2f76-08b5-4f0b-b505-ef8f47f30321.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/a73b8b7b-0647-4fb0-ad42-7eae252ce1a0.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/b94c5617-05c3-4786-96d0-f6854179fad8.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/374a3067-bb14-4d3e-a232-3777912a5b32.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/c12f9835-a6f0-42a2-a3a3-dd66bf8a630c.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/bde71f7e-599e-4a5c-9279-175e691bfc07.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/6a2fea4b-5b0d-4d5d-bb89-1c4c9c1dcb41.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/20d71f10-342c-43af-91d0-2004345caaf5.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/2d271118-6700-487a-9bbd-99aaae5b5602.png'
   ]
}, {
   "name": "康哒",
   "des": "用于O2O的商品，药品，医疗服务的采购与配送的App，包含附近诊所，商家管理，商品与医疗服务购买，自营商品，接入达达配送服务，自动安排配送，自动商家分成等功能模块。",
   "expense": "8万元",
   "cycle": "2个月",
   "logo": "http://moke-store.oss-cn-beijing.aliyuncs.com/18d59500-943e-4949-8843-54337fb2f9ea.png",
   "android": "https://project-apk.oss-cn-beijing.aliyuncs.com/%E5%BA%B7%E5%93%92.apk",
   "ios": "https://itunes.apple.com/cn/app/id1393938113",
   "pc": "",
   "preview":[
     'http://moke-store.oss-cn-beijing.aliyuncs.com/54e629ce-c37b-4dd8-acc9-68815aeea9e3.jpeg',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/ee53b9de-97eb-4dc7-97a8-ee2067859599.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/42c7f61d-eb7c-4d99-a396-733b8b7fa61f.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/71602b15-e7ea-478e-b2c7-3c9f49cf13be.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/a2b05350-3daa-4e65-86d8-4eb6e4a09a39.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/1c428605-f249-4662-bb46-2eddb9540a89.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/c62ca459-51b0-4559-ae53-81c743455abf.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/210370cd-88ce-4ca9-95d6-545afac327af.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/d75a2e8d-fc04-4861-bc5e-2d175993cf21.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/53e6fd35-e1a7-4729-aff6-b3a0fe95ec6a.png'
   ]
 }, {
   "name": "云达饭团",
   "des": "用于外卖产品的销售配送的典型App，包含餐品展示，在线购买，优惠券，店长管理，配送管理等功能模块。",
   "expense": "4万元",
   "cycle": "1个月",
   "logo": "http://moke-store.oss-cn-beijing.aliyuncs.com/d23fd81b-97b9-4f5b-a4d7-63d78a3a2b37.png",
   "android": "https://project-apk.oss-cn-beijing.aliyuncs.com/%E4%BA%91%E8%BE%BE%E9%A5%AD%E5%9B%A2.apk",
   "ios": "https://itunes.apple.com/cn/app/id1323947723",
   "pc": "",
   "preview":[
     'http://moke-store.oss-cn-beijing.aliyuncs.com/12b7dcea-eb9c-46f0-b2c6-2ccc35c2ecb0.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/3139e073-bacd-4c18-ae31-ad4b1d6cff43.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/633db1ba-7929-4c2e-ad70-8c2eaacc90a0.jpeg',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/1c71806d-5ed2-4708-a88f-9f01aebc4aea.jpeg',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/3d0e62f5-8903-49cb-905f-600b07c879a5.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/781e7cb8-5f61-4821-9e99-723892da717b.jpeg',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/bc4936ef-bbfe-498b-86cd-c179642e9b50.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/18c0b390-4b0e-47ec-b77f-57349d1bd0ec.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/6922821b-8ade-4c77-a57e-d2bbf159128f.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/f01df4e0-a5fb-4530-84f1-c2ce583c04ef.png'
   ]
 }, {
   "name": "智赛桥牌",
   "des": "用于桥牌比赛的桌面游戏软件，包含完整桥牌比赛流程，战队管理，比赛记录，结分计算，比赛裁判，在线视频，比赛录像等功能模块。",
   "expense": "50万元",
   "cycle": "5个月",
   "logo": "http://moke-store.oss-cn-beijing.aliyuncs.com/782e1256-a970-4399-87a9-d24073250053.png",
   "pc": "https://project-apk.oss-cn-beijing.aliyuncs.com/%E6%99%BA%E8%B5%9B%E6%A3%8B%E7%89%8C.exe",
   "preview":[
     'http://moke-store.oss-cn-beijing.aliyuncs.com/02c42159-2e28-48d2-a5ce-835f315517b6.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/d90fd130-1eb8-42fd-a6d1-40bf1eabf2fb.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/dae34372-f9b0-4f4b-ba6f-f13222831604.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/5112588c-8528-4f8c-a7ec-1857fd90dc70.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/7aee89a5-17ca-4b7b-b5e0-f0ea27d888cc.png'
   ]
 }, {
   "name": "智能水管家",
   "des": "用于企业或学校的热水器管理App，包含热水器状态查询，远程设置，耗电与用水统计，在线报修，经销商管理，滤芯损耗与更换等功能模块。",
   "expense": "5万元",
   "cycle": "2个月",
   "logo": "http://moke-store.oss-cn-beijing.aliyuncs.com/73a7767d-d9a5-40cd-aae3-04e4360db2e8.png",
   "android": "https://project-apk.oss-cn-beijing.aliyuncs.com/%E6%99%BA%E8%83%BD%E6%B0%B4%E7%AE%A1%E5%AE%B6.apk",
   "ios": "https://itunes.apple.com/cn/app/id1388200179",
   "pc": "",
   "preview":[
     'http://moke-store.oss-cn-beijing.aliyuncs.com/f3cc352d-9f49-42cc-9236-970b478067e8.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/76a9e0b4-d678-4daf-86e6-e7b7b8cb2372.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/6c0453b3-3648-483f-a073-e1a41bbe9465.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/cd478178-0327-4966-8701-b021938938f1.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/8f819ea6-1b8a-49a3-9e8a-2097fb646595.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/42e71c0c-af3b-4f3f-b116-cb807c15bc71.png'
   ]
 }, {
   "name": "房智汇",
   "des": "用于加拿大房地产的信息资讯类App，包含地图找房，价格走势，社区统计，双语切换，房屋信息，租房售房，房产经纪人，房产广告等功能模块。",
   "expense": "8万元",
   "cycle": "2个月",
   "logo": "http://moke-store.oss-cn-beijing.aliyuncs.com/0c87f657-9b85-470b-a199-1b901531b612.png",
   "android": "https://project-apk.oss-cn-beijing.aliyuncs.com/%E6%88%BF%E6%99%BA%E6%B1%87.apk",
   "ios": "https://itunes.apple.com/cn/app/id1255490256",
   "pc": "",
   "preview":[
     'http://moke-store.oss-cn-beijing.aliyuncs.com/1700f5ac-eed1-4d9d-b445-c41caa437445.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/823d26a4-688d-4543-84eb-218091c027fd.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/fcc72ac5-2f7b-47dd-8117-c6beac395940.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/512ac455-66d3-46df-82fb-af9d3f72e168.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/c205be0d-3666-47a4-8cce-7d1c1058afe2.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/e3784d81-978d-4d6a-91fa-f3b8657b45e6.png',
     'http://moke-store.oss-cn-beijing.aliyuncs.com/c10db6ca-4579-4872-87f0-58453bea37b5.png'
   ]
 }]
