<template>
	<div>
		<tk-flex column class="mobile-menu-fix">
			<tk-flex middle between class="mobile-menu">
				<img class="logo" src="http://moke-store.oss-cn-beijing.aliyuncs.com/6cb2e743-0d1a-4097-b62f-023d9bf60cf2.png">
				<tk-flex middle right class="right-menu">
					<div v-if="false">
					<img class="language-icon" src="http://moke-store.oss-cn-beijing.aliyuncs.com/5679eeaa-81cf-48f8-a160-45d01bc4d239.jpeg">
					<span class="language">中文</span>
					<img class="language-icon" src="http://moke-store.oss-cn-beijing.aliyuncs.com/fc991054-a834-43eb-aecb-4ffcabd1ec00.jpeg">
					<span class="language">EN</span>
					</div>
					<tk-flex middle center class="icon-wrap" @click.native="showMenu">
						<tk-icon size="15" color="white" v-if="!isShowmenu">navigation</tk-icon>
						<tk-icon size="15" color="white" v-else>down</tk-icon>
					</tk-flex>
				</tk-flex>
			</tk-flex>
			<!--<transition name='fade'>-->
				<div v-if="isShowmenu" style="width:100%">
					<tk-flex column center class='route_menu'>
					<div v-for="(item, key) in routeTo" :class="[isShowmenu ? 'menu-item' : 'menu-item-none']" :key='key' >
						<router-link :to="item.to" class="menu-item" :class='{active:(nowRoute.path===item.to)}' @click.native="closeMenu">{{item.title}}
						</router-link>
					</div>
					<div class='button'>
						<div v-fb @click="showPreviewf">免费评估项目
							<img :src="iconImg.right">
						</div>
					</div>
				</tk-flex>
				</div>
			<!--</transition>-->

		</tk-flex>
	</div>
</template>

<script>
    export default {
        name: "topMenumobile",
        data() {
            return {
                isShowmenu: false,
                nowRoute: this.$router.currentRoute,
                routeTo: [{to: '/',title: '首页1'}, { to: '/serve', title: '服务' },
                {to: '/project', title: '案例'},
                {to:'/about', title: '关于我们'}]
            }
        },
        methods: {
            showMenu: function () {
                this.isShowmenu = !this.isShowmenu
            },
            closeMenu: function () {
                this.isShowmenu = false
            },
        },
        watch: {
            $route(to) {
                if (this.nowRoute) {
                    this.nowRoute = to
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
	.mobile-menu-fix {
		width: 100%;
		position: fixed;
		z-index: 100;

		.mobile-menu {
			width: 100%;
			padding-left: 20px;
			height: 44px;
			background-color: rgba(255, 255, 255, 1);
		}

		.route_menu {
			transition: height 0.3s ease-out;
			padding-top: 40px;
			position: relative;
			z-index: 99;
			width: 100%;
			height: 623px;
			background-color: #20071B;

			.active {
				text-decoration: line-through
			}
		}

		.inside_out {
			width: 0;
			height: 0;
		}

	}
	.language {
		margin-right: 10px;
		font-size: 12px;
		color: rgba(244, 74, 79, 1);
	}

	.language-icon {
		margin-right: 10px;
		width: 16px;
		height: 16px;
	}

	.logo {
		width: 93px;
		height: 23px;
	}

	.menu {
		width: 34px;
		height: 34px;
		background-color: rgba(244, 74, 79, 1);
		border-radius: 5px;
	}

	.icon-wrap {
		margin-left: 15px;
		width: 34px;
		height: 34px;
		background-color: rgba(244, 74, 79, 1);
		border-radius: 5px;
	}

	.right-menu {
		margin-right: 15px;
		width: 180px;
	}
	.menu-item {
		transition: opacity 0.5s linear;
		margin: 31px 0;
		font-size: 18px;
		color: rgba(255, 255, 255, 1);
	}

	.menu-item-none {
		opacity: 0;
	}

	.button {
		margin-top: 50px;
		text-align: center;
		font-size: 16px;
		color: rgba(255, 255, 255, 1);
		line-height: 40px;
		width: 180px;
		height: 40px;
		background-color: rgba(244, 74, 79, 1);
		border-radius: 20px;
	}
</style>
