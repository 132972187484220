<template>
  <div>
    <div class="flex_column flex_center page-item item-1">
      <div class="title-2">案例分析研究</div>
      <div class="title-3">详细查看我们做了什么以及如何做</div>
      <div class="flex wrap flex_center first-line">
        <div
          v-for="(item,index1) in fourProjects1"
          :key="index1"
          class="flex_column flex_center cards"
        >
          <div class="click-img">
            <div class="img" @click="projectContent(item.projectIndex)">
              <img class="fade-in" v-lazy="item.src" />
            </div>
          </div>
          <lazy-component class="fade-in">
            <div class="script">{{item.script}}</div>
          </lazy-component>
        </div>
      </div>
      <div class="flex wrap flex_center">
        <div
          v-for="(item,index2) in fourProjects2"
          :key="index2"
          class="flex_column flex_center cards"
        >
          <div class="click-img">
            <div class="img" @click="projectContent(item.projectIndex)">
              <img class="fade-in" v-lazy="item.src" />
            </div>
          </div>
          <lazy-component class="fade-in">
            <div class="script">{{item.script}}</div>
          </lazy-component>
        </div>
      </div>
    </div>

    <div class="gray-line"></div>

    <div class="flex_column flex_center page-item item-2-wrap">
      <div class="title">查看我们其他作品</div>
      <div class="script">查看我们的移动和网络应用</div>
      <div class="flex flex_center wrap page-item item-2">
        <div v-for="(item,index) in otherProject" :key="index" class="card" style="width: 100%;">
          <lazy-component class="fade-in" style="width: 100%;">
            <div class="small_title flex items-center">
              <img
                style="width: 20px;height: 20px;"
                src="http://moke-store.oss-cn-beijing.aliyuncs.com/83812956-97ba-4745-bc42-26a184c0b44a.png"
              />
              {{ item.title }}
            </div>
            <div class="flex wrap" style="justify-content: space-around;">
              <div v-for="(s, i) in item.children" :key="i" class="flex flex_column small_item">
                <div class="click-img">
                  <div class="small-card" :style="s.back" @click="projectContent(s.projectIndex)"></div>
                </div>
                <div class="card-script">{{s.script}}</div>
              </div>
            </div>
          </lazy-component>
        </div>
      </div>
    </div>
    <div class="gray-line"></div>
    <div class="flex_column flex_center page-item item-3">
      <div class="title">在72小时内免费估算您的项目</div>
      <div class="script">三个简单的步骤即可获得项目的开发时间和成本</div>
      <div class="flex wrap flex_center maincards">
        <div class="three-cards" v-for="(item,index) in serveStep" :key="index">
          <div class="serial">{{index+1}}</div>
          <div class="steps-title">{{item.title}}</div>
          <div class="steps-script">{{item.script}}</div>
        </div>
      </div>
      <div class="click-button">
        <div class="button" @click="showPreviewf">
          免费评估您的项目
          <img :src="iconImg.right" />
        </div>
      </div>
      <a v-if="onMobile">
        <router-link to="/serve">还没准备好估算？了解我们的更多的服务介绍</router-link>
      </a>
    </div>

    <estimate-alert></estimate-alert>
  </div>
</template>

<script>
import EstimateAlert from './EstimateAlert'
export default {
  name: "pcProject",
  components: { EstimateAlert },
  data () {
    return {
      fourProjects1: [
        // {
        //   src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/376bdb81-d5c6-4bd3-b129-f166b7cb5b5e.png?x-oss-process=image/resize,w_560,h_300',
        //   script: '车辆信息智能监管应用',
        //   projectIndex: 1
        // },
        // {
        //   src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/443ed0c4-785f-4ce8-8f54-5eb155f5f7c0.png?x-oss-process=image/resize,w_560,h_300',
        //   script: '国外下单、接取任务应用',
        //   projectIndex: 0
        // }
      ],
      fourProjects2: [
        {
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/376bdb81-d5c6-4bd3-b129-f166b7cb5b5e.png?x-oss-process=image/resize,w_460,h_260',
          script: '车辆信息智能监管应用',
          projectIndex: 1
        },
        {
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a3d17653-28b7-46dc-8f03-fefee201fd2e.png?x-oss-process=image/resize,w_460,h_260',
          script: '软硬件结合智能伴宠机器人',
          projectIndex: 2
        },
        {
          src: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1a67a44-b78a-40c8-862f-39a3b020af46.png?x-oss-process=image/resize,w_460,h_260',
          script: '智能饮水机软件应用',
          projectIndex: 12
        }
      ],
      serveStep: [
        { title: '我们开始了解产品需求', script: '我们收集所有必要的需求信息以开始估算过程。' },
        { title: '估算团队开始工作', script: '开发人员和项目经理收集并估算您的想法需求。' },
        { title: '您会收到一个项目计划书', script: '您将获得大约的成本以及创建产品所需的时间。' }
      ]
    }
  },
  methods: {
    calIndex: function (index) {
      if (index === 0) return 5
      else return 2
    },
    projectContent: function (index) {
      // 0是小万生活
      this.$router.push({
        path: '/oneProject/',
        query: {
          index: index
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.item-1 {
  margin: 70px auto;
  width: 100%;
  height: auto;
  .title-2 {
    margin-top: 80px;
    line-height: 32px;
    font-size: 32px;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
  }

  .title-3 {
    margin: 18px auto 38px;
    line-height: 20px;
    font-size: 20px;
    color: rgba(124, 124, 153, 1);
  }

  .first-line {
    width: 100%;
    margin-bottom: 33px;
  }

  .cards {
    margin-right: 40px;
    height: 350px;
    .img {
      cursor: pointer;
      width: 460px;
      height: 260px;
    }
    .script {
      margin-top: 20px;
      line-height: 18px;
      font-size: 18px;
      color: rgba(45, 45, 73, 1);
    }
  }
}

.item-2 {
  max-width: 1200px;
  width: 100%;
  height: auto;
  padding-top: 20px;
  margin-bottom: 50px;
  text-align: center;

  .small-card {
    cursor: pointer;
    margin: auto;
    width: 366px;
    height: 192px;
    /*background-color: rgba(250, 250, 250, 1);*/
    border-radius: 10px;
  }

  .card-script {
    line-height: 18px;
    font-size: 18px;
    color: rgba(45, 45, 73, 1);
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .card {
    width: 396px;
  }
}

.item-2-wrap {
  margin-top: 80px;
  height: auto;
  .title {
    line-height: 1;
    font-size: 32px;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
  }

  .script {
    margin-top: 20px;
    line-height: 1;
    font-size: 20px;
    color: rgba(124, 124, 153, 1);
    margin-bottom: 62px;
  }
}

.item-3 {
  .title {
    font-weight: bold;
    margin: 79px 0 19px;
    line-height: 1;
    font-size: 2rem;
    color: rgba(45, 45, 73, 1);
  }
  .script {
    line-height: 1;
    font-size: 1.3rem;
    color: rgba(110, 110, 136, 1);
  }
  .three-cards {
    margin: 73px 33px 69px 33px;
    width: 342px;
    height: 167px;
    .serial {
      box-shadow: -4px -4px 4px rgba(244, 74, 79, 0.4);
      text-align: center;
      font-size: 20px;
      color: rgba(255, 255, 255, 1);
      line-height: 36px;
      width: 36px;
      height: 36px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 50%;
      margin-bottom: 20px;
    }
    .steps-title {
      line-height: 1;
      font-size: 20px;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
      margin-bottom: 30px;
    }

    .steps-script {
      line-height: 30px;
      font-size: 18px;
      color: rgba(124, 124, 153, 1);
    }
  }
  .button {
    cursor: pointer;
    text-align: center;
    line-height: 56px;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    width: 270px;
    height: 56px;
    background-color: rgba(244, 74, 79, 1);
    border-radius: 28px;
    margin-bottom: 82px;
  }
  a {
    text-decoration: underline;
    margin-top: 15px;
    font-size: 11px;
    color: #7c7c99;
  }
}
.small_title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2d2d49;
  display: flex;
  align-items: center;
  margin-bottom: 29px;
  img {
    margin: 0 8px 0 24px;
  }
}
@media screen and (max-width: 600px) {
  .item-1 {
    padding-bottom: 30px;
    .first-line {
      margin-bottom: 0px;
    }
    .title-2 {
      margin-top: 30px;
      font-size: 20px;
    }
    .title-3 {
      font-size: 12px;
      margin: 16px 0px;
    }
    .cards {
      margin-right: 0px;
      height: 240px;
      .img {
        width: 335px;
        height: 190px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .script {
        font-size: 14px;
        line-height: 1;
      }
    }
  }
  .item-2 {
    margin-bottom: 0px;
    .small-card {
      width: 90%;
      min-width: 345px;
      // height: 82px;
    }
    .card {
      width: auto;
    }
    .card-script {
      font-size: 11px;
    }
  }
  .item-2-wrap {
    margin-top: 0;
    .title {
      margin-top: 35px;
      font-size: 20px;
    }
    .script {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  .item-3 {
    margin-bottom: 30px;
    .script {
      margin-bottom: 12px;
    }
    .title {
      margin-top: 46px;
    }
    .three-cards {
      height: auto;
      margin: 10px 0px;
      text-align: center;
      .serial {
        width: 18px;
        height: 18px;
        font-size: 10px;
        line-height: 1.5;
        margin: 0 auto 10px;
      }
      .steps-title {
        line-height: 1;
        font-size: 14px;
        margin-bottom: 0px;
      }
      .steps-script {
        font-size: 12px;
      }
    }
    .button {
      width: 180px;
      height: 40px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 2.6;
    }
  }
  .small_title {
    font-size: 2rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2d2d49;
    display: flex;
    align-items: center;
    margin-bottom: 29px;
    img {
      margin: 0 8px 0 24px;
    }
  }
  .small_item {
    width: 100%;
  }
}
</style>
