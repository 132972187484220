<template>
	<div class="flex_column flex_center top-page" v-if="!onMobile">
		<div class="title-2">
			<lazy-component class="fade-in">在找软件开发公司？</lazy-component>
		</div>
		<lazy-component class="fade-in">
			<div class="title-3">不如找一个<span style="color:#F4365A ">长期合作伙伴</span><br/>来承担您遇到所有的技术问题
			</div>
		</lazy-component>
		<lazy-component class="fade-in">
			<div :class="{'click-button':!onMobile}">
				<div class="button" @click="showPreviewf">免费评估您的项目
					<img :src="iconImg.right">
				</div>
			</div>
		</lazy-component>
	</div>
	<div class="flex_column flex_center top-page" v-else>
		<div class="title-2">在找软件开发公司？</div>
		<div class="title-3">不如找一个<span style="color:#F4365A ">长期合作伙伴<br/></span>来承担您遇到所有的技术问题</div>
		<div :class="{'click-button':!onMobile}"><div class="flex flex_center button" @click="showPreviewf" v-fb>免费评估您的项目<img :src="iconImg.right"></div></div>
	</div>
</template>
<script>
    export default {
        name: 'topBanner'
    }
</script>
<style scoped lang="scss">
	.top-page {
		@media screen and (max-width: 1160px) {
			width: 100%;
			margin-top: 44px;
		}
		@media (min-width: 1160px) {
			min-width: 1160px;
			margin-top: 70px;
		}
		background: #130E2D url('http://moke-store.oss-cn-beijing.aliyuncs.com/8870f04a-3a20-4eee-b13c-4652a2a96260.jpeg') center center no-repeat;
		height: 780px;
		background-size: cover;
		letter-spacing: 5px;
		.title-2 {
			margin-top: 233px;
			margin-bottom: 30px;
			line-height: 40px;
			color: white;
			font-size: 40px;
			color: rgba(255, 255, 255, 1);
		}
		.title-3 {
			line-height: 75px;
			text-align: center;
			font-weight: bold;
			font-size: 50px;
			color: rgba(255, 255, 255, 1);
			margin-left: 12px;
		}
		.button {
			text-align: center;
			cursor: pointer;
			margin-top: 51px;
			margin-bottom: 221px;
			width: 270px;
			height: 56px;
			line-height: 56px;
			letter-spacing: 0;
			background-color: rgba(244, 74, 79, 1);
			border-radius: 28px;
			font-size: 20px;
			color: rgba(255, 255, 255, 1);
		}
		@media screen and (max-width: 600px) {
			letter-spacing: 0;
			background-image: url('http://moke-store.oss-cn-beijing.aliyuncs.com/b3ee9d10-16ec-465a-b338-4c932286d8ba.jpeg');
			height: 520px;
			.title-2 {
				margin-top: 134px;
				margin-bottom: 21px;
				line-height: 16px;
				font-size: 16px;
				color: rgba(255, 255, 255, 1);
			}
			.title-3 {
				margin: 0;
				padding: 0 30px;
				line-height: 38px;
				font-size: 25px;
				color: rgba(255, 255, 255, 1);
			}
			.button {
				text-align: center;
				margin-top: 39px;
				/*margin-bottom: 158px;*/
				width: 200px;
				height: 40px;
				line-height: 40px;
				background-color: rgba(244, 74, 79, 1);
				border-radius: 20px;
				font-size: 16px;
				color: rgba(255, 255, 255, 1);
			}
		}
	}
</style>
