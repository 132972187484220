export default [
  {
    name: 'HIRO',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ae48e981-9ff1-435c-865d-9a142c7ec6c3.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/57f3e0aa-b108-4abb-bee7-c399fd2f0901.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/36e07c69-31af-403f-9110-99d8cf31b61d.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '利用游戏化的模式，进行下单和接单。根据定位查看周边HIRO的位置。使HIRO边娱乐边挣钱。',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e86cd867-3fb5-4185-9caa-e879040c8438.png',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: 'HIRO衣橱', script: '在这里英雄可以更换英雄的形象，英雄可以根据自己的爱好来选择自己的形象。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/571eb335-852b-4064-b8eb-a919e90f311c.png' },
      { title: 'HIRO位置', script: '系统获取用户的定位，根据定位信息。用户可以看到附近英雄的位置。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c4598aba-fb37-41a7-b268-1b99375460bc.png' },
      { title: 'HIRO信息', script: '点击英雄的头像，出现一个小的弹窗，可以看到英雄的部分信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ed48245a-30fd-40b1-b9a1-b290d4898964.png' },
      { title: 'HIRO简介', script: '在Profile中可以看到英雄的信息评分、经验、什么时候开始成为英雄的。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6b57edce-d627-4923-86af-4a48fe5d93e9.png' },
      { title: '消息沟通', script: '在Messages里，可以看到和英雄沟通的内容，未读条数等信息，方便和英雄进行交谈。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a64ef428-3877-42ea-9b6b-354194104cfb.png' },
      { title: '个人中心', script: '汇集用户基本信息，基本功能的集合。如英雄的位置、衣橱、基本设置、开关等功能。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5f8f1152-5ed5-42f7-8f87-191ef4fe5996.png' },
      { title: '任务中心', script: '英雄可以在任务中心查看、接取任务，显示任务的时间、赏金等信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1ebf1ec9-9dd3-4042-beb1-40b356fdba6b.png' },
    ],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          'Flutter 是 Google 推出并开源的移动应用开发框架，主打跨平台、高保真、高性能。开发者可以通过 Dart 语言开发 App，一套代码同时运行在 iOS 和 Android平台。 Flutter 提供了丰富的组件、接口，开发者可以很快地为 Flutter 添加 Native 扩展。',
          'Flutter谷歌开发是最新的跨平台开发方式，在国外是非常流行的，相较于其他开发方式，Flutter具有成本低、开发快、性能好的优点。'],
        items: [
          { title: '框架', keywords: 'Flutter' },
          { title: '单元测试', keywords: 'unittest，GoogleTest' },
          { title: '异步编程', keywords: 'Kotlin协程，RxSwift' },
          { title: '数据架构', keywords: 'hasura' },
          { title: '分析工具', keywords: 'MixPanel，Instabug' },
          { title: '布局', keywords: 'SDK SnapKit' },
          { title: '推送通知', keywords: 'firebase' },
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }
        ]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2bc82d33-d14c-4df2-831d-2ebead8247b4.png',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI原型设计', '服务器运维'
    ]
  },
  {
    name: '车辆管理系统',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b2bbaba8-fe40-46d2-a098-6717c0c6f4b4.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6507f6cb-5d41-4631-bd62-1b9598867495.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d3310f92-cbf8-49e5-abdf-4d0b3d2a03e1.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '为更好的对人、车、违章、道路拥堵的管控，特建设车辆管理系统，建立安全监管规范制度，通过科技手段智能化执行监管任务，对并大数据进行分析，优化港内运输效率。',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7e34f797-1527-4293-8c70-cc67bfeafb81.jpeg',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      {
        itemsTitle: '车辆管理APP',
        content: [
          {
            title: '用户类型', script: '注册APP时需要选择用户类型。系统会根据用户的身份，展示对应的APP功能。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0897016f-4a56-4f82-923d-7579d059025c.png'
          },
          {
            title: '车辆详情', script: '清楚的展示用户车辆的交纳金额、扣款金额、当前余额信息。可随意更换车辆的司机信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b9d946b6-49b6-4a66-9fd0-f79bdd2ca125.png'
          },
          { title: '我的页面', script: '系统根据用户的身份，在我的页面显示相对应的功能，使软件的易用性得到了进一步提升。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6386259e-ac0c-4646-9415-0646d0dbe6a6.png' },
          { title: '首页', script: '支持单独交纳车辆履约金、批量交纳车辆履约金。金额的汇总和计算将省去大量的人力核算提高工作效率。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/66c4a0b8-3868-4f60-8918-f4012177d5b7.png' },
          { title: '地图导航', script: '根据定位查找附近的店铺优惠和权益活动，同时支持本地化水电费、燃气费充值和本地资讯等生活服务内容', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4069381b-7102-4c8e-b669-1311d7b783c0.png' },]
      },
      {
        itemsTitle: '总管理平台端',
        content: [
          { title: '数据统计', script: '整体数据的统计，履约金信息统计、用户类型统计、车辆情况统计信息。所有信息一目了然。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/dba381eb-883c-45b1-b9fd-0cf21c2cae64.png' },
          { title: '进出记录', script: '系统统计园区车辆的进出时间，可以方便快捷的进行管理园区，维护园区治安。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/93acecfb-9ea0-4b67-9f90-f4495241c390.png' },
          { title: '信息发布', script: '后台控制园区大屏推送显示内容。可显示车辆的违章信息、园区拥堵情况、高风险地区预警信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/324cfcf0-7cba-4bca-9046-4b587f29c6aa.png' },
          { title: '高风险地区', script: '后台添加设置高风险地区，系统将根据车辆的车牌号进行识别，禁止高风险地区车辆进入。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/885359a2-1226-4079-bed5-a204d99d049d.png' },
          { title: '设置', script: '管理员可在后台设置一些APP的使用规则、用户协议、隐私政策等信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/051eb4eb-e685-4a31-89a2-22b4a06eaa37.png' },
        ]
      }
    ],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          'Flutter 是 Google 推出并开源的移动应用开发框架，主打跨平台、高保真、高性能。开发者可以通过 Dart 语言开发 App，一套代码同时运行在 iOS 和 Android平台。 Flutter 提供了丰富的组件、接口，开发者可以很快地为 Flutter 添加 Native 扩展。',
          'Flutter谷歌开发是最新的跨平台开发方式，在国外是非常流行的，相较于其他开发方式，Flutter具有成本低、开发快、性能好的优点。'],
        items: [
          { title: '框架', keywords: 'Flutter' },
          { title: '单元测试', keywords: 'AssertK，Mockito' },
          { title: '异步编程', keywords: 'Kotlin协程' },
          { title: '反应式编程', keywords: 'RxSwift' },
          { title: '分析工具', keywords: 'MixPanel，Instabug' },
          { title: '布局', keywords: 'SDK SnapKit' },
          { title: '推送通知', keywords: 'PushKit，CallKit' },
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }
        ]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/00685d01-0dee-49b6-ba61-d2c92fe99cfe.png',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI界面设计', '服务器运维'
    ]
  },
  {
    name: '古力古利',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5bc95e03-fed0-450b-86cf-621c5c253eb1.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9c3a0775-8a21-4ae9-b80d-1f125334e87c.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/58fb3df6-3293-4db7-a531-e72e80b6a673.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '古力古利是一款与智能伴宠机器人相结合的，可实现实时视频通信，与爱宠亲密交流、互动的APP。不论是出差、旅行、工作、加班都可以随时与爱宠互动，像平时在家一样，参与爱宠生活的点滴时刻。',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/43c8ceb0-d2a4-426d-8798-5d06081ffc91.png',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '个人中心', script: '汇集用户基本信息，基本功能的集合。如设备解绑、重置WiFi等。便于用户进行操作。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a3477094-3045-4dd1-97e9-a4dbd55d7d04.png' },
      { title: '相册', script: '用户使用设备给宠物进行拍照，拍摄的照片将储存在AP的相册中，可对相册进行删除管理。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9b015b83-4edc-4275-a1d6-f0da5fe9eeab.png' },
      { title: '设备控制', script: '用户可通过APP对设备进行控制实现远程拍照、录音摄像等功能。实现不在家即可看到宠物的动态。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/58c62a66-e84b-4d7f-be13-3d8ba12d4ee9.png' },
      { title: '设备连接', script: '用户通过手机蓝牙与硬件设备相连接，连接完成后，可通过APP对设备进行操控。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/bec63dc5-b251-4d69-b61c-255687586ced.png' },
      { title: '启动机器', script: '用户操控硬件设备开关，启动机器人，硬件工作准备就绪，准备第二部操作。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c4da94b5-7967-4dd6-a18b-4c717b0ac2d3.png' },
      { title: '共享设备', script: '用户点击发起共享，输入被分享设备账号，输入完成可共享使用该设备，我的共享生成共享记录。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ae09849d-6036-4680-8c17-00ccf98a12f8.png' },
    ],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          'Flutter 是 Google 推出并开源的移动应用开发框架，主打跨平台、高保真、高性能。开发者可以通过 Dart 语言开发 App，一套代码同时运行在 iOS 和 Android平台。 Flutter 提供了丰富的组件、接口，开发者可以很快地为 Flutter 添加 Native 扩展。',
          'Flutter谷歌开发是最新的跨平台开发方式，在国外是非常流行的，相较于其他开发方式，Flutter具有成本低、开发快、性能好的优点。'],
        items: [
          { title: '框架', keywords: 'Flutter' },
          { title: '单元测试', keywords: 'AssertK，Mockito' },
          { title: '异步编程', keywords: 'Kotlin协程' },
          { title: '反应式编程', keywords: 'RxSwift' },
          { title: '分析工具', keywords: 'MixPanel，Instabug' },
          { title: '布局', keywords: 'SDK SnapKit' },
          { title: '推送通知', keywords: 'PushKit，CallKit' },
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }
        ]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/04626fee-5ff1-456c-a572-c6ec7845b1d9.png',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI原型设计', '服务器运维'
    ]
  },
  {
    name: '小萬生活',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d762e23-5db9-4a57-8888-88696f1dcd55.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fff51b75-0c18-4a8c-a7e5-469f47320a62.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ce632733-2f3d-4430-b250-91137f787ded.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '本地化服务商，您可以在上面体验所在地的权益和服务',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    // process: [
    //   {
    //     card_title: '第一期（√）',
    //     card_script: '本期实现：o2o商场、商家模块、会员分享购买，达到用户基本使用功能使用',
    //     time: '2019年3月8日上线',
    //     title: '超1年',
    //     text: '总开发时长'
    //   }, {
    //     card_title: '第二期（√）',
    //     card_script: '本期实现：短视频模块、用户分润、智能洗车机、话费充值等优化用户体验功能',
    //     time: '2019年10月19日上线',
    //     title: '12名',
    //     text: '开发人员'
    //   }, {
    //     card_title: '第三期（进行中）',
    //     selected: true,
    //     card_script: '本期预计实现：直播模块、社区社交模块、快递配送、会员分销逻辑修改等功能',
    //     time: '预计2020年2月14日',
    //     title: '132个',
    //     text: '更新版本'
    //   }, {
    //     card_title: '第四期（设计阶段）',
    //     card_script: '需求功能设计：项目众筹、公益众筹、在线聊天、消息实时推送，优化直播模块等',
    //     time: '预计2020年7月5日',
    //     title: '319张',
    //     text: 'UI设计图'
    //   }, {
    //     card_title: '第五期（未开始）',
    //     card_script: '对于核心平台支付，对接支付宝蚂蚁信用。做自有支付平台和信用贷款等金融模块',
    //     time: '预计2020年12月13日',
    //     title: '743个',
    //     text: '问题沟通并解决'
    //   }
    // ],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8476b203-b0b3-4ac2-9c74-1fa0c4652e9c.jpeg',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '商家店铺详情', script: '商家店铺的一些点评和环境是用户所关心，环境方面除了常规图片展示还配合了VR浏览，点评系统会根据每次用户消费点评计算而来，使数据更可信', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5dce0c77-4b85-42c3-b752-75698c3ec3b7.jpeg' },
      { title: '用户购买会员', script: '用户开通金钻会员、蓝钻会员可享受商家的免费定制服务，添加推荐人，共享积分奖励', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0975fc35-bde3-419c-b01e-fa0a8510cd7d.jpeg' },
      { title: '订单结算', script: '为便于促成交易，在自有的交易平台的基础上，同步支持支付宝、微信等快捷交易', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c24f1d7f-e4dd-4a61-a638-988e2364c348.jpeg' },
      { title: '商家的权益优惠', script: '商家权益是商家为小萬会员用户提供的优惠权益，会员可享免费定制权益和服务', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/78739412-ef2d-41aa-9cd1-d41d348a9841.jpeg' },
      { title: '小萬生活首页', script: '根据定位查找附近的店铺优惠和权益活动，同时支持本地化水电费、燃气费充值和本地资讯等生活服务内容', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/74c4d86a-a073-4d16-9450-138af453b3e2.jpeg' },
      { title: '生活缴费', script: '基于第三方，在此应用上实现了本地化生活服务，用户可以享受到足不出户的便利', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/462d28cb-376b-4e35-ab19-8ae2d9da15d5.jpeg' },
      { title: '社区动态发现', script: '视频传播和优质化内容是社区发展的本质，在技术上规避不良信息，根据核心算法实现优质内容推送，增加用户的对于APP的粘性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8491a063-c754-411a-9fcc-8bf938e0ae3e.jpeg' },
      { title: '商家VIP购买', script: '为促进商家优质性和竞争性和平台的变现能力，对于商家开通VIP入驻等服务', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/43d8b10e-7946-4405-9ee4-0b065319571b.png' },
      { title: '商家中心', script: '商品服务平台是为了商家便于操作编辑店铺信息搭建的，使商家便于操作更新店铺商品和查看订单', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/752efcb9-4ca1-4e67-bd7a-c30ff57b1c9f.png' },
      { title: '个人中心', script: '用户中心集合了个人信息管理，平台活动参加，团队创建等多种功能，数据整理和信息分发易于用户的使用', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6d51fbde-f135-405c-b7d8-5ccd4310244a.png' }],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          '我们选择了MVVM，这是Google推荐的一种架构模式。它帮助我们使UI代码保持简单且无应用逻辑。该代码健壮，高质量且易于管理。',
          '我们决定使用诸如Realm或Texture之类的高效工具  ，分别覆盖数据库通知和UI。此外，我们必须成为线程处理方面的专家，并发明自己的Queue Queue，这是此用例的出色数据结构。'],
        items: [
          { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: 'Vue.js' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '地图', keywords: '高德导航和位置服务解决方案' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },
          { title: '支付方式', keywords: '微信/支付宝' },]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/465817db-6626-4173-a6e0-3ff7716d5cdd.jpeg',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '智能设备开发', '微信小程序开发', '应用图标设计', '产品原型设计', 'UI原型设计', '微信推广活动', '服务器运维'
    ]
  },
  {
    name: '威选商城',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7a20b13f-5bc1-4b0f-bb8b-415cc5487523.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e37b8212-5bf9-4e6a-ad0c-ca11b9d35714.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3e5b5c3e-757d-4dd5-9bfd-f06ad200e750.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '电动车配件B2B交易服务平台，线上包含分类、社区、购物车等功能，可在线购买一些电动车的配件。致力于成为让客户放心购的一站式电配摩配集采平台。',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3b080efb-ab66-414c-b0ae-3662fa086293.png',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '首页', script: '用户可在首页查看新品、促销产品、热卖榜单。也可以通过搜索快速找到想要购买的商品。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/86b3e70d-8378-4e99-90b2-32ea3b1f8fcd.png' },
      { title: '详情页', script: '可以看到商品的原价、折扣价、活动结束时间、优惠活动、商品详情等信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4278c9ad-3f05-48b1-a374-4d65cea81d5e.png' },
      { title: '生日祝福', script: '系统显示近期将要过生日的好友，用户可在这里送好友礼物、欢乐豆或幸运星。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/22916f92-ea51-4fa5-b20d-7efec0ef71f9.png' },
      { title: '我的欢乐豆', script: '当消费超过100元时，系统会返给用户一定的欢乐豆，欢乐豆可以抵扣现金使用。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c37ecf95-ab4e-46b5-b58c-f882fdbd235f.png' },
      { title: '我的幸运星', script: '做任务可获得幸运星，当幸运星累积到一定数量时，可进行抽奖，获得对应的奖品。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/60f7a41e-9a9c-46ba-a4e9-7fc7cdc30819.png' },
      { title: '限时抢购', script: '点击此页可以查看折扣商品信息，系统统计该商品抢购的数量，和显示活动的时间，增强用户的购买欲。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d5e65592-027a-40e3-9d8b-78cad4bb1f57.png' },
      { title: '热卖榜单', script: '系统根据商品的销售量，生成榜单。用户可根据榜单购买自己心怡的优质商品。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2ef943f7-407b-4bac-bf75-0a78330bd251.png' },
      { title: '个人中心', script: '汇集用户基本信息，基本功能的集合。如我的订单、我的钱包等。便于用户进行操作。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/05d983a9-87fb-4145-bb93-ba5ae516892a.png' },
    ],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          '我们选择了MVVM，这是Google推荐的一种架构模式。它帮助我们便U代码保持简单且无应用逻标。该代码健壮，高臣且易于管理。',
          '我们决定使用诸如Realm或Texture之的高效工具，分别覆盖数据库通知和U。此外，我们必须成为线程处理方面的专家，并发明自己的Queue Queue，这是此用例的出色数据结构。'],
        items: [
          { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: 'Vue.js' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },
          { title: '支付方式', keywords: '微信/支付宝' },
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/465817db-6626-4173-a6e0-3ff7716d5cdd.jpeg',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI原型设计', '服务器运维'
    ]
  },
  {
    name: '清博教育系统',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/581b237b-6f1c-4004-aa5c-9baa3d0991d5.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/015d9c01-d61e-4153-84f3-4deac312ef54.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ff7059d5-0030-43f1-a7df-f2f050681e61.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '清博教育生态应用系统，将公司自身沉淀的教学数据、案例、教学资源建设成一个数字创意类课程（专业）的数字化人才培养平台，服务合作院校的学生。',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'UI页面设计', 'WEB网页', '公众号开发', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/bb8ad8eb-4e89-4930-997c-eac75c8dbfd6.png',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '专业选课', script: '课程以模块的形式排列展示，内容清晰可见，当学院选了课程，相应课程的按钮会变为灰色。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/76fa30db-95a6-4b9f-8544-ec77c05d040c.png' },
      { title: '课程集合', script: '课程详情，可以看到大概有多少课程，授课的教师、课程介绍等信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/cbf43349-e66a-4a59-9a56-1f1923c21982.png' },
      { title: 'AI学习地图', script: '学员选择专业后，系统会自动生成学习地图，把适合学习的课程推荐给学员。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/65f69723-8d19-4ca3-ab76-9fbca27cd787.png' },
      { title: '选课中心', script: '学员选择多个课程，添加至选课中心，可批量提交课程、删除课程信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/582ed170-1c43-41ec-8463-d7cc9fae07bc.png' },
      { title: 'AI学习地图', script: '学员可查看自己的学习课程、学习进度等信息。对自己的学习情况一目了然。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e01d95bc-73b8-46e2-b55c-d9d9f13e47a6.png' },
      { title: '课程详情页', script: '学员可以看到，该课程的教师信息、课程的节数、课程大纲信息、课程目录、课程计划和学员对该课程的评价。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/25214036-e549-4334-a137-ca963072714f.png' },
      { title: '课程播放', script: '学员在查看教学视频的同时，可以在目录里切换课程，查看该课程教师布置的作业内容，可以边看，边在笔记里记录该课程的重要信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ff10265c-7e63-4a4c-9762-585638dd076b.png' },
      { title: '作业详情', script: '在作业详情里可以查看学员的作业得分情况，可以和教师在这里沟通学习。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/15c33806-fab1-45ba-b9fc-83d41142ef52.png' },
      { title: '我的作业', script: '全面了解自己的作业信息，可在我的作业里下载素材，查看作业提交的状态，得分情况，上传作业等。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/45fee1b0-3787-40d8-a7a0-a02a93fae639.png' },
      { title: '我的课表', script: '一周的课程清晰的展示出来，学员可以清楚的知道每天有哪些课程。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/160a24ec-070c-4a1b-95cd-08b3ec1215a0.png' }],
    tech: [
      {
        title: '网站开发',
        script: ['我们用于网站开发解决方案',
          '网站开发以access_token为接口调用凭据，来调用接口，所有接口的调用需先获取access_token',
          'Ant Design of Vue提炼自企业级中后台产品的交互语言和视觉风格，是一款开箱即用的高质量 Vue 组件。'],
        items: [
          { title: '技术架构', keywords: 'antdesign vue' },
          { title: '数据架构', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: '框架Vue.js' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务器' },
          { title: '开发方式', keywords: 'H5+原生混合开发' }
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }
        ]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e4a8b3c0-15e9-4736-b32e-31d00e25f433.png',
    serveRange: [
      {
        title: '微信公众号开发',
        img: require('../svg/weixin.svg'),
        script: '在客户公众号开发推送通知。使用户能够及时的获取到消息'
      }, {
        title: 'web网页',
        img: require('../svg/web.png'),
        script: '配合后台，对课程进行管理，吸引用户订购课程内容'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI原型设计', '微信推广活动', '服务器运维'
    ]
  },
  {
    name: '有光万物',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/537999a4-7786-4519-a6a5-c66301151453.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/bb439f11-8087-4f44-bff7-c229c26b26a8.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0cfa977f-4776-41e8-be97-49d322e62f2f.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fff51b75-0c18-4a8c-a7e5-469f47320a62.png',
    script: '有光万物App是Light & Health品牌旗下养宫精灵产品的专属智能控制应用，包含以下功能：1、生理期管理及生理期提醒功能； 2、养宫精灵功能控制及使用帮助与指导；  3、生理期健康知识 ',
    // function: [
    //   ' 1、生理期管理及生理期提醒功能；',
    //   ' 2、养宫精灵功能控制及使用帮助与指导； ',
    //   ' 3、生理期健康知识 ',
    // ],
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fed45df9-0ec8-4a02-a2e7-7d29e5d836c4.png',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '首页-月经期', script: '用户处于月经期时，通过应用控制设备，提供了石墨烯加热和双红光两种模式，增加定时服务更省心。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9e365883-34eb-46c1-9b5e-95cfd6d30487.png' },
      { title: '首页-月经前期', script: '用户处于月经前期，为了用户能享受更好的体验，为用户提供贴心的操作意见。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/24b563c0-9838-4c53-9baf-e3e803232e95.png' },
      { title: '首页-正常期', script: '用户处于正常期，根据用户经期周期结合日历推算出下次月经时间。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ba00aab9-9da1-4800-98d6-50a38ab21012.png' },
      { title: '经期记录', script: '通过日历的形式，精准的记录每日情况，技术上根据用户经期的周期变化，预测用户未来经期。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/28016f3c-8b1c-4dc0-a5c5-1c5b221bf922.png' },
      { title: '预测经期', script: '预测经期满足了用户需求，为用户提供了更多数据支持。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b1d365a8-a74e-4b26-8d30-cd1f3bffeaf9.png' },
      { title: '经期小贴士', script: '用户在经期需要了解一些注意事项，我们贴心的为用户提供经期知识。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/601341a5-8bf7-45de-a176-7f9f7aa45f31.png' },
      { title: '生理资讯', script: '管理员可发布一些关于健康的资讯。丰富有趣的内容可以增加用户的粘性。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e2ead6e4-571a-418b-b3bb-f785065671a8.png' },
      { title: '我的', script: '用户进入APP的闪屏图，品牌logo名称映入眼帘，给用户留下初步印象。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a61c7837-1333-4363-af8f-0146ca709515.png' }
    ],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          'Flutter 是 Google 推出并开源的移动应用开发框架，主打跨平台、高保真、高性能。开发者可以通过 Dart 语言开发 App，一套代码同时运行在 iOS 和 Android平台。 Flutter 提供了丰富的组件、接口，开发者可以很快地为 Flutter 添加 Native 扩展。',
          'Flutter谷歌开发是最新的跨平台开发方式，在国外是非常流行的，相较于其他开发方式，Flutter具有成本低、开发快、性能好的优点。'],
        items: [
          { title: '框架', keywords: 'Flutter' },
          { title: '单元测试', keywords: 'AssertK，Mockito' },
          { title: '异步编程', keywords: 'Kotlin协程' },
          { title: '反应式编程', keywords: 'RxSwift' },
          { title: '分析工具', keywords: 'MixPanel，Instabug' },
          { title: '布局', keywords: 'SDK SnapKit' },
          { title: '推送通知', keywords: 'PushKit，CallKit' },
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }
        ]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/aed6cdfa-36ed-4056-bc10-76021d598a61.png',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI界面设计', '服务器运维'
    ]
  },
  {
    name: 'TATTOOLS',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a88a07e3-3a5f-4543-97af-4861725d0db4.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ce58fcb4-56d4-4a84-8418-0b9213fcb2e7.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3e090b9d-c76f-4a1e-925a-e22ecc1a4278.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
    script: '手机管理纹身店铺信息，每日收入、支出清晰可见。还支持手机预约纹身，方便快捷。',
    brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/559b6676-c4e7-4daf-aaf2-3fd3b50cfd77.png',
    scope: [
      {
        icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
        content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
      },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '启动页', script: '用户进入APP的闪屏图，品牌logo名称映入眼帘，给用户留下初步印象。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/298757f5-73c8-44d1-b414-b738ebef9736.png' },
      { title: '推荐案例', script: '在首页的推荐案例列表信息，可以看到案例的评论人数和点赞人数。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/32910fb9-9dcb-49ad-904f-492bba22f57d.png' },
      { title: '设计师', script: '在首页的设计师列表信息，可以看到设计师发表的作品，和对该作品的描述。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e359ce95-2e3d-4c9b-92a2-914bd7982f8d.png' },
      { title: '案例', script: '在首页的设计师列表信息，可以看到设计师发表的作品，和对该作品的描述。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d96887b9-ac7e-4cb6-844f-9f1ff5fcd19d.png' },
      { title: '附近店铺', script: '系统获取手机定位，根据用户的定位，为用户附近的店铺信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d77bfd5-6026-4f32-b941-aac6420af87b.png' },
      { title: '店铺详情', script: '用户查看店铺地址、电话详情。查看店铺纹身师的作品、信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ae5905b5-3b9b-4de1-90ab-1ad0be3e0563.png' },
      { title: '工作台', script: '纹身师在工作台，可以看到近期预约信息，和等待纹身的用户信息。可以快速的清楚每天的工作安排。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1831ab5-3857-4d5e-9002-c12e43f5b19d.png' },
      { title: '创建订单', script: '纹身师可为顾客创建订单，填写项对应的金额，可创建多个账单，满足现实的实际应用中。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e6171f8c-877b-4b49-9177-cf339d9f4805.png' },
      { title: '店铺收益', script: '可根据日期筛选，查看店铺的收入和支出情况。可查看店铺的明细信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/db31e307-40f3-437e-b856-d9389fbe3ecc.png' },
    ],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          'Flutter 是 Google 推出并开源的移动应用开发框架，主打跨平台、高保真、高性能。开发者可以通过 Dart 语言开发 App，一套代码同时运行在 iOS 和 Android平台。 Flutter 提供了丰富的组件、接口，开发者可以很快地为 Flutter 添加 Native 扩展。',
          'Flutter谷歌开发是最新的跨平台开发方式，在国外是非常流行的，相较于其他开发方式，Flutter具有成本低、开发快、性能好的优点。'],
        items: [
          { title: '框架', keywords: 'Flutter' },
          { title: '单元测试', keywords: 'AssertK，Mockito' },
          { title: '异步编程', keywords: 'Kotlin协程' },
          { title: '反应式编程', keywords: 'RxSwift' },
          { title: '分析工具', keywords: 'MixPanel，Instabug' },
          { title: '布局', keywords: 'SDK SnapKit' },
          { title: '推送通知', keywords: 'PushKit，CallKit' },
        ]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }
        ]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9aa7c526-acb4-41c9-8eb2-95f98f735602.png',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
      }, {
        title: '移动安卓App',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI原型设计', '服务器运维'
    ]
  },
  {
    name: '简果',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/72dd6b14-b948-422e-83d1-7c83c7854bc4.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4be3b9d5-62f2-44f1-96e3-3a5e69661d9d.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ec5711d1-468f-428f-bdf9-6ba3a6c22746.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fbb03102-6f81-4d70-956e-e649c41416c4.jpeg',
    script: '大型储存运输管理应用，提供库存管理、物料管理、员工管理等功能',
    brands: [1, 2, 6, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b1eca851-307c-41ea-86f1-be2a63ac2492.jpeg',
    scope: [
      { icon: 'ios', title: '移动应用开发', script: '我们的目标是为简果开发ios和Android移动在线管理应用程序。', subTitle: '用户可以：', content: ['进行入库出库管理', '对员工进行管理', '管理物料信息', '在线记账', '导出配货单'] },
      { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '调整产品售价', script: '水果了类产品售价要根据季节和市场灵活调控，我们在管理者端口做了数据互通，保证用户获取到实时价格数据', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b56bf932-df5f-4037-a8b8-9a09c27b0455.jpeg' },
      { title: '创建配货单', script: '订单的记录代替了人工手记繁琐工作，对于重复性工作我们做到自动填写录入，并且对于每一个单数据都有备份储存和实时打印', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/340ac125-8b57-443f-a128-2e3d8d4101a4.jpeg' },
      { title: '物料登记', script: '水果包装物料是必备产品，对于整个厂区的大量物料产品管控和监管，软件代替人工可以查溯到物料的进出数据和所有使用位置', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/cadd4104-66cd-43a2-bb7a-50b8f1a1e2fe.jpeg' },
      { title: '在线记账', script: '厂区管理员手工记账工作繁琐，数据整理计算繁重、类目不规范等问题都可以在软件记账方式下解决', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/440348b3-3b17-4dea-85c4-7d7c1b0348e2.jpeg' },
      { title: '果品定位', script: '水果有好坏、大小之分，品种类型也不近相同，实现定位入库，可以在软件中查看每个果品的准确位置', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4f8cf019-d56f-448c-8ad7-92190df3c43f.jpeg' },
      { title: '个人中心', script: '软件的快捷性和重复使用性是增加用户的粘性的关键，浏览过和收藏过的信息我们统一汇总在这里从而让用户快速找到自己想要的内容，进一步提升软件的易使用性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1ea9f3c8-ac2e-429c-9b92-44b01c19d306.jpeg' },
      { title: '库存结算', script: '订单的自己计算和数据汇总，是库存类软件的核心，它可以省去大量的人力核算，提高工作效率', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5433f960-169d-4cee-803f-3b298af02279.jpeg' },
      { title: '员工签到', script: '员工签到，办公OA我们在实现库存管理的基础上同步实现，简化厂区的人员管理', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2cf970aa-c2f7-47e2-adb3-8735ad58fbad.png' },
      { title: '物料账目', script: '物料的使用和账目核算同步进行，达到了一个品种一种物料，帮助厂区做到了精准核算账目', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a77f2220-4c69-4b65-9c52-7e5a219260eb.png' },
      { title: '库房收存', script: '收存是入库的非必要前提，但也是重要组成部分，简化有秩序的收存逻辑是提供厂区员工工作效率的核心', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/afc52c8c-d367-4354-b5cf-dbe3ca7d1bdc.png' }],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          '根据我们客户的指南，该应用程序支持不同的屏幕尺寸和智能手机。',
          '用户可以使用其唯一的密码登录，保证安全性。 ',
          '简果应用程序的流程比较复杂。从用户的角度和流畅的体验，我们用Kotlin协同程序的并发和异步编程。'],
        items: [
          { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: 'Vue.js' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '地图', keywords: '高德地图导航和位置服务解决方案' },
          { title: '角色权限', keywords: '多端登录，相当于六个app' },
          { title: '蓝牙打印', keywords: '远程打印技术' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
      },
      {
        title: '设计',
        script: ['通过设计来满足需求，与客户沟通，从用户角度发现问题，解决软件界面各方面的痛点，使其更加合理、流畅、易用。',
          '结合整个产品公司定位，价值观等，根据公司设计指导准则使产品具有完整性，一致性，易操作。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' },
          { title: '建议与参考', keywords: '合作' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/715d1fea-9f04-4c23-8fc8-4d3f6303d49e.jpeg',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已完成并且上线，可在苹果商场搜索下载使用'
      }, {
        title: '移动安卓APP',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机应用，在华为应用商城，小米应用商城等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
    ]
  },
  {
    name: '天安人寿',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6965b313-a792-4f8f-9d54-9dc13c8209bf.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b1e55207-34bd-4383-8dad-cc379624e53a.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/75f3314b-7f76-41f3-98b5-25586e5eeb48.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/80c8f2d3-cefa-444e-a37b-e5bbe0ee2aaa.jpeg',
    script: '微信公众号在线考试答题，后台管理系统进行查看管理，提高公司的管理效率。',
    brands: [6, 9, 12, 13, 14, 15],
    inCharge: ['产品设计', '微信公众号开发', '后台管理系统', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/adcf7290-7769-4b26-ab4a-f1f14ec85e21.jpeg',
    scope: [
      { icon: 'WeChat', title: '微信公众号开发', script: '我们的目标是为天安人寿开发微信公众平台及后台管理系统。', subTitle: '用户可以：', content: ['选择考试项目', '在线答题', '应聘公司职位', '进行内部人员考试'] },
      { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
      { icon: 'idea', title: '后端开发', script: '基于JSP技术、B/S架构设计的Java应用平台，总体维护成本非常低。', subTitle: '用户可以：', content: ['确保后台管理的安全性、易操作和实用性', '大量数据处理和报表生成，从而提高工作效率', '在程序设计中采用面向对象行的模块设计，便于程序的扩展和二次开发。'] }],
    appContent: [
      {
        itemsTitle: '微信公众号',
        content: [
          { title: '计时答题', script: '采用时间表做倒计时的形式实现答题模块，并且实现试题随机分配，保证题型难度的基础上让每个考生试卷都不相同', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b5d10116-01a3-4139-bfb1-1f7f7e5febbf.jpeg' },
          { title: '账号密码登录', script: '平台为公司私密性项目，所以我们做了制定账号密码登录，保证了项目的安全性和可靠性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1caa173-def9-45f1-8d8c-a75c935686cd.jpeg' },
          { title: '考试科目列表', script: '为保证考试的强提醒，考试之前采用倒计时的形式呈现，可以帮助用户更好的形成记忆', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1d9bbc48-3ed4-4111-9c95-7a0a870a813e.jpeg' },
          { title: '完成考试', script: '成功反馈是操作末端对用户的直接反馈，突出了本次考试的基本信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/75f7ee8f-64b6-46a4-b277-6e3d7e3a3a7d.jpeg' },
          { title: '修改密码', script: '个人密码修改是整个登录的闭环，可以保证个人密码的安全性和保密性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0744c155-6ddc-4bc5-a1c4-0431d3ffc1e8.jpeg' },
          { title: '个人中心', script: '考试信息汇总查看便于统计记录考试', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/66c155ef-fe75-4b59-8c1b-40b4cf24da5a.jpeg' }]
      },
      {
        itemsTitle: '后台管理',
        content: [
          { title: '考试详情', script: '良好的数据展示，简化的便捷操作在PC端更好呈现是我们的预期', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d85bf0d6-52a2-4d76-9e9c-e239ccad5127.png', isBig: true },
          { title: 'PC端考试页', script: '用户可以在PC端直接开始考试，限时答题、自动提交，我们在网络错误和数据错误时都有修正', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4e8b6b4b-b772-4779-bacc-03828ec13d3f.png', isBig: true },
          { title: '应聘人员详情', script: '人员详情同时也是简历文档，帮助HR更好的管理人才档案', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ddbf29b7-f288-4fff-9c5f-7de909c4a888.png', isBig: true },
          { title: '试卷库', script: '您所有的考试都是基于试卷库搭建的，每次考试都可以选择已有的试卷进行创建，更好的简化操作步骤', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/355ec1cb-e85f-4416-8476-2e0bb2f34ce6.png', isBig: true },
          { title: '内部员工测评', script: '人员测评模块包含互评和层级评分，实现了自动分配打分人员和评分统计，同时支持Excel表格导出', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3c8bd011-0f08-4ce6-b0b8-8f75cfaf9e1f.png', isBig: true },
          { title: '考试管理', script: '管理人员创建考试，监督考试进行，分配考试人员等操作都需要在这里进行', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8e53e1e4-59c5-4566-be25-dd9951874322.png', isBig: true },
          { title: '试卷评分', script: '对于判断、选择、填空系统会自动形成评分，但是简答题模块需要分配专业人员打分', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/301356ed-be56-40e0-8b72-dd7104030042.png', isBig: true },
          { title: '新建评分表', script: '评分表的项目和内容都不是固定的，对于每季度和每年都会做出调整，所以我们这里给了用户更多的自由度，可以灵活创建考评表', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/eb56356d-70ce-4932-b7a4-2ea33106e677.png', isBig: true },
          { title: '分配评分任务', script: '组织架构会涉及到到同一人监管多部门情况，应对这种特殊情况在自动分配评分任务基础上可以灵活添加评分人', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8938f13b-e0bc-48aa-831d-76b8f0c29668.png', isBig: true },
          { title: '试卷考题添加', script: '试卷在可灵活编辑的同时考题也需要灵活搭配，不仅支持PC端编辑考题同时也支持Excel表格导入自动识别考题', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4d6e9081-296a-4aec-a2b6-f79f76cec5b1.png', isBig: true }]
      }
    ],
    tech: [
      {
        title: '公众号开发',
        script: ['我们用于公众号开发的解决方案',
          '公众平台以access_token为接口调用凭据，来调用接口，所有接口的调用需要先获取access_token',
          'Ant Design of Vue提炼自企业级中后台产品的交互语言和视觉风格，是一款开箱即用的高质量 Vue 组件。'],
        items: [
          { title: '技术架构', keywords: 'antdesign vue' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: 'Vue.js' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务器' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },]
      },
      {
        title: '设计',
        script: ['我们经验丰富的产品人员会帮您整理项目需求、出思维导图和开发报价单，专业的产品经理梳理需求、出原型图。团队拥有多年开发经验，我们会为您的项目想的更多。',
          '好的设计不仅能帮助粉丝更好的了解解读企业，也能帮助企业树立别具一格的形象。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '规范与创意', keywords: '原型' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/cf12f97e-1132-4249-8d03-2495709e40c1.jpeg',
    serveRange: [
      {
        title: '微信公众号开发',
        img: require('../svg/weixin.svg'),
        script: '在客户公众号开发的一套员工考试系统'
      }, {
        title: 'OA管理系统',
        img: require('../svg/oa-01.svg'),
        script: '为减轻HR工作负担，提高工作效率，定制人员管理，考评系统，考试系统等'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
    ]
  },
  {
    name: '房智汇',
    brands: [1, 2, 6, 12, 13, 14],
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d5a49a0f-2fbd-427a-bfa0-a7ae5e605dd6.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0ed2d1e1-8838-4e3e-a1d5-3427f6a64836.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/460a6c9f-ffb0-4afd-b6cb-00a74b7b7181.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2e48cffe-ca1f-4d4e-ab26-31c27593e030.jpeg',
    script: '多伦比亚房产销售应用，透明的房产行情、高效的找房工具，提供全面真实房源',
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d5cf2c8-cf12-403c-b9d4-b8986ad27798.jpeg',
    scope: [
      { icon: 'ios', title: '移动应用开发', script: '我们的目标是为多伦比亚房产开发ios及Android移动应用程序', subTitle: '用户可以：', content: ['了解行业数据市场动向', '根据个人需求找房', '通过地图快速找房', '查看新闻资讯'] },
      { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '地图找房模块', script: '我们使用高德地图定位技术信息汇总房源信息，为用户提供高效便捷的房源信息，解决了房源位置信息不明确、不直观等需求痛点', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f0bfc66b-4c22-4026-8c08-5c17097d61fc.jpeg' },
      { title: '附近已售相似房屋', script: '为促进用户达成购买，我们通过大数据核算和虚拟算法等核心技术推荐用户感兴趣的房源信息，促使房源成交率提示30%', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a913a7fa-2c00-473a-8421-916ae79f83fb.jpeg' },
      { title: '用户信息', script: '软件的快捷性和重复使用性是增加用户的粘性的关键，浏览过和收藏过的信息我们统一汇总在这里从而让用户快速找到自己想要的内容，进一步提升软件的易使用性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/78237a79-92f7-46cf-8ecb-993799257244.jpeg' },
      { title: '房子的行业数据展示', script: '房屋成交数据、房屋销售数据、市场房价增降是用户购入房源的关键信息，对于复杂的信息如何在软件通俗易懂的呈现给客户是通用难点，我们根据图形数据对于表格数据更敏感，，实现了一整套的实时数据图形表', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/675163c5-e7a2-4e9e-8fea-f1e6b401b829.jpeg' },
      { title: '社区房源', script: '房源信息得地理位置是用户最关心得之一，数据上我们按社区汇总，促使用户更加便利得找到目标房源信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fde149e0-9f1f-4d6d-841b-2926a3599707.jpeg' },
      { title: '推荐社区', script: '智能推荐算法，根据用户浏览的社区房源推荐同类型和相似类型的社区信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/faf4835d-6d8f-4473-a5d7-ba484cfaeeb7.jpeg' },
      { title: '关注房源', script: '软件基本功能更好的体现用户体验，我们会给用户关注的房源做对比并且分析房源信息优劣，从而更好的促使用户交易', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5d43406c-ccbd-406c-9c76-c2c2729d110c.jpeg' },
      { title: '房屋详细信息', script: '房屋信息复杂、繁多，我们通过内部探讨会和数据调查整理出用户最关心的数据展示在移动端，并且保持了高度易读，方便识别', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5e1ede9a-3ef8-4276-8cc5-b63164afc0f5.png' },
      { title: '用户注册', script: '为简化用户登录步骤，我们做了登录注册调整，保留原登录方式基础上，增加了快捷登录', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/50917bbb-5d0e-49db-ab88-4530c828d167.png' },
      { title: '筛选房源', script: '对于房源的庞大数据，用户怎么更快的找到自己目标房源，我们不仅有社区、附近等大推荐模块，对于房源价格区间、户型等具体信息用户也可搜索查询', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2b9f9a51-21c4-4baf-9f62-e385a2c815dc.png' }],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          '我们仅使用几个库构建了iOS应用。对于网络，重新查询服务器或整理行业数据统计等大多数功能，我们创建了自己的本机解决方案。',
          '由于iOS平台的特性，以这种方式实现它们比使用外部库更容易，更快。它为我们提供了100％的灵活性，并允许我们精确地创建所需的功能。',
          '应客户的要求，我们以地图找房的形式创建了一种高效的搜索形式。'],
        items: [{ title: '技术架构', keywords: '自研架构框架，高内聚，低耦合' },
        { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
        { title: '框架', keywords: 'Vue.js' },
        { title: '云服务', keywords: '基于阿里linux系统的云服务' },
        { title: '地图', keywords: '高德地图导航和位置服务解决方案' },
        { title: '多语言切换', keywords: '支持多语言' },
        { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
      },
      {
        title: '设计',
        script: ['定制化app设计增强用户粘性，圈留住老客户，让他们成为您最忠实的口碑传播者，无形中提高用户的口碑。',
          '采用图文并茂、形象生动的广告表现形式，降低营销成本。',
          '基于地理位置，为用户推送房源信息，能链接电话，短信，使用更便捷。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2348f933-4226-40ba-81d0-94b61d964b4b.jpeg',
    serveRange: [
      {
        title: '苹果iOS APP',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端的开发已完成并且上线，可在苹果商场搜索下载使用'
      }, {
        title: '移动安卓APP',
        img: require('../svg/android.svg'),
        script: '适用于所有安卓系统手机的应用，在华为应用商城，小米应用商城等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
    ]
  },
  {
    name: '子曰',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/266ef9a5-657b-4c19-9d72-254d015f8c8c.jpeg?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1ac5d48-31a5-47de-8cdf-97b2dd633663.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1bd1c536-1dd7-49da-bb48-310a737ee245.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c3ff32ce-c135-49be-9d8a-9c0faee4ab7e.jpeg',
    script: '用于教学的在线朗读应用，让您高效学习，快乐学习。',
    brands: [3, 4, 6, 12, 13, 14],
    inCharge: ['产品设计', 'ipad交互设计', 'ipad应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/90c1ea15-8106-4817-9563-7b51a630e548.jpeg',
    scope: [
      { icon: 'ipad', title: 'iPad应用开发', script: '我们的目标是开发一款在线朗读的iPad应用程序。', subTitle: '用户可以：', content: ['选择自己喜欢的书籍', '收听内容轻松学习', '全屏查看书籍内容', '选择自己喜欢的朗读版本', '标记书籍内容'] },
      { icon: 'devise', title: 'iPad交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – 后台程序和前端程序都有符合需求的丰富功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '启动页面', script: '子曰是一款教学类应用程序，使用多媒体课件可以实现陪读、领读等模式，减轻教师负担', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6cf178dc-d885-410a-8d15-5efe28c2c077.png', isBig: true },
      { title: '经典书架', script: '书架陈列的数据是PC端编辑好，下载即可使用的课件，同时对最近播放的课件和数据记录', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/df6a0eaa-415e-44cc-9177-fa97dbd4eebb.png', isBig: true },
      { title: '内容选择', script: '自由的选择所播放的段落，并且可配置多个音频依次播放', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3f2ec347-01dc-4f44-8cf7-9f727213c084.png', isBig: true },
      { title: '播程选择', script: '播程既是当前书籍的音频文件，可以选择言或着语等播放方式，层次架构实现语音和文字同步，并且在按言、语等播放的停顿', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/edce4a81-823e-4dbe-9792-9f989c76534c.png', isBig: true },
      { title: '设置', script: '实际使用中陪读的音量大小需求不同，音量的自动设置调节帮助教师更好操控', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7ae2ac7c-8cb8-4de5-9f77-f2477f5e519f.png', isBig: true },
      { title: '播放模式', script: '多个播程依次播放的同时，快进和后退也会根据当前选择的播放模式按章、语、句等模式调整', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/519eb204-d309-4839-be9c-df05247f0409.png', isBig: true },
      { title: '专注模式', script: '全屏播放模式是投影在多媒体上更好的展现方式，使学生可以更好的专注当前所读的内容', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a12632b8-59b5-480e-bce3-69bd6054cbd4.png', isBig: true }],
    tech: [
      {
        title: 'iPad开发',
        script: ['我们用于iPad开发的解决方案',
          '我们提供了多个通信和跟踪渠道，这使整个过程非常高效。我们保持频繁的更新频率，保证各个方面的透明性，并顺利地组织了依赖性，这使我们能够完全控制该项目，有助于我们了解其性能。',
          '我们选择Assertk,Mockito进行多次测试，使用RxSwift编程，保证代码的耐用性使用户拥有流畅的体验。'],
        items: [
          { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: 'Vue.js' },
          { title: '音频编辑', keywords: 'howler、wavesurfer' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },]
      },
      {
        title: '设计',
        script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
          '为了让用户高效学习，我们新增了—专注板块，客户对此非常满意。'],
        items: [{ title: '沟通与分析', keywords: '需求' },
        { title: '线稿与设计', keywords: '交互' },
        { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c428a9c1-120f-4842-8dcf-608b3b63decc.jpeg',
    serveRange: [
      {
        title: '苹果iOS iPad',
        img: require('../svg/ios.svg'),
        script: '我们负责ios端ipad的开发和交互体验设计，帮助提供用户使用率'
      }, {
        title: '移动安卓平板',
        img: require('../svg/android.svg'),
        script: '运行于所有安卓系统平板的应用，在华为应用商城，小米应用商城等均可下载'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合APP使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
    ]
  },
  {
    name: '沃特德饮水机',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/34f72973-6d3f-4abc-9923-0a13b9516bdd.jpeg?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8b6f0dee-ae9a-4e8a-a272-7c647a6e5174.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3bf61ee4-b7db-4342-9ef6-c8cc629fcbb7.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1b5d2024-afab-4f1d-a3a9-ed1b9d340d5f.jpeg',
    script: '软硬件结合，手机端直观操作智能水机系统。',
    brands: [1, 2, 6, 7, 9, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ec323865-a25b-4dce-8315-c6422b2e6c04.jpeg',
    scope: [
      { icon: 'ios', title: '移动应用开发', script: '我们的目标是为沃特德开发iOS和Android移动商务应用程序。', subTitle: '用户可以：', content: ['查看水机水质水量等信息', '获取水机分布情况', '了解滤芯情况适时更换', '对经销商进行管理'] },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '水机设置', script: '软硬件结合及时通信是关键，云服务数据的及时传输帮助软件可以随时监管设置水机信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/30f168cb-bab7-4926-b24a-84e844ecdff4.jpeg' },
      { title: '水机详情', script: '管理员可以查看水机位置信息及使用情况包括水位、水质等详情，方便用户及时更换反馈', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f85e4b5c-3175-4e9a-a400-289ea8b1ba4e.jpeg' },
      { title: '经销商端', script: '经销商权限是由管理端统一分配，数据判断处理只能查看自己名下的水机信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/27e5cdaf-72a0-4ef1-b886-1581be7bbb36.jpeg' },
      { title: '滤芯状态', script: '及时通讯芯片帮助软件及时获取水机滤芯状态，软件警告提醒管理员，及时更换滤芯', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7301b90d-461a-4a77-86be-e1d64e7df841.jpeg' },
      { title: '用水量统计', script: '水机数据核算部分我们采用真实数据统计核算，可以及时监控水机的盈利状态', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3e400ef0-221a-4692-a18a-fd4cfb521404.jpeg' },
      { title: '租赁设置', script: '因各个城市的消费水平的不同，对于租用水机的服务商，单独可以设置水机出水量、价格等配置', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5b3690f8-79bc-493c-91d8-e28d532c5977.jpeg' },
      { title: '管理员端', script: '管理员对当前水机和经销商进行管理，便于查找我们做了层级划分，管理员可根据经销商查看水机信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4a976b42-a31b-4fc9-ac94-8ca7b7985138.jpeg' },
      { title: '运行时间设置', script: '除了在水机硬件调整运行时间，我们还实现了在APP远程设置运行时间，帮助管理员操控水机', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/586db8b6-4491-4339-ad2d-74b65c2b74f8.png' },
      { title: '水机报修', script: '硬件监控可能不尽完善，为了确保终端的正常使用，应用实时通讯技术让用户可以把问题直接反馈给管理者', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/46bc64dc-9780-4fd8-a0a5-a2e6165dfe99.png' },
      { title: '维修记录', script: '维修记录的统计是平台维护机器和记录机器运行状态的重要信息，可促使机器测试跟易发问题的更新，提高硬件的质量', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2ca7bddd-dd13-4c65-afec-ac83a3284a48.png' }],
    tech: [
      {
        title: '移动开发',
        script: ['我们用于APP开发的解决方案',
          '用户可以使用其唯一的密码登录，保证其安全性。',
          '我们选择了SnapKit布局，这是Google推荐的一种布局方式。它帮助我们使UI设计保持简单且规范。该布局高质量且易于管理。',
          '从用户的角度和流畅的体验，我们用MixPanel的分析工具和Kotlin协程，更好的完成项目。'],
        items: [
          { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '框架', keywords: 'Vue.js' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '物联网', keywords: '新一代网络智能售水机' },
          { title: '微信开发', keywords: '公众号、小程序' },
          { title: '中台', keywords: '提供完善的企业管理能力' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
      },
      {
        title: '设计',
        script: ['该应用具有友好的交互式UI，可让用户更好地了解水机情况从而高效管理水机。由于硬件提供商和软件开发团队之间的顺利合作，我们提供了最佳的更新体验。',
          '严谨的交互逻辑，保证用户在使用时感觉流畅并能熟练使用该应用。'],
        items: [{ title: '沟通与分析', keywords: '需求' },
        { title: '线稿与设计', keywords: '交互' },
        { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/76419d3b-e68d-45f7-bdbc-56b0d314175f.jpeg',
    serveRange: [
      {
        title: '微信公众号开发',
        img: require('../svg/weixin.svg'),
        script: '帮助客户在所拥有的公众号搭建水机管理、在线售水、远程操作等功能'
      }, {
        title: '微信小程序开发',
        img: require('../svg/xiao.svg'),
        script: '为便于用户使用，后续开发完成了小程序端、跟公众号的数据所同步使用'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合公众号、小程序管理使用的PC后台，管理商家、水机、数据报表统计等'
      }
    ],
    addServe: [
      '智能设备开发', '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
    ]
  },
  {
    name: '乡镇纪委监督平台',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/925694a4-99fb-40fe-b717-fc89768d966d.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/55ee1a1f-39ef-4e00-a37c-09d5a7881b58.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9941b85e-1142-46f6-94f3-05d30a4c6542.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1b5d2024-afab-4f1d-a3a9-ed1b9d340d5f.jpeg',
    script: '使用数字可视化的技术，统计区域的待办事项。查看事物的线索来源，对区域进行科学严谨的管理，可以及时的处理区域的待办事项。',
    brands: [1, 2, 6, 7, 9, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'WEB交互设计', '小程序设计', 'UI界面设计', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d88b1dfd-aa23-472f-b3bc-8ddf91b5015f.png',
    scope: [
      { icon: 'ios', title: '移动应用开发', script: '我们的目标是为沃特德开发iOS和Android移动商务应用程序。', subTitle: '用户可以：', content: ['查看水机水质水量等信息', '获取水机分布情况', '了解滤芯情况适时更换', '对经销商进行管理'] },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      {
        itemsTitle: '大屏',
        content: [
          { title: '数据总览', script: '系统会统计未受理、办理中、已办结、信访统计、反馈统计、廉情统计的信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/df9ced29-b842-4bb9-a1a3-1601b23cf933.png' },
          { title: '综合监督', script: '系统汇总监督统计信息、同级监督、村级监督、执法监督、审批服务。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0db2f883-9221-4426-82a8-1b2a9dfbdfd7.png' },
          { title: '邢台乡镇大屏', script: '以区域地图显示乡镇信息，点击某个区域可以看到该区域的线索总数、待办理数量、办理中数量、已办理数量。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/82b03cad-1a1a-432e-a5f0-3e52390de281.png' }]
      },
      {
        itemsTitle: '小程序',
        content: [
          { title: '首页', script: '用户登录小程序，可以进行信访预约、信访举报、查看区域发表的动态文章。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/11b5b62b-48b5-4cb3-b2a0-1d51b8487a5c.png' },
          { title: '信访预约', script: '线上填写信访预约，直达信访中心，预约成功将优先接待。使信访更加方便快捷。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0a7b252d-1962-48fe-8415-a56da11abfd9.png' },
          { title: '我的信访预约', script: '查看用户的信访记录，信访的状态已通过、未通过。方便用户进行记录。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53182306-34c0-4ebc-a357-d287f91bcfbd.png' },
          { title: '实名举报', script: '用户可进行实名举报、匿名举报。填写完举报内容，可直接反馈给地方政府。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9f7160ff-bab9-4953-91c2-4d8502d7d150.png' },
          { title: '举报查询', script: '用户可以在此位置查看举报的内容。举报处理的情况，待受理、已转办。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/869fe36e-41e7-4d9e-96ff-00a8ce336acb.png' },
          { title: '个人中心', script: '汇集用户基本信息，基本功能的集合。如我的信访预约、我的举报查询等。便于用户进行操作。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c003b4ed-0f4b-472a-83ba-adbba99f5092.png' },
        ]
      }
    ],
    tech: [
      {
        title: '网页开发',
        script: [
          '我们用于网页开发的解决方案',
          '我们选择了MVVM，这是Google推荐的一种架构模式。它使开发者可以不去操作繁杂的DOM元素，只专注Model的变化，让MVVM框架去自动更新DOM的状态。',
          '为了使数据更直观生动，我们选择了Apache ECharts库渲染数据。ECharts是一款基于JavaScript的数据可视化图表库，涵盖各行业图表，可满足各种需求',
          ],
        items: [
          { title: '框架', keywords: 'VUE' },
          { title: '技术框架', keywords: 'Apache ECharts，自研架构框架' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },]
      },
      {
        title: '设计',
        script: ['我们经验丰富的产品人员会帮您整理项目需求、出思维导图和开发报价单，专业的产品经理梳理需求、出原型图。团队拥有多年开发经验，我们会为您的项目想的更多。',
          '好的设计不仅能帮助粉丝更好的了解解读企业，也能帮助企业树立别具一格的形象。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '规范与创意', keywords: '原型' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e32eafd1-d1f3-48bc-9a12-5ad4035e00ea.png',
    serveRange: [
      {
        title: '微信小程序',
        img: require('../svg/weixin.svg'),
        script: '我们开发微信小程序。使用户可以通过小程序进行信访举报'
      }, {
        title: 'WEB网页',
        img: require('../svg/web.png'),
        script: '配合小程序使用的数据可视化展示界面，清楚的看到平台数据的汇总'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合微信小程序使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI界面设计', '服务器运维'
    ]
  },
  {
    name: '限额交易系统',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5495fc8a-61af-445b-8463-216f133d5ad4.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b3413908-f88b-4a8a-b94d-6692a710ea39.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a2200634-0a65-4741-bae8-3c3c69929a93.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1b5d2024-afab-4f1d-a3a9-ed1b9d340d5f.jpeg',
    script: '限额交易系统，发布方在后台发布交易的项目。响应方可在WEB页面/手机公众号上查看相应的项目进行响应。资源交易中心审核通过后，交易成功。',
    brands: [1, 2, 6, 7, 9, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', 'WEB页面设计', '公众号开发', 'UI页面设计', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/76c83fa4-00f4-4757-a38c-0306b784ef8b.png',
    scope: [
      { icon: 'ios', title: '移动应用开发', script: '我们的目标是为沃特德开发iOS和Android移动商务应用程序。', subTitle: '用户可以：', content: ['查看水机水质水量等信息', '获取水机分布情况', '了解滤芯情况适时更换', '对经销商进行管理'] },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '限额交易系统', script: '用户登录限额交易系统，可以查看公告列表里正在交易项目、查看资讯动态、政策法规等信息。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/66526f7e-329f-4bd6-8693-adf3144b99c3.png' },
      { title: 'CA信息', script: '用户可在个人中心，查看CA的状态已激活/未激活。可在此页面再次购买CA。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f1346ce0-eb2b-4ff5-9df7-25dab0562a5b.png' },
      { title: '冻结申诉', script: '机构在响应过程中，因某些行为导致账号冻结，可以在此页面填写上传相关证明，进行申诉。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/25dc57dd-6b4d-457c-b5b9-02a694730d94.png' },
      { title: '开具发票', script: '机构积累一定的金额可以申请开具发票，发票类型分为企业单位和个人。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6b6c7db5-3fe6-4940-84ab-17fa5b949cb0.png' },
      { title: '收货地址', script: '收货地址用于，机构购买CA后，收取CA的地址及联系方式，可以随时修改地址。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b9953da9-ed76-4b98-9a38-1d4457e7ad05.png' },
      { title: '变更公告', script: '机构可以进行对比查看变更公告，变更的内容，修改的地方会用蓝色进行区分。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0ba0224e-cc38-414c-97d0-165654017591.png' },
      { title: '机构列表', script: '显示平台所有注册机构的信息，通过对接第三方可直接获取的机构最新的信息。', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/bfad7132-d662-4e5a-9313-056c56f6330a.png' },
    ],
    tech: [
      {
        title: '网页开发',
        script: [
          '我们用于网页开发的解决方案',
          '我们选择了MVVM，这是Google推荐的一种架构模式。它使开发者可以不去操作繁杂的DOM元素，只专注Model的变化，让MVVM框架去自动更新DOM的状态。',
          'Ant Design of Vue提炼自企业级中后台产品的交互语言和视觉风格，是一款开箱即用的高质量 Vue 组件。',
          ],
        items: [
          { title: '框架', keywords: 'VUE' },
          { title: '技术框架', keywords: 'antdesign vue' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },
          { title: '在线支付', keywords: '微信支付' }]
      },
      {
        title: '设计',
        script: ['我们经验丰富的产品人员会帮您整理项目需求、出思维导图和开发报价单，专业的产品经理梳理需求、出原型图。团队拥有多年开发经验，我们会为您的项目想的更多。',
          '好的设计不仅能帮助粉丝更好的了解解读企业，也能帮助企业树立别具一格的形象。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '规范与创意', keywords: '原型' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9f75cc5f-abfb-48f3-89ad-4f7d9dfa8e30.png',
    serveRange: [
      {
        title: '微信公众号开发',
        img: require('../svg/weixin.svg'),
        script: '在客户公众号开发推送通知。使用户能够及时的获取到消息'
      }, {
        title: 'WEB网页',
        img: require('../svg/web.png'),
        script: '响应方在WEB页面查看发布的项目，对相应的项目进行响应'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合WEB页面使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI界面设计', '服务器运维'
    ]
  },
  {
    name: '南和公园导览',
    icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/99f2e574-af76-49bc-a5ac-15ca55821746.png?x-oss-process=image/resize,w_68,h_68',
    headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b2a05988-9e64-4c0c-996b-33126b2982e3.png?x-oss-process=image/resize,w_600',
    headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/392d5243-9061-4c69-b45d-6cc2eb143432.png',
    mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1b5d2024-afab-4f1d-a3a9-ed1b9d340d5f.jpeg',
    script: '公园建设坚持“以人为本、回归自然”理念，注重城市文化相结合，依托废弃窑坑就势建园。并充分挖掘区域悠久的历史文化，分类建设人文景观。',
    brands: [1, 2, 6, 7, 9, 11, 12, 13, 14, 15],
    inCharge: ['产品设计', '交互设计', '公园设备开发', 'UI页面设计', '后端开发'],
    banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d2a5c820-6481-4a53-a28f-93c4387dcd53.png',
    scope: [
      { icon: 'ios', title: '移动应用开发', script: '我们的目标是为沃特德开发iOS和Android移动商务应用程序。', subTitle: '用户可以：', content: ['查看水机水质水量等信息', '获取水机分布情况', '了解滤芯情况适时更换', '对经销商进行管理'] },
      {
        icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
        content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
      },
      {
        icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
        content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
      }
    ],
    appContent: [
      { title: '公园导览', script: '显示公园的基本简介，可查看景点的位置，查看VR全景图像、天气预览等信息。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b97ed831-a50c-4e80-b3e6-360dd269db36.png' },
      { title: '景点详情', script: '点击查看景点的信息，会伴随着语音播报，介绍该进景点对应的风景信息。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/254351a5-a043-4bf6-ad9c-14eae46b8d8f.png' },
      { title: '路线导航', script: '系统根据游客的当前位置信息和要去的景点规划一条最优路线，游客可以根据路线到达要去的目的地。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/50f1e3bc-dd58-42de-9a90-caafde3d4f3a.png' },
      { title: 'VR全景图', script: '用户可以查看公园的VR全景图，可通过滑动的方式查看公园周围的美景。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7e351621-67f0-4c41-96e4-81743d624365.png' },
      { title: '全部景点', script: '显示公园全部景点信息，点击会有相对应的语音播报。游客可以查看该地区的美景。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e85e8be6-ffaf-4179-b512-349734a24ca7.png' },
      { title: '天气预览', script: '游客可以查看当天的天气情况，不同的天气还会给出不同的温馨提示。 ', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2c74d4f0-e7b7-4ac1-b562-7d1631e94cef.png' },
    ],
    tech: [
      {
        title: '网页开发',
        script: [
          '我们用于网页开发的解决方案',
          '我们选择了MVVM，这是Google推荐的一种架构模式。它使开发者可以不去操作繁杂的DOM元素，只专注Model的变化，让MVVM框架去自动更新DOM的状态。',
          '从用户的角度和流畅的体验，我们用MixPanel的分析工具和Kotlin协程，更好的完成项目。',
          ],
        items: [
          { title: '框架', keywords: 'VUE' },
          { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
          { title: '云服务', keywords: '基于阿里linux系统的云服务' },
          { title: '开发方式', keywords: 'H5 + 原生混合开发' },
          { title: '异步编程', keywords: 'Kotlin协程，RxSwift' },
          { title: '分析工具', keywords: 'MixPanel，Instabug' },]
      },
      {
        title: '设计',
        script: ['我们经验丰富的产品人员会帮您整理项目需求、出思维导图和开发报价单，专业的产品经理梳理需求、出原型图。团队拥有多年开发经验，我们会为您的项目想的更多。',
          '好的设计不仅能帮助粉丝更好的了解解读企业，也能帮助企业树立别具一格的形象。'],
        items: [
          { title: '沟通与分析', keywords: '需求' },
          { title: '线稿与设计', keywords: '交互' },
          { title: '规范与创意', keywords: '原型' },
          { title: '制作与细节', keywords: '视觉' }]
      }
    ],
    motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/31866b38-9495-468a-a989-afcc37a38705.png',
    serveRange: [
      {
        title: '公园导览开发',
        img: require('../svg/weixin.svg'),
        script: '游客到公园大屏查看公园的景点、路线。可导航到想要到达的位置。'
      }, {
        title: 'WEB网页',
        img: require('../svg/web.png'),
        script: '游客在查看公园的景点介绍，可语音播报景点的内容信息。'
      }, {
        title: '数据管理后台',
        img: require('../svg/moke.svg'),
        script: '配合公园导览使用的软件管理后台也投入使用，查看软件数据、管理用户数据'
      }
    ],
    addServe: [
      '应用图标设计', '产品原型设计', 'UI界面设计', '服务器运维'
    ]
  },
]
// export default [
//   // 【小万生活0，智赛桥牌1，沃特德饮水机2，领航练车3，子曰4，房智汇5，简果6，天安人寿7】//旧版
//   // 改版： 【0. HIRO 1. 车辆管理系统 2.古力古利 3. 隆尧 4. 威选商城 5. 清博教育 6. 沃特德饮水机 7.有光万物 8.TATTOOLS 9.小万生活 10.简果 11. 天安人寿 12. 房智汇 13. 子曰 14. 沃特德 15. 乡镇纪委监督平台】
//   {
//     name: '小萬生活',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d762e23-5db9-4a57-8888-88696f1dcd55.png?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fff51b75-0c18-4a8c-a7e5-469f47320a62.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ce632733-2f3d-4430-b250-91137f787ded.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
//     script: '本地化服务商，您可以在上面体验所在地的权益和服务',
//     brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
//     process: [
//       {
//         card_title: '第一期（√）',
//         card_script: '本期实现：o2o商场、商家模块、会员分享购买，达到用户基本使用功能使用',
//         time: '2019年3月8日上线',
//         title: '超1年',
//         text: '总开发时长'
//       }, {
//         card_title: '第二期（√）',
//         card_script: '本期实现：短视频模块、用户分润、智能洗车机、话费充值等优化用户体验功能',
//         time: '2019年10月19日上线',
//         title: '12名',
//         text: '开发人员'
//       }, {
//         card_title: '第三期（进行中）',
//         selected: true,
//         card_script: '本期预计实现：直播模块、社区社交模块、快递配送、会员分销逻辑修改等功能',
//         time: '预计2020年2月14日',
//         title: '132个',
//         text: '更新版本'
//       }, {
//         card_title: '第四期（设计阶段）',
//         card_script: '需求功能设计：项目众筹、公益众筹、在线聊天、消息实时推送，优化直播模块等',
//         time: '预计2020年7月5日',
//         title: '319张',
//         text: 'UI设计图'
//       }, {
//         card_title: '第五期（未开始）',
//         card_script: '对于核心平台支付，对接支付宝蚂蚁信用。做自有支付平台和信用贷款等金融模块',
//         time: '预计2020年12月13日',
//         title: '743个',
//         text: '问题沟通并解决'
//       }
//     ],
//     inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8476b203-b0b3-4ac2-9c74-1fa0c4652e9c.jpeg',
//     scope: [
//       {
//         icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
//         content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
//       },
//       {
//         icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
//         content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
//       },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '商家店铺详情', script: '商家店铺的一些点评和环境是用户所关心，环境方面除了常规图片展示还配合了VR浏览，点评系统会根据每次用户消费点评计算而来，使数据更可信', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5dce0c77-4b85-42c3-b752-75698c3ec3b7.jpeg' },
//       { title: '用户购买会员', script: '用户开通金钻会员、蓝钻会员可享受商家的免费定制服务，添加推荐人，共享积分奖励', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0975fc35-bde3-419c-b01e-fa0a8510cd7d.jpeg' },
//       { title: '订单结算', script: '为便于促成交易，在自有的交易平台的基础上，同步支持支付宝、微信等快捷交易', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c24f1d7f-e4dd-4a61-a638-988e2364c348.jpeg' },
//       { title: '商家的权益优惠', script: '商家权益是商家为小萬会员用户提供的优惠权益，会员可享免费定制权益和服务', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/78739412-ef2d-41aa-9cd1-d41d348a9841.jpeg' },
//       { title: '小萬生活首页', script: '根据定位查找附近的店铺优惠和权益活动，同时支持本地化水电费、燃气费充值和本地资讯等生活服务内容', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/74c4d86a-a073-4d16-9450-138af453b3e2.jpeg' },
//       { title: '生活缴费', script: '基于第三方，在此应用上实现了本地化生活服务，用户可以享受到足不出户的便利', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/462d28cb-376b-4e35-ab19-8ae2d9da15d5.jpeg' },
//       { title: '社区动态发现', script: '视频传播和优质化内容是社区发展的本质，在技术上规避不良信息，根据核心算法实现优质内容推送，增加用户的对于APP的粘性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8491a063-c754-411a-9fcc-8bf938e0ae3e.jpeg' },
//       { title: '商家VIP购买', script: '为促进商家优质性和竞争性和平台的变现能力，对于商家开通VIP入驻等服务', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/43d8b10e-7946-4405-9ee4-0b065319571b.png' },
//       { title: '商家中心', script: '商品服务平台是为了商家便于操作编辑店铺信息搭建的，使商家便于操作更新店铺商品和查看订单', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/752efcb9-4ca1-4e67-bd7a-c30ff57b1c9f.png' },
//       { title: '个人中心', script: '用户中心集合了个人信息管理，平台活动参加，团队创建等多种功能，数据整理和信息分发易于用户的使用', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6d51fbde-f135-405c-b7d8-5ccd4310244a.png' }],
//     tech: [
//       {
//         title: '移动开发',
//         script: ['我们用于APP开发的解决方案',
//           '我们选择了MVVM，这是Google推荐的一种架构模式。它帮助我们使UI代码保持简单且无应用逻辑。该代码健壮，高质量且易于管理。',
//           '我们决定使用诸如Realm或Texture之类的高效工具  ，分别覆盖数据库通知和UI。此外，我们必须成为线程处理方面的专家，并发明自己的Queue Queue，这是此用例的出色数据结构。'],
//         items: [
//           { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '云服务', keywords: '基于阿里linux系统的云服务' },
//           { title: '地图', keywords: '高德导航和位置服务解决方案' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' },
//           { title: '支付方式', keywords: '微信/支付宝' },]
//       },
//       {
//         title: '设计',
//         script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
//           '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
//         items: [
//           { title: '沟通与分析', keywords: '需求' },
//           { title: '线稿与设计', keywords: '交互' },
//           { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/465817db-6626-4173-a6e0-3ff7716d5cdd.jpeg',
//     serveRange: [
//       {
//         title: '苹果iOS APP',
//         img: require('../svg/ios.svg'),
//         script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
//       }, {
//         title: '移动安卓App',
//         img: require('../svg/android.svg'),
//         script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
//       }
//     ],
//     addServe: [
//       '智能设备开发', '微信小程序开发', '应用图标设计', '产品原型设计', 'UI原型设计', '微信推广活动', '服务器运维'
//     ]
//   },
//   {
//     name: '小萬生活',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d762e23-5db9-4a57-8888-88696f1dcd55.png?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fff51b75-0c18-4a8c-a7e5-469f47320a62.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ce632733-2f3d-4430-b250-91137f787ded.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/53e59951-4a52-4a42-82b2-f4a85775f29f.jpeg',
//     script: '本地化服务商，您可以在上面体验所在地的权益和服务',
//     brands: [1, 2, 6, 8, 10, 11, 12, 13, 14, 15],
//     process: [
//       {
//         card_title: '第一期（√）',
//         card_script: '本期实现：o2o商场、商家模块、会员分享购买，达到用户基本使用功能使用',
//         time: '2019年3月8日上线',
//         title: '超1年',
//         text: '总开发时长'
//       }, {
//         card_title: '第二期（√）',
//         card_script: '本期实现：短视频模块、用户分润、智能洗车机、话费充值等优化用户体验功能',
//         time: '2019年10月19日上线',
//         title: '12名',
//         text: '开发人员'
//       }, {
//         card_title: '第三期（进行中）',
//         selected: true,
//         card_script: '本期预计实现：直播模块、社区社交模块、快递配送、会员分销逻辑修改等功能',
//         time: '预计2020年2月14日',
//         title: '132个',
//         text: '更新版本'
//       }, {
//         card_title: '第四期（设计阶段）',
//         card_script: '需求功能设计：项目众筹、公益众筹、在线聊天、消息实时推送，优化直播模块等',
//         time: '预计2020年7月5日',
//         title: '319张',
//         text: 'UI设计图'
//       }, {
//         card_title: '第五期（未开始）',
//         card_script: '对于核心平台支付，对接支付宝蚂蚁信用。做自有支付平台和信用贷款等金融模块',
//         time: '预计2020年12月13日',
//         title: '743个',
//         text: '问题沟通并解决'
//       }
//     ],
//     inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8476b203-b0b3-4ac2-9c74-1fa0c4652e9c.jpeg',
//     scope: [
//       {
//         icon: 'ios', title: '移动应用开发', script: '我们的目标是为小萬生活开发iOS和Android移动商务应用程序。', subTitle: '用户可以：',
//         content: ['享受商家优质服务', '成为商家出售权益和商品', '发布动态与网友评论互动', '赚取萬赢币抵扣现金', '参与小萬活动']
//       },
//       {
//         icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
//         content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
//       },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '商家店铺详情', script: '商家店铺的一些点评和环境是用户所关心，环境方面除了常规图片展示还配合了VR浏览，点评系统会根据每次用户消费点评计算而来，使数据更可信', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5dce0c77-4b85-42c3-b752-75698c3ec3b7.jpeg' },
//       { title: '用户购买会员', script: '用户开通金钻会员、蓝钻会员可享受商家的免费定制服务，添加推荐人，共享积分奖励', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0975fc35-bde3-419c-b01e-fa0a8510cd7d.jpeg' },
//       { title: '订单结算', script: '为便于促成交易，在自有的交易平台的基础上，同步支持支付宝、微信等快捷交易', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c24f1d7f-e4dd-4a61-a638-988e2364c348.jpeg' },
//       { title: '商家的权益优惠', script: '商家权益是商家为小萬会员用户提供的优惠权益，会员可享免费定制权益和服务', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/78739412-ef2d-41aa-9cd1-d41d348a9841.jpeg' },
//       { title: '小萬生活首页', script: '根据定位查找附近的店铺优惠和权益活动，同时支持本地化水电费、燃气费充值和本地资讯等生活服务内容', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/74c4d86a-a073-4d16-9450-138af453b3e2.jpeg' },
//       { title: '生活缴费', script: '基于第三方，在此应用上实现了本地化生活服务，用户可以享受到足不出户的便利', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/462d28cb-376b-4e35-ab19-8ae2d9da15d5.jpeg' },
//       { title: '社区动态发现', script: '视频传播和优质化内容是社区发展的本质，在技术上规避不良信息，根据核心算法实现优质内容推送，增加用户的对于APP的粘性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8491a063-c754-411a-9fcc-8bf938e0ae3e.jpeg' },
//       { title: '商家VIP购买', script: '为促进商家优质性和竞争性和平台的变现能力，对于商家开通VIP入驻等服务', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/43d8b10e-7946-4405-9ee4-0b065319571b.png' },
//       { title: '商家中心', script: '商品服务平台是为了商家便于操作编辑店铺信息搭建的，使商家便于操作更新店铺商品和查看订单', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/752efcb9-4ca1-4e67-bd7a-c30ff57b1c9f.png' },
//       { title: '个人中心', script: '用户中心集合了个人信息管理，平台活动参加，团队创建等多种功能，数据整理和信息分发易于用户的使用', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6d51fbde-f135-405c-b7d8-5ccd4310244a.png' }],
//     tech: [
//       {
//         title: '移动开发',
//         script: ['我们用于APP开发的解决方案',
//           '我们选择了MVVM，这是Google推荐的一种架构模式。它帮助我们使UI代码保持简单且无应用逻辑。该代码健壮，高质量且易于管理。',
//           '我们决定使用诸如Realm或Texture之类的高效工具  ，分别覆盖数据库通知和UI。此外，我们必须成为线程处理方面的专家，并发明自己的Queue Queue，这是此用例的出色数据结构。'],
//         items: [
//           { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '云服务', keywords: '基于阿里linux系统的云服务' },
//           { title: '地图', keywords: '高德导航和位置服务解决方案' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' },
//           { title: '支付方式', keywords: '微信/支付宝' },]
//       },
//       {
//         title: '设计',
//         script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
//           '遵循UI设计的当前趋势，我们创建了外观和感觉都很好的现代应用平台。'],
//         items: [
//           { title: '沟通与分析', keywords: '需求' },
//           { title: '线稿与设计', keywords: '交互' },
//           { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/465817db-6626-4173-a6e0-3ff7716d5cdd.jpeg',
//     serveRange: [
//       {
//         title: '苹果iOS APP',
//         img: require('../svg/ios.svg'),
//         script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
//       }, {
//         title: '移动安卓App',
//         img: require('../svg/android.svg'),
//         script: '适用于所有安卓系统手机的应用，在华为应用商店，小米应用商店等均可下载'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '我们负责ios端的开发已经完成并且上线，可在苹果商城搜索下载使用'
//       }
//     ],
//     addServe: [
//       '智能设备开发', '微信小程序开发', '应用图标设计', '产品原型设计', 'UI原型设计', '微信推广活动', '服务器运维'
//     ]
//   },
//   {
//     name: '智赛桥牌',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2d81faf3-1a03-4f70-a779-3b76a9ebd6d6.jpeg?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/26017189-b483-437d-bdd7-816c7f39c9ed.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9f0c6ece-c2d4-4e76-82ba-421595c73c58.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/51cef144-6686-4273-bbb5-9de8e68028b5.jpeg',
//     script: '电脑端桥牌，率属于石家庄桥牌协会，为制作全新的设计。',
//     brands: [5, 6, 12, 13, 14, 15],
//     inCharge: ['产品设计', '端游交互设计', 'Win应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c8eeef22-0ca9-4010-b068-a292e4b0cc4c.png',
//     scope: [
//       { icon: 'windows', title: 'Win应用程序', script: '我们的目标是为石家庄桥牌协会开发一款智赛桥牌的电脑游戏比赛。', subTitle: '用户可以：', content: ['四人成组，畅玩桥牌', '与好友创建房间', '打开视频', '在线聊天互动'] },
//       { icon: 'game', title: '端游交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API，不同身份不同权限应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '游戏大厅', script: '快速游戏实时响应技术实现了自动找寻匹配的对手对局，在线聊天技术时用户可以在大厅自主找寻对手', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d7ae4646-cf43-41ae-9c57-67cc16b17693.png', isBig: true },
//       { title: '游戏准备中', script: '对于线上比赛预防用户犯规，使用了切屏结束对局和视频监控等方式', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/be4b54cb-177f-4d69-9535-c6049009068c.png', isBig: true },
//       { title: '游戏进行中', script: '桥牌规则跟实际规则完全一致，可能出现的错误方式都有限定，即使不懂桥牌规则用户也可使用', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6d9d2b1b-9a82-4706-a9e3-3d260897aec2.png', isBig: true },
//       { title: '游戏积分', script: '积分核算在桥牌比赛规则中复杂性很高，复合型算法保证了比赛分数的正确和公平', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f6000638-e743-442f-b010-ac88e34fbf4c.png', isBig: true },
//       { title: '裁判介入', script: '比赛模式配备了裁判角色，裁判相当于监督员可以进入任一比赛场查看或者回看视频，比赛的所有场次都做了视频录制', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/78ce8933-cf14-4264-8d00-85e5f1b881be.png', isBig: true },
//       { title: '摊牌', script: '某一用户提出摊牌的结算分数的时候，必须给在场所有用户发送摊牌确认信息，全员同意才可以中止比赛', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f44827e8-b03c-4064-88f0-f1643cb15da6.png', isBig: true },
//       { title: '打牌记录', script: '打牌记录是核算分数的关键，展示给用户，保证了比赛分数没有异议和算分的准确', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f37bae91-21f1-4e2c-800b-f26f1875d9fb.png', isBig: true }],
//     tech: [
//       {
//         title: 'Win开发',
//         script: ['我们用于游戏开发的解决方案',
//           '我们创建了专用于桥牌应用程序的自定义架构。在后端开发中，我们使用诸如S3，Lambda和Elastic Transcoder之类的AWS服务。',
//           '对于数据管理，我们具有独特的数据下载，存储和同步的高级解决方案。'],
//         items: [{ title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//         { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//         { title: '框架', keywords: 'Vue.js' },
//         { title: '在线发牌', keywords: '服务器发牌，拒绝作弊，保证公平' },
//         { title: '视频聊天', keywords: '融合通信PaaS云' },
//         { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
//       },
//       {
//         title: '设计',
//         script: ['与客户沟通需求后，我们了解客户的想法，结合当前游戏设计的原则规范，我们精心设计了游戏界面，看起来更有趣，我们需要一个简洁而高效的操作流从而对于提升用户体验。',
//           '我们从游戏玩法、游戏画面、游戏整体体验入手，立志于打造一款用户喜爱的应用产品。'],
//         items: [{ title: '沟通与分析', keywords: '需求' },
//         { title: '线稿与设计', keywords: '交互' },
//         { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/032570cd-8ded-4cbe-ad0d-3059fa60bd9b.jpeg',
//     serveRange: [
//       {
//         title: 'PC桌面应用',
//         img: require('../svg/pc.svg'),
//         script: '基于win系统开发的即时视频游戏应用，并配备了娱乐模式的比赛模式'
//       }, {
//         title: '实时视频和录制',
//         img: require('../svg/video.svg'),
//         script: '跟阿里云视频云数据库结合，开发完成了比赛模式需要的实时视频播放和存储转换'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '配合桌面应用使用的软件管理后台，查看比赛数据、管理报名团队数据、视频回放'
//       }
//     ],
//     addServe: [
//       '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   },
//   {
//     name: '沃特德饮水机',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/34f72973-6d3f-4abc-9923-0a13b9516bdd.jpeg?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8b6f0dee-ae9a-4e8a-a272-7c647a6e5174.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3bf61ee4-b7db-4342-9ef6-c8cc629fcbb7.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1b5d2024-afab-4f1d-a3a9-ed1b9d340d5f.jpeg',
//     script: '软硬件结合，手机端直观操作智能水机系统。',
//     brands: [1, 2, 6, 7, 9, 11, 12, 13, 14, 15],
//     inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ec323865-a25b-4dce-8315-c6422b2e6c04.jpeg',
//     scope: [
//       { icon: 'ios', title: '移动应用开发', script: '我们的目标是为沃特德开发iOS和Android移动商务应用程序。', subTitle: '用户可以：', content: ['查看水机水质水量等信息', '获取水机分布情况', '了解滤芯情况适时更换', '对经销商进行管理'] },
//       {
//         icon: 'devise', title: '产品交互设计', script: '我们为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：',
//         content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势']
//       },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '水机设置', script: '软硬件结合及时通信是关键，云服务数据的及时传输帮助软件可以随时监管设置水机信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/30f168cb-bab7-4926-b24a-84e844ecdff4.jpeg' },
//       { title: '水机详情', script: '管理员可以查看水机位置信息及使用情况包括水位、水质等详情，方便用户及时更换反馈', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f85e4b5c-3175-4e9a-a400-289ea8b1ba4e.jpeg' },
//       { title: '经销商端', script: '经销商权限是由管理端统一分配，数据判断处理只能查看自己名下的水机信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/27e5cdaf-72a0-4ef1-b886-1581be7bbb36.jpeg' },
//       { title: '滤芯状态', script: '及时通讯芯片帮助软件及时获取水机滤芯状态，软件警告提醒管理员，及时更换滤芯', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7301b90d-461a-4a77-86be-e1d64e7df841.jpeg' },
//       { title: '用水量统计', script: '水机数据核算部分我们采用真实数据统计核算，可以及时监控水机的盈利状态', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3e400ef0-221a-4692-a18a-fd4cfb521404.jpeg' },
//       { title: '租赁设置', script: '因各个城市的消费水平的不同，对于租用水机的服务商，单独可以设置水机出水量、价格等配置', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5b3690f8-79bc-493c-91d8-e28d532c5977.jpeg' },
//       { title: '管理员端', script: '管理员对当前水机和经销商进行管理，便于查找我们做了层级划分，管理员可根据经销商查看水机信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4a976b42-a31b-4fc9-ac94-8ca7b7985138.jpeg' },
//       { title: '运行时间设置', script: '除了在水机硬件调整运行时间，我们还实现了在APP远程设置运行时间，帮助管理员操控水机', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/586db8b6-4491-4339-ad2d-74b65c2b74f8.png' },
//       { title: '水机报修', script: '硬件监控可能不尽完善，为了确保终端的正常使用，应用实时通讯技术让用户可以把问题直接反馈给管理者', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/46bc64dc-9780-4fd8-a0a5-a2e6165dfe99.png' },
//       { title: '维修记录', script: '维修记录的统计是平台维护机器和记录机器运行状态的重要信息，可促使机器测试跟易发问题的更新，提高硬件的质量', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2ca7bddd-dd13-4c65-afec-ac83a3284a48.png' }],
//     tech: [
//       {
//         title: '移动开发',
//         script: ['我们用于APP开发的解决方案',
//           '用户可以使用其唯一的密码登录，保证其安全性。',
//           '我们选择了SnapKit布局，这是Google推荐的一种布局方式。它帮助我们使UI设计保持简单且规范。该布局高质量且易于管理。',
//           '从用户的角度和流畅的体验，我们用MixPanel的分析工具和Kotlin协程，更好的完成项目。'],
//         items: [
//           { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '云服务', keywords: '基于阿里linux系统的云服务' },
//           { title: '物联网', keywords: '新一代网络智能售水机' },
//           { title: '微信开发', keywords: '公众号、小程序' },
//           { title: '中台', keywords: '提供完善的企业管理能力' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
//       },
//       {
//         title: '设计',
//         script: ['该应用具有友好的交互式UI，可让用户更好地了解水机情况从而高效管理水机。由于硬件提供商和软件开发团队之间的顺利合作，我们提供了最佳的更新体验。',
//           '严谨的交互逻辑，保证用户在使用时感觉流畅并能熟练使用该应用。'],
//         items: [{ title: '沟通与分析', keywords: '需求' },
//         { title: '线稿与设计', keywords: '交互' },
//         { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/76419d3b-e68d-45f7-bdbc-56b0d314175f.jpeg',
//     serveRange: [
//       {
//         title: '微信公众号开发',
//         img: require('../svg/weixin.svg'),
//         script: '帮助客户在所拥有的公众号搭建水机管理、在线售水、远程操作等功能'
//       }, {
//         title: '微信小程序开发',
//         img: require('../svg/xiao.svg'),
//         script: '为便于用户使用，后续开发完成了小程序端、跟公众号的数据所同步使用'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '配合公众号、小程序管理使用的PC后台，管理商家、水机、数据报表统计等'
//       }
//     ],
//     addServe: [
//       '智能设备开发', '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   },
//   {
//     name: '领航练车',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3db4aba0-007f-48a5-863a-c0fc18473a92.jpeg?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/963e16d5-7280-4ea2-937c-0f7725416ab0.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3ed11e18-00ff-4d63-8783-7a101d631ce8.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/09673791-dd06-42b4-8196-a85470c760ac.jpeg',
//     script: '在教练和学员之间搭建信息桥梁，能够自动化完成预约管理工作，让练车更高效！',
//     brands: [1, 2, 6, 10, 12, 13, 14, 15],
//     inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7801525c-525d-441e-9da5-3b8aadae6d0e.jpeg',
//     scope: [
//       { icon: 'ios', title: '移动应用开发', script: '我们的目标是为领航练车开发iOS和Android移动应用程序。', subTitle: '用户可以：', content: ['选择训练项目', '约教练员练车', '接学员的练车申请', '对练车员进行评分', '意见反馈'] },
//       { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '身份识别', script: '我们在一个软件中集成了2个APP软件，用户只需要一个入口就可以根据不同身份进去不同软件操作', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0491c1db-6ffc-4140-bf41-3d8db7fd0cb4.jpeg' },
//       { title: '优质练车', script: '对于优质、服务好的教练我们优先展示给用户，用户可根据查看教练数据选择性预约教练练车', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/be9905e1-0bc1-4c41-947b-8892323d291b.jpeg' },
//       { title: '快速练车', script: '为简化操作步骤让约车更高效，当用户发出订单需求后系统会根据您要练车的考试和科目，推送最优质的教练', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1669939-cbbe-4929-ac05-de96438d34bc.jpeg' },
//       { title: '教练端', script: '教练端我们实现了实时抢单和数据管理，使教练管理查看基本收益、评分信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1e3683a3-8c1a-4c6e-85c9-33758564a947.jpeg' },
//       { title: '接单服务', script: '我们使用云服务器数据实时交换，实现教练员的实时接单抢单功能', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c2e6b39c-0fa4-4bcd-a34a-e413775cdfac.jpeg' },
//       { title: '用户中心', script: '软件的快捷性和重复使用性是增加用户的粘性的关键，用户信息我们汇总在这里展示，进一步提升软件的易使用性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ac9d5196-38d8-4ffb-b5ce-56f117dbaf53.jpeg' },
//       { title: '订单评论', script: '评分系统是根据用户完成订单后给教练打分实现的，评分系统起到了帮助监督教练，评选优质教练的基础', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3be47090-3606-4ff6-871a-c53b2efd8760.jpeg' },
//       { title: '微信朋友圈分享', script: '基于微信的用户分享，实现了一整套的推广促销逻辑，APP分享到微信好友注册获得优惠券机制使APP新增用户每月多提升了40%', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/bd937b3e-768f-451d-9284-82b06065efd4.png' },
//       { title: '教练申请', script: '为把控管理教练质量，除了评分系统监管，还设立了入驻门槛', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/539fe6b1-b031-42b6-97ee-10a137c3aa04.png' },
//       { title: '签到领学费', script: '推广优惠活动跟签到相结合的方式，促使提高了APP用户活跃度和下单量', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/adced47a-6dde-4a4f-a29f-32a90b58444b.png' }],
//     tech: [
//       {
//         title: '移动开发',
//         script: ['我们用于APP开发的解决方案',
//           '对于教练接单，重新查询服务器或在线约车等大多数功能，我们创建了自己的本机解决方案。由于iOS平台的特性，以这种方式实现它们比使用外部库更容易，更快。它为我们提供了100％的灵活性，并允许我们精确地创建所需的功能。',
//           'Android应用程序开发与iOS应用程序开发非常相似。主要区别在于我们使用了流行的，可靠的库，例如Retrofit，Moshi，AndroidX等。'],
//         items: [
//           { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '云服务', keywords: '基于阿里linux系统的云服务' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' },
//           { title: '支付方式', keywords: '微信/支付宝' },]
//       },
//       {
//         title: '设计',
//         script: ['该应用程序的设计与客户的想法有些不同，我们建议了一些可以更好地设计应用程序的方法 -例如，我们建议使用圆角样式，简洁大方。我们的客户接受了这一点，他们对结果感到满意。',
//           '在用户选择课程约教练员等流程时，保证对用户友好，流畅和方便至关重要。'],
//         items: [
//           { title: '沟通与分析', keywords: '需求' },
//           { title: '线稿与设计', keywords: '交互' },
//           { title: '制作与细节', keywords: '视觉' },
//           { title: '建议与参考', keywords: '合作' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a196dbc4-3f8f-4174-87f1-fba91004d5b6.jpeg',
//     serveRange: [
//       {
//         title: '苹果iOS APP',
//         img: require('../svg/ios.svg'),
//         script: '我们负责ios端的开发已完成并且上线，可在苹果商场搜索下载使用'
//       }, {
//         title: '移动安卓APP',
//         img: require('../svg/android.svg'),
//         script: '适用于所有安卓系统手机的应用，在华为应用商城，小米应用商城等均可下载'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '配合前端APP使用的软件管理后台也投入使用，查看软件数据，管理用户数据'
//       }
//     ],
//     addServe: [
//       '微信推广活动', '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   },
//   {
//     name: '子曰',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/266ef9a5-657b-4c19-9d72-254d015f8c8c.jpeg?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1ac5d48-31a5-47de-8cdf-97b2dd633663.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1bd1c536-1dd7-49da-bb48-310a737ee245.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c3ff32ce-c135-49be-9d8a-9c0faee4ab7e.jpeg',
//     script: '用于教学的在线朗读应用，让您高效学习，快乐学习。',
//     brands: [3, 4, 6, 12, 13, 14],
//     inCharge: ['产品设计', 'ipad交互设计', 'ipad应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/90c1ea15-8106-4817-9563-7b51a630e548.jpeg',
//     scope: [
//       { icon: 'ipad', title: 'iPad应用开发', script: '我们的目标是开发一款在线朗读的iPad应用程序。', subTitle: '用户可以：', content: ['选择自己喜欢的书籍', '收听内容轻松学习', '全屏查看书籍内容', '选择自己喜欢的朗读版本', '标记书籍内容'] },
//       { icon: 'devise', title: 'iPad交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – 后台程序和前端程序都有符合需求的丰富功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '启动页面', script: '子曰是一款教学类应用程序，使用多媒体课件可以实现陪读、领读等模式，减轻教师负担', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6cf178dc-d885-410a-8d15-5efe28c2c077.png', isBig: true },
//       { title: '经典书架', script: '书架陈列的数据是PC端编辑好，下载即可使用的课件，同时对最近播放的课件和数据记录', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/df6a0eaa-415e-44cc-9177-fa97dbd4eebb.png', isBig: true },
//       { title: '内容选择', script: '自由的选择所播放的段落，并且可配置多个音频依次播放', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3f2ec347-01dc-4f44-8cf7-9f727213c084.png', isBig: true },
//       { title: '播程选择', script: '播程既是当前书籍的音频文件，可以选择言或着语等播放方式，层次架构实现语音和文字同步，并且在按言、语等播放的停顿', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/edce4a81-823e-4dbe-9792-9f989c76534c.png', isBig: true },
//       { title: '设置', script: '实际使用中陪读的音量大小需求不同，音量的自动设置调节帮助教师更好操控', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/7ae2ac7c-8cb8-4de5-9f77-f2477f5e519f.png', isBig: true },
//       { title: '播放模式', script: '多个播程依次播放的同时，快进和后退也会根据当前选择的播放模式按章、语、句等模式调整', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/519eb204-d309-4839-be9c-df05247f0409.png', isBig: true },
//       { title: '专注模式', script: '全屏播放模式是投影在多媒体上更好的展现方式，使学生可以更好的专注当前所读的内容', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a12632b8-59b5-480e-bce3-69bd6054cbd4.png', isBig: true }],
//     tech: [
//       {
//         title: 'iPad开发',
//         script: ['我们用于iPad开发的解决方案',
//           '我们提供了多个通信和跟踪渠道，这使整个过程非常高效。我们保持频繁的更新频率，保证各个方面的透明性，并顺利地组织了依赖性，这使我们能够完全控制该项目，有助于我们了解其性能。',
//           '我们选择Assertk,Mockito进行多次测试，使用RxSwift编程，保证代码的耐用性使用户拥有流畅的体验。'],
//         items: [
//           { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '音频编辑', keywords: 'howler、wavesurfer' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' },]
//       },
//       {
//         title: '设计',
//         script: ['与客户沟通需求后，我们精心设计了线框和可点击的原型，以更好地了解客户的想法。这种方法为我们节省了很多时间。',
//           '为了让用户高效学习，我们新增了—专注板块，客户对此非常满意。'],
//         items: [{ title: '沟通与分析', keywords: '需求' },
//         { title: '线稿与设计', keywords: '交互' },
//         { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/c428a9c1-120f-4842-8dcf-608b3b63decc.jpeg',
//     serveRange: [
//       {
//         title: '苹果iOS iPad',
//         img: require('../svg/ios.svg'),
//         script: '我们负责ios端ipad的开发和交互体验设计，帮助提供用户使用率'
//       }, {
//         title: '移动安卓平板',
//         img: require('../svg/android.svg'),
//         script: '运行于所有安卓系统平板的应用，在华为应用商城，小米应用商城等均可下载'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '课件编辑和管理后台，帮助实现在电脑端编辑音频和文字的匹配，使平板正常播放课件'
//       }
//     ],
//     addServe: [
//       '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   },
//   {
//     name: '房智汇',
//     brands: [1, 2, 6, 12, 13, 14],
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d5a49a0f-2fbd-427a-bfa0-a7ae5e605dd6.png?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0ed2d1e1-8838-4e3e-a1d5-3427f6a64836.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/460a6c9f-ffb0-4afd-b6cb-00a74b7b7181.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2e48cffe-ca1f-4d4e-ab26-31c27593e030.jpeg',
//     script: '多伦比亚房产销售应用，透明的房产行情、高效的找房工具，提供全面真实房源',
//     inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/9d5cf2c8-cf12-403c-b9d4-b8986ad27798.jpeg',
//     scope: [
//       { icon: 'ios', title: '移动应用开发', script: '我们的目标是为多伦比亚房产开发ios及Android移动应用程序', subTitle: '用户可以：', content: ['了解行业数据市场动向', '根据个人需求找房', '通过地图快速找房', '查看新闻资讯'] },
//       { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '地图找房模块', script: '我们使用高德地图定位技术信息汇总房源信息，为用户提供高效便捷的房源信息，解决了房源位置信息不明确、不直观等需求痛点', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/f0bfc66b-4c22-4026-8c08-5c17097d61fc.jpeg' },
//       { title: '附近已售相似房屋', script: '为促进用户达成购买，我们通过大数据核算和虚拟算法等核心技术推荐用户感兴趣的房源信息，促使房源成交率提示30%', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a913a7fa-2c00-473a-8421-916ae79f83fb.jpeg' },
//       { title: '用户信息', script: '软件的快捷性和重复使用性是增加用户的粘性的关键，浏览过和收藏过的信息我们统一汇总在这里从而让用户快速找到自己想要的内容，进一步提升软件的易使用性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/78237a79-92f7-46cf-8ecb-993799257244.jpeg' },
//       { title: '房子的行业数据展示', script: '房屋成交数据、房屋销售数据、市场房价增降是用户购入房源的关键信息，对于复杂的信息如何在软件通俗易懂的呈现给客户是通用难点，我们根据图形数据对于表格数据更敏感，，实现了一整套的实时数据图形表', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/675163c5-e7a2-4e9e-8fea-f1e6b401b829.jpeg' },
//       { title: '社区房源', script: '房源信息得地理位置是用户最关心得之一，数据上我们按社区汇总，促使用户更加便利得找到目标房源信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fde149e0-9f1f-4d6d-841b-2926a3599707.jpeg' },
//       { title: '推荐社区', script: '智能推荐算法，根据用户浏览的社区房源推荐同类型和相似类型的社区信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/faf4835d-6d8f-4473-a5d7-ba484cfaeeb7.jpeg' },
//       { title: '关注房源', script: '软件基本功能更好的体现用户体验，我们会给用户关注的房源做对比并且分析房源信息优劣，从而更好的促使用户交易', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5d43406c-ccbd-406c-9c76-c2c2729d110c.jpeg' },
//       { title: '房屋详细信息', script: '房屋信息复杂、繁多，我们通过内部探讨会和数据调查整理出用户最关心的数据展示在移动端，并且保持了高度易读，方便识别', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5e1ede9a-3ef8-4276-8cc5-b63164afc0f5.png' },
//       { title: '用户注册', script: '为简化用户登录步骤，我们做了登录注册调整，保留原登录方式基础上，增加了快捷登录', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/50917bbb-5d0e-49db-ab88-4530c828d167.png' },
//       { title: '筛选房源', script: '对于房源的庞大数据，用户怎么更快的找到自己目标房源，我们不仅有社区、附近等大推荐模块，对于房源价格区间、户型等具体信息用户也可搜索查询', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2b9f9a51-21c4-4baf-9f62-e385a2c815dc.png' }],
//     tech: [
//       {
//         title: '移动开发',
//         script: ['我们用于APP开发的解决方案',
//           '我们仅使用几个库构建了iOS应用。对于网络，重新查询服务器或整理行业数据统计等大多数功能，我们创建了自己的本机解决方案。',
//           '由于iOS平台的特性，以这种方式实现它们比使用外部库更容易，更快。它为我们提供了100％的灵活性，并允许我们精确地创建所需的功能。',
//           '应客户的要求，我们以地图找房的形式创建了一种高效的搜索形式。'],
//         items: [{ title: '技术架构', keywords: '自研架构框架，高内聚，低耦合' },
//         { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//         { title: '框架', keywords: 'Vue.js' },
//         { title: '云服务', keywords: '基于阿里linux系统的云服务' },
//         { title: '地图', keywords: '高德地图导航和位置服务解决方案' },
//         { title: '多语言切换', keywords: '支持多语言' },
//         { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
//       },
//       {
//         title: '设计',
//         script: ['定制化app设计增强用户粘性，圈留住老客户，让他们成为您最忠实的口碑传播者，无形中提高用户的口碑。',
//           '采用图文并茂、形象生动的广告表现形式，降低营销成本。',
//           '基于地理位置，为用户推送房源信息，能链接电话，短信，使用更便捷。'],
//         items: [
//           { title: '沟通与分析', keywords: '需求' },
//           { title: '线稿与设计', keywords: '交互' },
//           { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2348f933-4226-40ba-81d0-94b61d964b4b.jpeg',
//     serveRange: [
//       {
//         title: '苹果iOS APP',
//         img: require('../svg/ios.svg'),
//         script: '我们负责ios端的开发已完成并且上线，可在苹果商场搜索下载使用'
//       }, {
//         title: '移动安卓APP',
//         img: require('../svg/android.svg'),
//         script: '适用于所有安卓系统手机的应用，在华为应用商城，小米应用商城等均可下载'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '配合前端APP使用的软件管理后台也投入使用，查看软件数据，管理用户数据'
//       }
//     ],
//     addServe: [
//       '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   },
//   {
//     name: '简果',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/72dd6b14-b948-422e-83d1-7c83c7854bc4.png?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4be3b9d5-62f2-44f1-96e3-3a5e69661d9d.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ec5711d1-468f-428f-bdf9-6ba3a6c22746.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/fbb03102-6f81-4d70-956e-e649c41416c4.jpeg',
//     script: '大型储存运输管理应用，提供库存管理、物料管理、员工管理等功能',
//     brands: [1, 2, 6, 12, 13, 14, 15],
//     inCharge: ['产品设计', 'APP交互设计', 'IOS应用程序', 'Android应用程序', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b1eca851-307c-41ea-86f1-be2a63ac2492.jpeg',
//     scope: [
//       { icon: 'ios', title: '移动应用开发', script: '我们的目标是为简果开发ios和Android移动在线管理应用程序。', subTitle: '用户可以：', content: ['进行入库出库管理', '对员工进行管理', '管理物料信息', '在线记账', '导出配货单'] },
//       { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
//       {
//         icon: 'idea', title: '后端开发', script: '我们使用阿里云后端数据库为平台，更好的确保了数据的保密性与私有性。', subTitle: '主要挑战是：',
//         content: ['为多个客户端应用程序提供API – iOS应用程序和安卓 App应该具有不同的功能。', '优化数据模式B端我们采用专业数据统计模式，统计计算应用程序用户的模式，帮助软件更好的发展。']
//       }
//     ],
//     appContent: [
//       { title: '调整产品售价', script: '水果了类产品售价要根据季节和市场灵活调控，我们在管理者端口做了数据互通，保证用户获取到实时价格数据', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b56bf932-df5f-4037-a8b8-9a09c27b0455.jpeg' },
//       { title: '创建配货单', script: '订单的记录代替了人工手记繁琐工作，对于重复性工作我们做到自动填写录入，并且对于每一个单数据都有备份储存和实时打印', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/340ac125-8b57-443f-a128-2e3d8d4101a4.jpeg' },
//       { title: '物料登记', script: '水果包装物料是必备产品，对于整个厂区的大量物料产品管控和监管，软件代替人工可以查溯到物料的进出数据和所有使用位置', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/cadd4104-66cd-43a2-bb7a-50b8f1a1e2fe.jpeg' },
//       { title: '在线记账', script: '厂区管理员手工记账工作繁琐，数据整理计算繁重、类目不规范等问题都可以在软件记账方式下解决', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/440348b3-3b17-4dea-85c4-7d7c1b0348e2.jpeg' },
//       { title: '果品定位', script: '水果有好坏、大小之分，品种类型也不近相同，实现定位入库，可以在软件中查看每个果品的准确位置', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4f8cf019-d56f-448c-8ad7-92190df3c43f.jpeg' },
//       { title: '个人中心', script: '软件的快捷性和重复使用性是增加用户的粘性的关键，浏览过和收藏过的信息我们统一汇总在这里从而让用户快速找到自己想要的内容，进一步提升软件的易使用性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1ea9f3c8-ac2e-429c-9b92-44b01c19d306.jpeg' },
//       { title: '库存结算', script: '订单的自己计算和数据汇总，是库存类软件的核心，它可以省去大量的人力核算，提高工作效率', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/5433f960-169d-4cee-803f-3b298af02279.jpeg' },
//       { title: '员工签到', script: '员工签到，办公OA我们在实现库存管理的基础上同步实现，简化厂区的人员管理', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/2cf970aa-c2f7-47e2-adb3-8735ad58fbad.png' },
//       { title: '物料账目', script: '物料的使用和账目核算同步进行，达到了一个品种一种物料，帮助厂区做到了精准核算账目', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/a77f2220-4c69-4b65-9c52-7e5a219260eb.png' },
//       { title: '库房收存', script: '收存是入库的非必要前提，但也是重要组成部分，简化有秩序的收存逻辑是提供厂区员工工作效率的核心', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/afc52c8c-d367-4354-b5cf-dbe3ca7d1bdc.png' }],
//     tech: [
//       {
//         title: '移动开发',
//         script: ['我们用于APP开发的解决方案',
//           '根据我们客户的指南，该应用程序支持不同的屏幕尺寸和智能手机。',
//           '用户可以使用其唯一的密码登录，保证安全性。 ',
//           '简果应用程序的流程比较复杂。从用户的角度和流畅的体验，我们用Kotlin协同程序的并发和异步编程。'],
//         items: [
//           { title: '技术架构', keywords: '自研架构框架，高内聚、低耦合' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '云服务', keywords: '基于阿里linux系统的云服务' },
//           { title: '地图', keywords: '高德地图导航和位置服务解决方案' },
//           { title: '角色权限', keywords: '多端登录，相当于六个app' },
//           { title: '蓝牙打印', keywords: '远程打印技术' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' }]
//       },
//       {
//         title: '设计',
//         script: ['通过设计来满足需求，与客户沟通，从用户角度发现问题，解决软件界面各方面的痛点，使其更加合理、流畅、易用。',
//           '结合整个产品公司定位，价值观等，根据公司设计指导准则使产品具有完整性，一致性，易操作。'],
//         items: [
//           { title: '沟通与分析', keywords: '需求' },
//           { title: '线稿与设计', keywords: '交互' },
//           { title: '制作与细节', keywords: '视觉' },
//           { title: '建议与参考', keywords: '合作' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/715d1fea-9f04-4c23-8fc8-4d3f6303d49e.jpeg',
//     serveRange: [
//       {
//         title: '苹果iOS APP',
//         img: require('../svg/ios.svg'),
//         script: '我们负责ios端的开发已完成并且上线，可在苹果商场搜索下载使用'
//       }, {
//         title: '移动安卓APP',
//         img: require('../svg/android.svg'),
//         script: '适用于所有安卓系统手机应用，在华为应用商城，小米应用商城等均可下载'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '配合前端APP使用的软件管理后台也投入使用，查看软件数据，管理用户数据'
//       }
//     ],
//     addServe: [
//       '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   },
//   {
//     name: '天安人寿',
//     icon: 'http://moke-store.oss-cn-beijing.aliyuncs.com/6965b313-a792-4f8f-9d54-9dc13c8209bf.png?x-oss-process=image/resize,w_68,h_68',
//     headImg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b1e55207-34bd-4383-8dad-cc379624e53a.png?x-oss-process=image/resize,w_600',
//     headBack: 'http://moke-store.oss-cn-beijing.aliyuncs.com/75f3314b-7f76-41f3-98b5-25586e5eeb48.png',
//     mobileHeadimg: 'http://moke-store.oss-cn-beijing.aliyuncs.com/80c8f2d3-cefa-444e-a37b-e5bbe0ee2aaa.jpeg',
//     script: '微信公众号在线考试答题，后台管理系统进行查看管理，提高公司的管理效率。',
//     brands: [6, 9, 12, 13, 14, 15],
//     inCharge: ['产品设计', '微信公众号开发', '后台管理系统', '后端开发'],
//     banner: 'http://moke-store.oss-cn-beijing.aliyuncs.com/adcf7290-7769-4b26-ab4a-f1f14ec85e21.jpeg',
//     scope: [
//       { icon: 'WeChat', title: '微信公众号开发', script: '我们的目标是为天安人寿开发微信公众平台及后台管理系统。', subTitle: '用户可以：', content: ['选择考试项目', '在线答题', '应聘公司职位', '进行内部人员考试'] },
//       { icon: 'devise', title: '产品交互设计', script: '原生开发，为客户量身打造最优产品，满足客户高端定制要求，遵循UI设计的最新趋势。', subTitle: '我们的关键准则：', content: ['引导用户轻松体验产品', '以清晰，吸引人的方式展示产品信息', '分工明确，一对一沟通，减少沟通误差', '为用户提供精准服务', '把握当前设计趋势'] },
//       { icon: 'idea', title: '后端开发', script: '基于JSP技术、B/S架构设计的Java应用平台，总体维护成本非常低。', subTitle: '用户可以：', content: ['确保后台管理的安全性、易操作和实用性', '大量数据处理和报表生成，从而提高工作效率', '在程序设计中采用面向对象行的模块设计，便于程序的扩展和二次开发。'] }],
//     appContent: [
//       {
//         itemsTitle: '微信公众号',
//         content: [
//           { title: '计时答题', script: '采用时间表做倒计时的形式实现答题模块，并且实现试题随机分配，保证题型难度的基础上让每个考生试卷都不相同', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/b5d10116-01a3-4139-bfb1-1f7f7e5febbf.jpeg' },
//           { title: '账号密码登录', script: '平台为公司私密性项目，所以我们做了制定账号密码登录，保证了项目的安全性和可靠性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/e1caa173-def9-45f1-8d8c-a75c935686cd.jpeg' },
//           { title: '考试科目列表', script: '为保证考试的强提醒，考试之前采用倒计时的形式呈现，可以帮助用户更好的形成记忆', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/1d9bbc48-3ed4-4111-9c95-7a0a870a813e.jpeg' },
//           { title: '完成考试', script: '成功反馈是操作末端对用户的直接反馈，突出了本次考试的基本信息', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/75f7ee8f-64b6-46a4-b277-6e3d7e3a3a7d.jpeg' },
//           { title: '修改密码', script: '个人密码修改是整个登录的闭环，可以保证个人密码的安全性和保密性', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/0744c155-6ddc-4bc5-a1c4-0431d3ffc1e8.jpeg' },
//           { title: '个人中心', script: '考试信息汇总查看便于统计记录考试', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/66c155ef-fe75-4b59-8c1b-40b4cf24da5a.jpeg' }]
//       },
//       {
//         itemsTitle: '后台管理',
//         content: [
//           { title: '考试详情', script: '良好的数据展示，简化的便捷操作在PC端更好呈现是我们的预期', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/d85bf0d6-52a2-4d76-9e9c-e239ccad5127.png', isBig: true },
//           { title: 'PC端考试页', script: '用户可以在PC端直接开始考试，限时答题、自动提交，我们在网络错误和数据错误时都有修正', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4e8b6b4b-b772-4779-bacc-03828ec13d3f.png', isBig: true },
//           { title: '应聘人员详情', script: '人员详情同时也是简历文档，帮助HR更好的管理人才档案', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/ddbf29b7-f288-4fff-9c5f-7de909c4a888.png', isBig: true },
//           { title: '试卷库', script: '您所有的考试都是基于试卷库搭建的，每次考试都可以选择已有的试卷进行创建，更好的简化操作步骤', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/355ec1cb-e85f-4416-8476-2e0bb2f34ce6.png', isBig: true },
//           { title: '内部员工测评', script: '人员测评模块包含互评和层级评分，实现了自动分配打分人员和评分统计，同时支持Excel表格导出', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/3c8bd011-0f08-4ce6-b0b8-8f75cfaf9e1f.png', isBig: true },
//           { title: '考试管理', script: '管理人员创建考试，监督考试进行，分配考试人员等操作都需要在这里进行', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8e53e1e4-59c5-4566-be25-dd9951874322.png', isBig: true },
//           { title: '试卷评分', script: '对于判断、选择、填空系统会自动形成评分，但是简答题模块需要分配专业人员打分', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/301356ed-be56-40e0-8b72-dd7104030042.png', isBig: true },
//           { title: '新建评分表', script: '评分表的项目和内容都不是固定的，对于每季度和每年都会做出调整，所以我们这里给了用户更多的自由度，可以灵活创建考评表', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/eb56356d-70ce-4932-b7a4-2ea33106e677.png', isBig: true },
//           { title: '分配评分任务', script: '组织架构会涉及到到同一人监管多部门情况，应对这种特殊情况在自动分配评分任务基础上可以灵活添加评分人', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/8938f13b-e0bc-48aa-831d-76b8f0c29668.png', isBig: true },
//           { title: '试卷考题添加', script: '试卷在可灵活编辑的同时考题也需要灵活搭配，不仅支持PC端编辑考题同时也支持Excel表格导入自动识别考题', img: 'http://moke-store.oss-cn-beijing.aliyuncs.com/4d6e9081-296a-4aec-a2b6-f79f76cec5b1.png', isBig: true }]
//       }
//     ],
//     tech: [
//       {
//         title: '公众号开发',
//         script: ['我们用于公众号开发的解决方案',
//           '公众平台以access_token为接口调用凭据，来调用接口，所有接口的调用需要先获取access_token',
//           'WeUI 是一套同微信原生视觉体验一致的基础样式库，令用户的使用感知更加统一。'],
//         items: [
//           { title: '技术架构', keywords: 'antdesign vue' },
//           { title: '数据库框架', keywords: 'GraphQL的数据库体系' },
//           { title: '框架', keywords: 'Vue.js' },
//           { title: '云服务', keywords: '基于阿里linux系统的云服务器' },
//           { title: '开发方式', keywords: 'H5 + 原生混合开发' },]
//       },
//       {
//         title: '设计',
//         script: ['我们经验丰富的产品人员会帮您整理项目需求、出思维导图和开发报价单，专业的产品经理梳理需求、出原型图。团队拥有多年开发经验，我们会为您的项目想的更多。',
//           '好的设计不仅能帮助粉丝更好的了解解读企业，也能帮助企业树立别具一格的形象。'],
//         items: [
//           { title: '沟通与分析', keywords: '需求' },
//           { title: '线稿与设计', keywords: '交互' },
//           { title: '规范与创意', keywords: '原型' },
//           { title: '制作与细节', keywords: '视觉' }]
//       }
//     ],
//     motion: 'http://moke-store.oss-cn-beijing.aliyuncs.com/cf12f97e-1132-4249-8d03-2495709e40c1.jpeg',
//     serveRange: [
//       {
//         title: '微信公众号开发',
//         img: require('../svg/weixin.svg'),
//         script: '在客户公众号开发的一套员工考试系统'
//       }, {
//         title: 'OA管理系统',
//         img: require('../svg/oa-01.svg'),
//         script: '为减轻HR工作负担，提高工作效率，定制人员管理，考评系统，考试系统等'
//       }, {
//         title: '数据管理后台',
//         img: require('../svg/moke.svg'),
//         script: '配合公众号和OA系统使用的软件管理后台，查看软件数据，管理用户数据'
//       }
//     ],
//     addServe: [
//       '服务器运维', '应用图标设计', '产品原型设计', 'UI界面设计'
//     ]
//   }
// ]
