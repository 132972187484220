<template>
    <div>
        <Pc></Pc>
    </div>
</template>
<script>
    import Pc from '../../components/pcServe'
    export default {
        name: 'serve',
        components: {
            Pc,
        }
    }
</script>
