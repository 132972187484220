<template>
    <div>
    <div v-show="showPreview" class="flex align-center contain_back" @click="closePreview">
        <div v-if="!onMobile" @click.stop="" class="flex flex_center page-item item-9">
            <div class="form-card">
                <div class="title text-center">免费评估，获取开发报价</div>
                <div class="flex_row input-wrap full-width">
                    <div class="full-width" style="margin-right: 40px">
                        <input v-model="name" placeholder="您的称呼"/>
                    </div>
                    <div class="full-width">
                        <input v-model="phone" placeholder="联系方式"/>
                    </div>
                </div>
                <input v-model="title" placeholder="项目名称"/>
                <textarea placeholder="请尽可能详细的说明您的项目需求" v-model="body"></textarea>
                <div class="submit" @click="submit">立即评估</div>
                <a class="close" >
                    <svg t="1575274683718"
                         @click="closePreview"
                         viewBox="0 0 1024 1024"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="2509" width="40" height="40">
                        <path d="M504.224 470.288l207.84-207.84a16 16 0 0 1 22.608 0l11.328 11.328a16 16 0 0 1 0 22.624l-207.84 207.824 207.84 207.84a16 16 0 0 1 0 22.608l-11.328 11.328a16 16 0 0 1-22.624 0l-207.824-207.84-207.84 207.84a16 16 0 0 1-22.608 0l-11.328-11.328a16 16 0 0 1 0-22.624l207.84-207.824-207.84-207.84a16 16 0 0 1 0-22.608l11.328-11.328a16 16 0 0 1 22.624 0l207.824 207.84z" p-id="2510">
                        </path>
                    </svg>
                </a>
            </div>
        </div>
        <div @click.stop="" v-else class="flex flex_center page-item mobile-item-9">
            <div class="form-card">
                <div class="title">免费评估，获取开发报价</div>
                <textarea v-model="name" placeholder="您的称呼"/>
                <textarea v-model="phone" placeholder="联系方式"/>
                <textarea v-model="title" placeholder="项目名称"/>
                <textarea class="textarea-text" placeholder="请尽可能详细的说明您的项目需求" v-model="body"></textarea>
                <div class="submit" @click="submit" v-fb>立即评估</div>
                <a class="close" >
                    <svg t="1575274683718"
                         @click="closePreview"
                         viewBox="0 0 1024 1024"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg" p-id="2509" width="40" height="40">
                        <path d="M504.224 470.288l207.84-207.84a16 16 0 0 1 22.608 0l11.328 11.328a16 16 0 0 1 0 22.624l-207.84 207.824 207.84 207.84a16 16 0 0 1 0 22.608l-11.328 11.328a16 16 0 0 1-22.624 0l-207.824-207.84-207.84 207.84a16 16 0 0 1-22.608 0l-11.328-11.328a16 16 0 0 1 0-22.624l207.84-207.824-207.84-207.84a16 16 0 0 1 0-22.608l11.328-11.328a16 16 0 0 1 22.624 0l207.824 207.84z" p-id="2510">
                        </path>
                    </svg>
                </a>
            </div>
        </div>
        <transition name="bounce">
            <div v-show="showMsg" @click.stop="" class="alterMsg">
                <div class="h2">{{message}}</div>
                <button class="clickBtn" @click="handleShow">确定</button>
            </div>
        </transition>
    </div>
    </div>
</template>

<script>
// import {tkAjax} from '@moke/vue-tk'
export default {
    name: "EstimateAlert",
    data: () => {
        return {
            name: null,
            phone: null,
            title: null,
            body: null,
            message: '我们已经收到您的信息\n项目经理将会尽快与您取得联系',
            showMsg: false
        }
    },
    computed : {
        com () {
            return this.$store.state.showPreview
        }
    },
    methods : {
        handleShow () {
            this.showMsg = false
        },
        submit() {
            let that = this
            let xmlhttp=new XMLHttpRequest();
            if (!this.name || !this.title || !this.phone || !this.body) {
                this.message =  '请完整填写您的信息'
                this.showMsg = true
                return
            }
            xmlhttp.open('POST', 'https://api.mokekeji.com/consultation',true);
            xmlhttp.setRequestHeader('Content-Type', 'application/json');
            xmlhttp.send(JSON.stringify({
                name: this.name,
                phone: this.phone,
                title: this.title,
                body: this.body
            }));
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState === 4 && xmlhttp.status === 204) {
                    that.message = '我们已经收到您的信息\n项目经理将会尽快与您取得联系。'
                    that.showMsg = true
                    that.name =  null
                    that.phone =  null
                    that.title =  null
                    that.body =  null
                    window._hmt.push(['_trackEvent', 'page', 'message', 'pc'])
                    window.gtag('event', 'conversion', {'send_to': 'AW-965490601/JqaQCMvZpp4BEKnvsMwD'})
                    return;
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .clickBtn {
        &:hover {
            -webkit-transition: opacity 0.2s linear;
            transition: opacity 0.2s linear;
            opacity: 0.5;
        }
    }
    .contain_back {
        background-color: #00000054;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 11;
        left: 0;
    }
    .alterMsg {
        position: fixed;
        top: 40vh;
        left: 50vw;
        margin-left: -150px;
        z-index: 10000;
        width: 300px;
        height: 200px;
        background-color: white;
        font-size: 1.2rem;
        .h2 {
            white-space: pre-line;
            text-align: center;
            margin-top: 50px;
            width: 100%;
            line-height: 1.5;
            height: auto;
        }
        button {
            border-style: none;
            width: 160px;
            margin-top: 20px;
            margin-left: 70px;
            height: 50px;
            background-color: red;
            color: white;
        }
    }
    .item-9 {
        height: 550px;
        width: 900px;
        .form-card {
            position: relative;
            box-sizing: border-box;
            width: 900px;
            height: 550px;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            padding: 40px 60px;

            .title {
                font-size: 20px;
                font-weight: bold;
                color: rgba(45, 45, 73, 1);
                margin-bottom: 20px;
            }

            .input-wrap {
                div {
                    width: 370px;
                }
            }

            input, textarea {
                height: 52px;
                width: 100%;
                background-color: rgba(243, 243, 243, 1);
                border-radius: 1px;
                border: 0;
                padding: 0 8px;
                line-height: 52px;
                margin-bottom: 20px;
                box-sizing: border-box;
                outline: none;
            }

            textarea {
                padding-top: 5px;
                height: 172px !important;
                resize: none;
                line-height: 1.5;
            }

            .submit {
                margin: 0 auto;
                width: 210px;
                height: 56px;
                background-color: rgba(244, 74, 79, 1);
                border-radius: 28px;
                color: white;
                line-height: 56px;
                text-align: center;
                cursor: pointer;
                &:hover {
                    -webkit-transition: opacity 0.2s linear;
                    transition: opacity 0.2s linear;
                    opacity: 0.5;
                }
            }
            .close {
                position: absolute;
                width: auto;
                height: auto;
                top: 10px;
                right: 10px;
                &:hover {
                    -webkit-transition: opacity 0.2s linear;
                    transition: opacity 0.2s linear;
                    opacity: 0.2;
                }
            }
        }
    }
    .mobile-item-9 {
        z-index: 100;
        height: 426px;
        padding: 0 15px;
        .form-card {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 344px;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            margin-top: 40px;
            padding: 20px;

            .title {
                text-align: center;
                font-size: 14px;
                color: rgba(45, 45, 73, 1);
                margin-bottom: 15px;
            }
            textarea {
                height: 38px;
                width: 100%;
                background-color: rgba(243, 243, 243, 1);
                border-radius: 1px;
                border: 0;
                padding: 8px 8px;
                line-height: 22px;
                margin-bottom: 10px;
                box-sizing: border-box;
                outline: none;
                resize: none;
            }
            .textarea-text {
                height: 70px !important;
                resize: none;
                padding-top: 5px;
                line-height: 1.5;
            }
            .submit {
                margin: auto;
                width: 160px;
                height: 40px;
                background-color: rgba(244, 74, 79, 1);
                border-radius: 20px;
                font-size: 16px;
                color: rgba(255, 255, 255, 1);
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
            .close {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }
    @media screen and (max-width: 600px){
        .alterMsg {
            width: 260px;
            height: 160px;
            margin-left: -130px;
            .h2 {
                margin-top: 30px;
            }
            button {
                margin-left: 60px;
                width: 140px;
                height: 40px;
            }
        }
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0.3);
        }
        100% {
            transform: scale(1);
        }
    }

</style>
