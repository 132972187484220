<template>
  <div>
    <div class="flex flex_center item-1" :style="headBack">
      <div class="flex flex_center justify-space-between item-1-wrap">
        <div class="flex_column justify-end left-wrap">
          <!-- 486 -->
          <div class="flex_column align-start left-content">
            <div class="flex flex_center first-line">
              <div class="icon">
                <img class="icon" :src="project.icon" />
              </div>
              <div class="title">{{project.name}}</div>
            </div>
            <div class="script">{{project.script}}</div>
            <div
              class="flex flex_column project_fun"
              v-for="(s, i) in project.function"
              :key="i"
            >{{ s }}</div>
            <div class="sub-title">我们负责：</div>
            <div class="flex wrap items-wrap">
              <div v-for="(item,index) in inchargeItems" :key="index">
                {{item}}
                <span
                  v-if="index!==2&&index!==inchargeItems.length-1"
                  style="margin: 0 5px"
                >|</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex_center right-wrap">
          <img class="right-img fade-in" v-lazy="project.headImg" />
        </div>
      </div>
    </div>
    <div v-if="project.process" class="project_item flex_column">
      <div class="title">项目进程</div>
      <div class="flex flex-row program justify-content-space-around">
        <div
          :class="['flex flex_center circle', item.selected ? '': 'gray']"
          v-for="(item,index) in project.process"
          :key="index"
        >
          <div class="small"></div>
        </div>
      </div>
      <div class="content_card wrap flex flex-row justify-space-between" id="morecard">
        <div
          class="flex_column justify-center"
          v-for="(item,index) in project.process"
          :key="index"
        >
          <div :class="['flex_column card', item.selected?'redcard':'']">
            <div class="title">{{item.card_title}}</div>
            <div class="script">{{item.card_script}}</div>
            <div class="timecard text-center">{{item.time}}</div>
          </div>
        </div>
      </div>
      <div class="content_card flex wrap flex-row justify-space-between" id="content_text">
        <div
          class="flex_column justify-center content-card-text"
          v-for="(item,index) in project.process"
          :key="index+100"
        >
          <div class="card_title">{{item.title}}</div>
          <div class="card_text">{{item.text}}</div>
        </div>
      </div>
    </div>

    <div class="item-2-wrap">
      <div class="page-item item-2" :style="transBack"></div>
    </div>

    <div class="page-item item-3">
      <div class="title">项目服务范围</div>
      <div class="brand_card flex flex-row justify-space-between wrap">
        <div
          class="flex_column justify-center card"
          v-for="(item, i) in project.serveRange"
          :key="i+200"
        >
          <div class="flex flex-row justify-start align-center">
            <div class="card_img flex flex_center">
              <img :src="item.img" />
            </div>
            <div class="card_title">{{item.title}}</div>
          </div>
          <div class="descr">{{item.script}}</div>
        </div>
      </div>
      <div class="other_title">其他附加服务</div>
      <div class="flex other_title flex-row wrap justify-start">
        <div
          class="flex flex-row align-center text_card"
          v-for="(item,index) in project.addServe"
          :key="index+1000"
        >
          <div class="blackCircl"></div>
          <div class="serveText">{{item}}</div>
        </div>
      </div>
    </div>
    <div class="flex_column flex_center item-4">
      <div class="title">产品功能介绍</div>
      <div class="flex flex_center buttons" v-if="project.appContent[0].hasOwnProperty('content')">
        <div class="click-button">
          <div
            class="left-button"
            :class="{active:appContentSerial===0}"
            @click="switchappContentSerial(0)"
          >{{project.appContent[0].itemsTitle}}</div>
        </div>
        <div class="click-button">
          <div
            class="right-button"
            :class="{active:appContentSerial===1}"
            @click="switchappContentSerial(1)"
          >{{project.appContent[1].itemsTitle}}</div>
        </div>
      </div>
      <swiper :options="swiperOption" class="centerAll" ref="mySwiper" v-if="hh">
        <swiper-slide class="slide" v-for="(item,index) in sliderContent" :key="index">
          <div style="margin-top: 40px" class="flex flex_center wrap slider-item">
            <div class="img-wrap">
              <img class="img" :src="item.img" />
            </div>
            <div class="flex_column justify-start slide-title">
              <div class="sub-title">{{item.title}}</div>
              <div class="script">{{item.script}}</div>
            </div>
          </div>
        </swiper-slide>
        <div class="icon click-button" slot="button-prev">
          <img
            class="icon swiper-button-prev"
            src="http://moke-store.oss-cn-beijing.aliyuncs.com/735c7b3e-b22f-442c-a0c4-c8e003f21de8.png"
          />
        </div>
        <div class="icon click-button" slot="button-next">
          <img
            class="icon swiper-button-next"
            src="http://moke-store.oss-cn-beijing.aliyuncs.com/905f25b9-95b8-43af-bd24-12ed4272572f.png"
          />
        </div>
      </swiper>
      <div class="flex flex_center points">
        <div
          v-for="(item1,index1) in sliderContent"
          :key="index1"
          @click="toOne(index1)"
          class="point"
          :class="{active:(index1===sliderIndex)}"
        ></div>
      </div>
    </div>
    <div class="gray-line"></div>

    <div class="flex_column flex_center page-item item-5">
      <div class="title">我们使用的技术和方法</div>
      <div class="flex buttons">
        <div class="click-button">
          <div
            class="left-button"
            :class="{active:!isDesign}"
            @click="isDesign=false"
          >{{project.tech[0].title}}</div>
        </div>
        <div class="click-button">
          <div
            class="right-button"
            :class="{active:isDesign}"
            @click="isDesign=true"
          >{{project.tech[1].title}}</div>
        </div>
      </div>
      <div class="flex wrap flex_center red-board" v-if="isDesign">
        <div class="flex_column left-half">
          <li
            class="script"
            v-for="(item1,index1) in project.tech[1].script"
            :key="index1"
          >{{item1}}</li>
        </div>
        <div class="flex wrap right-half">
          <div
            class="flex_column card"
            v-for="(item2,index2) in project.tech[1].items"
            :key="index2"
          >
            <div class="title">{{item2.title}}</div>
            <div class="script">{{item2.keywords}}</div>
          </div>
        </div>
      </div>
      <div class="flex_row wrap justify-center red-board" v-else>
        <div class="flex_column left-half">
          <div class="script-head">{{project.tech[0].script[0]}}</div>
          <li
            class="script"
            v-for="(item1,index1) in project.tech[0].script.slice(1,project.tech[0].script.length)"
            :key="index1"
          >{{item1}}</li>
        </div>
        <div class="flex flex-row wrap justify-start right-half">
          <div
            class="flex_column card"
            v-for="(item2,index2) in project.tech[0].items"
            :key="index2"
          >
            <div class="title">{{item2.title}}</div>
            <div class="script">{{item2.keywords}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="gray-line"></div>

    <div class="flex_column align-center page-item item-6">
      <div class="title">产品运行逻辑</div>
      <div class="img">
        <img class="img" :src="project.motion" />
      </div>
    </div>

    <div class="gray-line"></div>

    <estimate></estimate>

    <div class="gray-line"></div>

    <div class="flex_column flex_center page-item item-8-wrap">
      <div class="title">其他案例</div>
      <div class="flex wrap flex_center page-item item-8">
        <div v-for="(item,index) in smallComputedProjects" :key="index" class="flex card">
          <lazy-component class="fade-in">
            <div class="click-img">
              <div class="small-card" @click="projectContent(item.projectIndex)" :style="item.back"></div>
            </div>
            <div class="card-script">{{item.script}}</div>
          </lazy-component>
        </div>
      </div>
      <div class="click-button">
        <button class="button" @click="toProject">查看更多案例</button>
      </div>
    </div>
    <estimate-alert></estimate-alert>
  </div>
</template>

<script>
import projects from '../assets/js/projectsDetail.js'
import estimate from './estimate'
import EstimateAlert from './EstimateAlert'
import serveRange from '../assets/js/serverange.js'
export default {
  name: "pcOneproject",
  components: { EstimateAlert, estimate },
  data () {
    const that = this
    return {
      ll: require('../assets/svg/ios.svg'),
      hh: true,
      serveRange: serveRange,
      swiperOption: {
        on: {
          slideChangeTransitionStart: function () {
            that.sliderIndex = this.realIndex
          }
        },
        //开启鼠标滚轮控制Swiper切换
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true //允许分页点击跳转
        },
        //自动轮播
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        }
      },
      showDot: false,
      appContentSerial: 0,
      index: -1,//项目编号
      isDesign: false,
      sliderIndex: 0,
      highPixcel: false,
    }
  },
  created: function () {
    if (this.$route.query.hasOwnProperty('index')) {
      this.index = this.$route.query.index
    }
  },
  watch: {
    $route (to) {
      this.$router.replace({ path: to.fullPath })
    }
  },
  mounted: function () {
    // 执行一次 ，解决ie上不动的背景图片的抖动问题
    if (!this.preventScroll && navigator.userAgent.match(/Trident\/7\./)) {
      document.body.addEventListener("mousewheel", function () {
        event.preventDefault()
        let wd = event.wheelDelta
        let csp = window.pageYOffset
        window.scrollTo(0, csp - wd)
      })
    }
    this.$store.commit('setpreventScroll', true)
  },
  computed: {
    sliderContent: {
      get: function () {
        if (this.project.appContent[0].hasOwnProperty('content')) {
          return this.project.appContent[this.appContentSerial].content
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          return this.sliderContent = this.project.appContent
        }
      },
      set: function (v) {
        return v
      }
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    preventScroll: function () {
      return this.$store.state.preventScroll
    },
    smallComputedProjects: function () {
      if (this.index !== -1) {
        let a = this.smallProjects.filter(a => {
          return a.projectIndex != this.index
        })
        return a.slice(0, 6)
      } else {
        return this.smallProjects.slice(0, 6)
      }
    },
    inchargeItems: function () {
      return this.project.inCharge
    },
    project: {
      get: function () {
        if (this.index !== -1) {
          return projects[this.index]
        } else {
          return projects[0]
        }
      }
    },
    transBack: function () {
      return `background-image:  url(${this.project.banner})`
    },
    headBack: function () {
      if (this.onMobile) return `background-image:url(${this.project.headBack});background-size:210px 170px;`
      return `background-image:url(${this.project.headBack});background-size:700px 640px;`
    },
  },
  methods: {
    toOne: function (index) {
      this.sliderIndex = index
      this.$nextTick(() => {
        return this.$refs.mySwiper.swiper.slideTo(index, 300, false)
      })
    },
    projectContent: function (index) {
      // 0是小万生活
      this.$router.push({
        path: '/oneProject/',
        query: {
          index: index
        }
      })
      window.scrollTo(0, 0)
    },
    toProject: function () {
      this.$router.push('/project/')
    },
    toAbout: function () {
      this.$router.push('/about/')
    },
    switchappContentSerial: function (serial) {
      this.appContentSerial = serial
      this.hh = false
      this.$nextTick(() => {
        this.hh = true
        this.sliderIndex = 0
        // this.swiper.slideTo(0, 300, false)
      })

    },
  },
}
</script>

<style scoped lang="scss">
.project_fun {
  font-size: 18px;
  line-height: 34px;
  color: #2d2d49;
}
.item-1 {
  width: 100%;
  margin: 70px auto 0;
  height: 766px;
  @media (min-width: 1280px) {
    background-position: 1220px top;
  }
  @media (max-width: 1279px) {
    background-position: 580px top;
  }
  @media (max-width: 600px) {
    background-size: 228px 0px;
  }
  .item-1-wrap {
    flex-wrap: wrap-reverse;
    height: 100%;
    width: 100%;
  }
  .left-wrap {
    padding: 0 0 20px 50px;
    margin-right: 60px;
    width: 43%;
    @media screen and (max-width: 1182px) {
      margin: auto;
    }
    height: auto;
    align-items: flex-end;
    .left-content {
      width: 490px;
      padding-left: 22px;
      @media screen and (max-width: 1100px) and (min-width: 900px) {
        width: 42vw;
      }
      @media screen and (max-width: 700px) {
        width: auto;
        padding-left: 0;
      }
    }
    .first-line {
      .title {
        margin-left: 40px;
        font-weight: bold;
        font-size: 32px;
        color: rgba(45, 45, 73, 1);
      }
    }
    .script {
      margin: 35px 0 0 0;
      width: 486px;
      line-height: 35px;
      font-size: 18px;
      color: rgba(45, 45, 73, 1);
      @media screen and (max-width: 1100px) and (min-width: 900px) {
        width: 100%;
      }
      @media screen and (max-width: 700px) {
        width: auto;
      }
    }

    .sub-title {
      margin-top: 50px;
      line-height: 16px;
      font-size: 16px;
      color: rgba(124, 124, 153, 1);
    }

    .items-wrap {
      margin-top: 24px;
      width: 320px;
      font-size: 16px;
      color: rgba(124, 124, 153, 1);
    }
  }
  .right-wrap {
    margin: 0px auto;
    height: auto;
    width: 50%;
    max-width: 704px;
    min-width: 604px;
    .right-img {
      width: 100%;
      max-width: 600px;
      max-height: 630px;
    }
    @media screen and (max-width: 1100px) and (min-width: 900px) {
      width: 48vw;
      min-width: 0px;
    }
    @media screen and (max-width: 900px) {
    }
  }
}
.project_item {
  margin: auto;
  width: 1160px;
  .title {
    font-size: 32px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    margin-bottom: 41px;
  }
  .program {
    width: 100%;
    height: 30px;
    background-color: rgba(245, 246, 247, 1);
    border-radius: 15px;
    margin-bottom: 30px;
    .circle {
      width: 30px;
      height: 30px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 50%;
      .small {
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
      }
    }
    .gray {
      background-color: rgba(245, 246, 247, 1);
    }
  }
  #morecard {
    margin: 0 auto 0px;
  }
  .content_card {
    margin: 0 auto 88px;
    width: 1160px;
    .card {
      width: 210px;
      height: 228px;
      background-color: rgba(245, 246, 247, 1);
      border-radius: 8px;
      padding: 21px 19px 23px 20px;
      .title {
        font-size: 19px;
        color: rgba(45, 45, 73, 1);
        margin-bottom: 17px;
      }
      .script {
        line-height: 1.5;
        margin-bottom: 29px;
        font-size: 16px;
        color: rgba(124, 124, 153, 1);
      }
      .timecard {
        width: 100%;
        height: auto;
        background-color: rgba(255, 234, 234, 1);
        border-radius: 4px;
        font-size: 16px;
        color: rgba(244, 74, 79, 1);
        padding: 7px 0;
      }
    }
    .redcard {
      background-color: rgba(244, 74, 79, 1);
      .title,
      .script {
        color: white;
      }
    }
    .card_title {
      margin: 64px auto 16px;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: rgba(244, 74, 79, 1);
    }
    .card_text {
      text-align: center;
      font-size: 20px;
      color: rgba(124, 124, 153, 1);
    }
    .content-card-text {
      width: 210px;
    }
  }
}
.item-2-wrap {
  visibility: visible;
  .item-2 {
    width: 100%;
    background-size: 100% auto;
    @media screen and (max-width: 1500px) {
      background-size: auto 1000px;
    }
    background-repeat: no-repeat;
    background-color: #d8d8d8;
    background-attachment: fixed;
    padding-top: 480px;
    position: relative;
    overflow: hidden;
  }
}
.item-3 {
  text-align: center;
  padding: 99px 0;
  width: 100%;
  /*background-color: rgba(245, 246, 247, 1);*/
  .title {
    margin-bottom: 55px;
    font-weight: bold;
    font-size: 32px;
    color: rgba(45, 45, 73, 1);
  }
  .other_title {
    width: 100%;
    max-width: 1190px;
    margin: 39px auto 29px;
    text-align: left;
    color: rgba(124, 124, 153, 1);
    padding-left: 16px;
    .text_card {
      width: 234px;
      margin-bottom: 32px;
    }
    .blackCircl {
      width: 10px;
      height: 10px;
      border: 1px solid rgba(45, 45, 73, 1);
      background-color: black;
      border-radius: 50%;
      margin-right: 13px;
    }
    .serveText {
      font-size: 16px;
      color: #2d2d49;
      font-weight: bold;
    }
  }
  .brand_card {
    width: 100%;
    max-width: 1190px;
    margin: auto;
    height: auto;
    .card {
      margin: 15px;
      width: 310px;
      /*padding: 36px 26px 38px;*/
      height: auto;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      text-align: left;
      .card_img {
        width: 60px;
        height: 60px;
        background-color: rgba(244, 74, 79, 1);
        border-radius: 4px;
        img {
          width: 33px;
          height: 40px;
        }
      }
      .card_title {
        font-size: 24px;
        margin-left: 31px;
        color: rgba(45, 45, 73, 1);
      }
      .descr {
        margin-top: 23px;
        font-size: 16px;
        width: 100%;
        color: rgba(124, 124, 153, 1);
      }
    }
  }
}
.item-4-mobile {
  display: none;
}
.item-4 {
  display: inline;
  min-height: 934px;
  text-align: center;
  background-color: rgba(250, 250, 250, 1);
  .buttons {
    margin: 20px auto 64px;
    .left-button {
      cursor: pointer;
      text-align: center;
      width: 180px;
      height: 46px;
      font-weight: bold;
      font-size: 16px;
      color: rgba(136, 136, 136, 1);
      line-height: 46px;
      background-color: white;
      border-radius: 22px 0 0 22px;
    }

    .right-button {
      cursor: pointer;
      text-align: center;
      width: 180px;
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 16px;
      color: rgba(136, 136, 136, 1);
      background-color: white;
      border-radius: 0 22px 22px 0;
    }

    .active {
      color: white !important;
      background-color: rgba(244, 74, 79, 1) !important;
    }
  }

  .centerAll {
    height: auto;
    max-width: 1240px;
    width: 100%;
    .icon {
      cursor: pointer;
      width: 49px;
      height: 49px;
    }
    .slide-title {
      margin-top: 20px;
    }
    @media screen and (max-width: 750px) {
      .slide-title {
        .sub-title {
          margin: auto;
          text-align: center;
        }
        .script {
          margin-left: 0;
          width: 100%;
        }
      }
    }
    .slider {
      margin: 85px 188px 0;
      max-width: 1100px;
      .slider-item {
        max-width: 1100px;
        height: auto;
        .img-wrap {
          width: 276px;
        }
        .img-wrap-big {
          max-width: 582px !important;
          width: 100%;
        }
        .img {
          max-width: 582px;
          width: 100%;
        }
      }
    }
  }
  .title {
    padding-top: 84px;
    margin-bottom: 52px;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
    color: rgba(45, 45, 73, 1);
  }
  .sub-title {
    margin-left: 100px;
    line-height: 28px;
    font-size: 28px;
    text-align: left;
    color: rgba(45, 45, 73, 1);
  }
  .script {
    text-align: left;
    width: 348px;
    margin-left: 100px;
    margin-top: 22px;
    line-height: 25px;
    font-size: 18px;
    color: rgba(124, 124, 153, 1);
  }
  .points {
    margin-top: 82px;
    padding-bottom: 78px;
    @media screen and (max-width: 750px) {
      margin-top: 0;
    }
    .point {
      cursor: pointer;
      margin: 0 5px;
      width: 6px;
      height: 6px;
      background-color: rgba(136, 136, 136, 1);
      border-radius: 50%;
    }
    .active {
      border: 1.5px solid #f7a5a7;
      width: 10px;
      height: 10px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 50%;
    }
  }
}
.item-5 {
  margin-bottom: 80px;
  .title {
    margin: 60px auto 29px;
    font-size: 28px;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
  }

  .buttons {
    margin-bottom: 64px;
    .left-button {
      cursor: pointer;
      text-align: center;
      width: 180px;
      height: 46px;
      font-weight: bold;
      font-size: 16px;
      color: rgba(136, 136, 136, 1);
      line-height: 46px;
      background-color: #fafafa;
      border-radius: 22px 0 0 22px;
    }
    .right-button {
      cursor: pointer;
      text-align: center;
      width: 180px;
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 16px;
      color: rgba(136, 136, 136, 1);
      background-color: #fafafa;
      border-radius: 0 22px 22px 0;
    }
    .active {
      color: white !important;
      background-color: rgba(244, 74, 79, 1) !important;
    }
  }

  .red-board {
    max-width: 1160px;
    height: auto;
    background-color: rgba(244, 74, 79, 1);

    .left-half {
      margin-right: 51px;
      padding: 52px 39px 0;
      width: 450px;
      .script {
        line-height: 28px;
        width: 450px;
        margin-bottom: 30px;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
      }
      .script-head {
        width: 450px;
        margin-bottom: 52px;
        font-size: 28px;
        font-weight: bold;
        color: rgba(254, 254, 254, 1);
      }
    }

    .right-half {
      width: 600px;
      padding: 40px 10px;
      .card {
        margin: 0 30px 30px 0 !important;
        padding-left: 22px;
        width: 260px;
        height: 72px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
      }

      .title {
        margin: 19px 0 10px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(45, 45, 73, 1);
      }

      .script {
        line-height: 14px;
        font-size: 14px;
        color: rgba(124, 124, 153, 1);
      }
    }
  }
}
.item-6 {
  height: 876px;
  .title {
    margin: 70px auto 50px;
    font-size: 28px;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
  }

  .img {
    max-width: 934px;
    width: 100%;
    height: auto;
  }
}
.item-8 {
  height: auto;
  width: 100%;
  max-width: 1250px;
  margin-bottom: 80px;
  text-align: center;

  .small-card {
    cursor: pointer;
    margin: auto;
    width: 366px;
    height: 192px;
    border-radius: 10px;
  }

  .card-script {
    line-height: 18px;
    font-size: 18px;
    color: rgba(45, 45, 73, 1);
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .card {
    width: 396px;
  }
}
.item-8-wrap {
  width: 100%;
  .title {
    margin: 90px 0 85px;
    line-height: 32px;
    font-size: 32px;
    font-weight: bold;
    color: rgba(45, 45, 73, 1);
  }
  .script {
    margin: 20px 0 62px;
    line-height: 20px;
    font-size: 20px;
    color: rgba(124, 124, 153, 1);
  }
  .button {
    margin-bottom: 110px;
    width: 134px;
    height: 42px;
    border: 1px solid rgba(124, 124, 153, 1);
    border-radius: 21px;
    font-size: 14px;
    color: rgba(124, 124, 153, 1);
  }
}
@media screen and (max-width: 600px) {
  .project_fun {
    font-size: 14px;
    line-height: 24px;
    color: #2d2d49;
  }
  .item-1 {
    margin-top: 44px;
    background-position: 206px top;
    height: 500px;
    // min-height: 500px;
    // max-height: 572px;
    .item-1-wrap {
      height: 467px;
      // min-height: 467px;
      // max-height: 567px;
    }
    .left-wrap {
      margin: 0;
      padding-left: 16px;
      width: 100%;
      .left-content {
        width: 100%;
      }
      .first-line {
        margin-left: 0px;
        margin-bottom: 15px;
        .icon {
          width: 34px;
          height: 34px;
        }
        .title {
          margin-left: 12px;
          font-size: 16px;
        }
      }
      .script {
        width: 100%;
        line-height: 1.2;
        height: auto;
        font-size: 14px;
        margin-top: 0px;
      }
      .sub-title {
        margin-top: 20px;
        font-size: 14px;
        width: 100%;
      }
      .items-wrap {
        width: 100%;
        line-height: 1.5;
        font-size: 14px;
        margin-top: 8px;
      }
    }
    .right-wrap {
      margin: 0px;
      width: 375px;
      height: 290px;
      min-width: 0px;
      .right-img {
        margin: 28px auto 0;
        width: auto;
        height: 260px;
      }
    }
  }
  .project_item {
    width: 100%;
    padding: 39px 14px 0 14px;
    .program {
      display: none;
    }
    .title {
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 28px;
    }
    .content_card {
      width: 100%;
      height: auto;
      .card {
        width: 100%;
        height: auto;
        padding: 17px;
        margin-bottom: 15px;
        .title {
          font-size: 16px;
          margin-bottom: 13px;
          text-align: left;
        }
        .script {
          font-size: 14px;
          margin-bottom: 15px;
        }
        .timecard {
          width: fit-content;
          text-align: left;
          padding: 3px 7px 4px;
          font-size: 13px;
        }
      }
      .card_title {
        font-size: 18px;
        margin: 27px auto 8px;
      }
      .card_text {
        font-size: 13px;
      }
      .content-card-text {
        width: 33%;
        justify-content: left;
      }
    }
    #content_text {
      justify-content: left;
      margin: 0 auto 30px;
    }
  }
  .item-2-wrap {
    .item-2 {
      display: none;
    }
  }
  .item-3 {
    padding: 35px 1vw 32px 1vw;
    margin-bottom: 0;
    .title {
      font-size: 19px;
      font-weight: normal;
      margin: 0 auto 35px;
    }
    .brand_card {
      .card {
        width: 100%;
        margin: 1.5vw;
        height: auto;
        .card_img {
          width: 30px;
          height: 30px;
          margin-right: 9px;
          img {
            width: 17px;
            height: 20px;
          }
        }
        .card_title {
          margin-left: 0;
          font-size: 16px;
        }
        .title {
          font-size: 16px;
          margin: 0 0 8px 0;
          font-weight: bold;
        }
        .descr {
          font-size: 13px;
          line-height: 1.4;
          width: 100%;
          margin: 14px auto 21px;
        }
      }
    }
    .other_title {
      margin: 0 0 18px;
      font-size: 14px;
      padding: 6px;
      .blackCircl {
        width: 7px;
        height: 7px;
        margin-right: 11px;
      }
      .text_card {
        width: 45vw;
      }
      .serveText {
        font-size: 14px;
        color: #2d2d49;
      }
    }
  }
  .item-4 {
    background-color: #fafafa;
    img {
      height: 50vw;
    }
    .title {
      padding-top: 0;
      margin-bottom: 0;
      text-align: center;
      font-size: 20px;
    }
    .script {
      width: 100%;
    }
    .centerAll {
      .icon {
        height: 0;
      }
      .slide-title {
        display: inline;
        width: 100%;
      }
    }
    .points {
      margin-top: 28px;
      padding-bottom: 35px;
      .point {
        width: 4px;
        height: 4px;
      }
    }
    .buttons {
      .left-button {
        width: 90px;
        height: 25px;
        font-weight: 700;
        font-size: 9px;
        line-height: 28px;
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.5;
        }
      }
      .right-button {
        width: 90px;
        height: 25px;
        font-weight: 700;
        font-size: 9px;
        line-height: 28px;
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.5;
        }
      }
      margin-bottom: 0;
    }
    .slider-item {
      .sub-title {
        margin: 30px 0 0 0;
        line-height: 1;
        font-size: 16px;
      }
      .script {
        text-align: center;
        margin: 10px 0 0 0;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
  .item-5 {
    margin-bottom: 0px;
    .title {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 26px;
    }
    .buttons {
      .left-button {
        width: 120px;
        height: 25px;
        font-size: 9px;
        // line-height: 2.2;
        line-height: 25px;
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.5;
        }
      }
      .right-button {
        width: 120px;
        height: 25px;
        font-size: 9px;
        // line-height: 2.2;
        line-height: 25px;
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
    .red-board {
      width: 100%;
      .left-half {
        width: 100%;
        padding: 35px 10px 0px 12px;
        margin-right: 0px;
        .script-head {
          width: 100%;
          font-size: 16px;
          text-align: center;
          margin-bottom: 22px;
        }
        .script {
          width: 100%;
          line-height: 2;
          font-size: 12px;
          margin-bottom: 8px;
        }
      }
      .right-half {
        padding-top: 0px;
        .card {
          width: 100%;
        }
      }
    }
  }
  .item-6 {
    height: auto;
    margin-bottom: 35px;
    .title {
      font-size: 20px;
      margin: 35px 0;
    }
  }
  .item-8 {
    margin-bottom: 12px;
  }
  .item-8-wrap {
    .title {
      font-size: 20px;
      margin: 30px 0;
    }
    .card {
      width: auto;
      margin-left: 10px;
    }
    .small-card {
      width: 160px;
      height: 84px;
    }
    .card-script {
      font-size: 11px;
      line-height: 1.5;
    }
    .button {
      width: 120px;
      height: 30px;
      line-height: 1.2;
      font-size: 11px;
      margin-bottom: 35px;
    }
  }
}
</style>
