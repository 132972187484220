<template>
  <tk-cover center middle :show="isShow" color="transparent" transition>
    <tk-flex center middle class="alert-box">
      <div class="alert">
        <div class="title">{{title}}</div>
        <div class="close" @click="hide">确定</div>
      </div>
    </tk-flex>
  </tk-cover>
</template>
<script>
  export default {
    name: 'alert',
    data () {
      return {
        isShow: false,
        title: ''
      }
    },
    methods: {
      show (title) {
        this.title = title
        this.isShow = true
      },
      hide () {
        this.isShow = false
      }
    }
  }
</script>
<style scoped lang="scss">
  .alert-box {
    width: 100%;
    height: 100%;
  }
  .alert {
    width: 400px;
    min-height: 260px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
    padding: 60px;
    text-align: center;
    @media screen and (max-width: 600px) {
      width: 280px;
      min-height: 130px;
      padding: 20px;
    }
    .title {
      text-align: center;
      white-space: pre-wrap;
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
      color: rgba(45, 45, 73, 1);
    }
    .close {
      width: 190px;
      height: 42px;
      background-color: rgba(244, 74, 79, 1);
      border-radius: 1px;
      display: inline-block;
      color: white;
      font-weight: bold;
      line-height: 42px;
      margin-top: 40px;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }
</style>
